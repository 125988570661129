import $ from 'jquery';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Chevron } from '../../assests/images/icons/chevron-left.svg';
import { ReactComponent as Editnw } from '../../assests/images/icons/edit.svg';
import { ReactComponent as Holiday } from '../../assests/images/icons/Holiday.svg';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Edit } from '../../assests/images/icons/main-plus.svg';
import Anz from '../../assests/images/icons/Mortgage.png';
import MainSidebar from '../MainSidebar';

// import { Cart } from "./Cart";

const Financial = () => {
  const sidebarRef = useRef(null);
  const [active, setActive] = useState(false);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };

  const handleChange = () => {
    setActive(!active);
  };
  // const handle = () => {
  //   console.log("Razik");
  //   setActive(false);
  // };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            <div className="app-main__outer financial-goals-main">
              <div className="row new-finance">
                <div className="col-lg-4 col-sm-12 main-heading-financial">
                  <div className="fs-3 bold black big-head-new">Your Financial Goals</div>
                  <div className="fst-normal setting_text_color">
                    Let's set some goals, it's always good having goals and something to aim for.
                  </div>
                </div>

                <div className="col-md-4 edit-btn-financial text-center">
                  <Link to="/Financial/AddNewFinancial">
                    <div className="edit-btn-col">
                      <div className="edit-btn" href-="#">
                        <div className="left">
                          <h4 className="bold">Add a new goal</h4>
                          <p style={{ color: '#04baff', textAlign: 'left' }}>Keep on track</p>
                        </div>

                        <div className="right">
                          <Edit />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-4 col-sm-12 right-section back-to-portal-financial">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link>
                    </div>

                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8 financial-cards-wrp-div">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 mt-4">
                        <div className="financial-holiday financial cursor-pointer" onClick={handleChange}>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="icon-holiday">🏝</div>
                              <div className="ms-2 text-white category-text fw-bold inter">Holiday</div>
                            </div>

                            <div className="financial-icon">
                              {active ? (
                                <span
                                  onClick={() => {
                                    setActive(false);
                                  }}
                                >
                                  <i class="fa-solid fa fa-close" style={{ color: '#FFFF' }}></i>
                                </span>
                              ) : (
                                <Editnw />
                              )}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                            <div className="financial-text ">Balance</div>
                            <div className="financial-text">50%</div>
                          </div>
                          <div
                            class="progress red-progress"
                            style={{
                              height: '7px',
                            }}
                          >
                            <div
                              class="progress-bar w-50 financial-progress-bar"
                              role="progressbar"
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="">
                              <span className="bold text-white ">$300</span>
                              <span className="financial-text">&nbsp; of &nbsp;$699</span>
                            </div>
                            <div className="financial-text">12th December 2022</div>
                          </div>
                          <div className="mt-3 text-white fw-bold inter fs-16">See detail</div>
                        </div>
                        {active && (
                          <div className="mt-3 edit-goal-ami active-edit-goal">
                            <div>
                              <div className="bold black sub-head-text inter">Edit Goal</div>
                              <div className="edit-goal-box d-flex justify-content-between">
                                <div className="fw-bold inter">Name</div>
                                <div className="edit-goal-house w-75 py-2 inter">House renovations</div>
                              </div>
                              <div className="edit-goal-box d-flex justify-content-between edit-goal-inner">
                                <div className="fw-bold inter">Goal dollar value</div>
                                <div className="edit-goal-house py-2 inter">$5000</div>
                              </div>
                              <div className="edit-goal-box edit-goal-inner">
                                <div className="d-flex justify-content-between ">
                                  <div className="fw-bold inter mt-1">Contributions</div>
                                  <div className="edit-goal-house  py-2 inter">$250</div>
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                  <div className="fw-bold inter mt-1">How often?</div>
                                  <div className="edit-goal-house  py-2 inter">
                                    Weekly <Chevron className="edit-goal mt-1" />
                                  </div>
                                </div>
                              </div>
                              <div className="edit-goal-box d-flex justify-content-between align-items-center edit-goal-inner">
                                <div className="fw-bold inter">Account</div>
                                <div className="edit-goal-house w-50 py-2 inter">
                                  House savings
                                  <Chevron className="edit-goal mt-1" />
                                </div>
                                <div>
                                  <img src={Anz} alt="" />
                                </div>
                              </div>

                              <div className="edit-goal-box d-flex justify-content-between edit-goal-inner-personalised">
                                <div className="fw-bold inter  py-1">Personalised colour</div>
                                <div className="edit-goal-house edit-red inter fw-bold">
                                  Red <Chevron className="edit-goal red-personalised" />
                                </div>
                              </div>
                              <div className="edit-goal-box d-flex justify-content-between edit-goal-inner-personalised">
                                <div className="fw-bold inter py-1">Personalised emoji</div>
                                <div className="edit-goal-house holiday-edit">
                                  <Holiday />
                                </div>
                              </div>
                            </div>
                            <div className="remove-goal-wrapp mt-4">
                              <button type="button" className="delete-btn-edit inter fw-bold">
                                Remove goal
                                <i class="fa-solid fa fa-close minus-symbol"></i>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-lg-6 col-md-12 mt-4">
                        <div className="financial-car financial">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="icon-car icon">🚗</div>
                              <div className="ms-2 fw-bold category-text inter">Car</div>
                            </div>

                            <div className="financial-icon-black">
                              <Editnw />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                            <div className="financial-text-black">Balance</div>
                            <div className="financial-text-black">50%</div>
                          </div>
                          <div
                            class="progress light-yellow-financial"
                            style={{
                              height: '7px',
                            }}
                          >
                            <div
                              class="progress-bar w-50 financial-progress-bar-car"
                              role="progressbar"
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="">
                              <span className="bold">$10,000</span>
                              <span className="financial-text-black">&nbsp; of &nbsp;$20,000</span>
                            </div>
                            <div className="financial-text-black ">28th February 2023</div>
                          </div>
                          <div className="mt-3 inter fw-bold fs-16">See detail</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 mt-4">
                        <div className="financial-laptop financial">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="icon-laptop icon">💻 </div>
                              <div className="ms-2 text-white category-text fw-bold inter">New Laptop</div>
                            </div>

                            <div className="financial-icon">
                              <Editnw />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                            <div className="financial-text">Balance</div>
                            <div className="financial-text">60%</div>
                          </div>
                          <div
                            class="progress light-pink-financial"
                            style={{
                              height: '7px',
                            }}
                          >
                            <div
                              class="progress-bar w-75 financial-progress-bar-laptop"
                              role="progressbar"
                              aria-valuenow="75"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="">
                              <span className="bold text-white ">$300</span>
                              <span className="financial-text">&nbsp; of &nbsp;$1,499</span>
                            </div>
                            <div className="financial-text">15th January 2023</div>
                          </div>
                          <div className="mt-3 text-white inter fw-bold fs-16">See detail</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 mt-4">
                        <div className="financial-house financial">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="icon-house icon">🏡 </div>
                              <div className="ms-2 text-white category-text fw-bold inter">House</div>
                            </div>

                            <div className="financial-icon">
                              <Editnw />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                            <div className="financial-text">Balance</div>
                            <div className="financial-text">50%</div>
                          </div>
                          <div
                            class="progress light-blue-financial"
                            style={{
                              height: '7px',
                            }}
                          >
                            <div
                              class="progress-bar w-50 financial-progress-bar-house"
                              role="progressbar"
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="">
                              <span className="bold text-white">$65,000</span>
                              <span className="financial-text">&nbsp; of &nbsp;$30,500</span>
                            </div>
                            <div className="financial-text">17th March 2023</div>
                          </div>
                          <div className="mt-3 text-white inter fw-bold fs-16">See detail</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Financial;

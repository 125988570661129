import React from 'react';
import AmiInsurance from '../../assests/images/icons/Ami insurance.png';
import Countdown from '../../assests/images/icons/Countdown.png';
import Mcdonalds from '../../assests/images/icons/Mcdonalds.png';
import Mortgage from '../../assests/images/icons/Mortgage.png';
import { Linecart } from './Linecart';

const Recenttransaction = () => {
  return (
    <>
      <div className="row mt-4">
        <div className="col-md-12 col-lg-6 bar-cart recent-transaction-wrapper">
          <div className="recent-see-all">
            <div className="col-md-6 mb-4 left">
              <h5>Recent Transactions</h5>
            </div>
            <div className="col-md-6 right-section right see-all">See All</div>
          </div>
          <div className="sideblck">
            <div className="merchnat-img">
              <img src={Countdown} alt="CountDown-img" />
              <div className="name-merchant bar-chart">
                <h5>Countdown</h5>
                <h6>Groceries</h6>
              </div>
            </div>
            <div className="date-time-blck">
              <h4>19/04/2022</h4>
              <span>|</span>
              <h4>10: 50am</h4>
            </div>
            <div className="status-btn">
              <div class="percentage bold red">Pending</div>
            </div>
            <div className="transaction-amt ">
              <h4>-$32.00</h4>
            </div>
          </div>
          <div className="sideblck">
            <div className="merchnat-img">
              <img src={Mcdonalds} alt="CountDown-img" />
              <div className="name-merchant bar-chart">
                <h5>Mcdonalds</h5>
                <h6>Eating out</h6>
              </div>
            </div>
            <div className="date-time-blck">
              <h4>19/04/2022</h4>
              <span>|</span>
              <h4>10: 50am</h4>
            </div>
            <div className="status-btn">
              <div class="percentage bold">Completed</div>
            </div>
            <div className="transaction-amt ">
              <h4>-$15.00</h4>
            </div>
          </div>
          <div className="sideblck">
            <div className="merchnat-img">
              <img src={AmiInsurance} alt="CountDown-img" />
              <div className="name-merchant bar-chart">
                <h5>AMI Insurance</h5>
                <h6>Insurance</h6>
              </div>
            </div>
            <div className="date-time-blck">
              <h4>19/04/2022</h4>
              <span>|</span>
              <h4>10: 50am</h4>
            </div>
            <div className="status-btn">
              <div class="percentage bold">Completed</div>
            </div>
            <div className="transaction-amt ">
              <h4>-$200.00</h4>
            </div>
          </div>
          <div className="sideblck">
            <div className="merchnat-img">
              <img src={Mortgage} alt="CountDown-img" />
              <div className="name-merchant bar-chart">
                <h5>Company xyz</h5>
                <h6>Salary</h6>
              </div>
            </div>
            <div className="date-time-blck">
              <h4>19/04/2022</h4>
              <span>|</span>
              <h4>10: 50am</h4>
            </div>
            <div className="status-btn">
              <div class="percentage bold">Completed</div>
            </div>
            <div className="transaction-amt">
              <h4 className="light-green-transaction">+$3052.00</h4>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 bar-cart cashflocart weekly-cahflowcart">
          <div className="col-md-6 mb-4 left">
            <h5>Weekly Cashflow Forecast</h5>
          </div>

          {/* <Linecart /> */}
          <div className="line-cart-net">
            <div className="bar-cart-heading-wrp col-lg-12 col-md-12">
              <div className="top-heading-canvas-price">
                <h4 className="bold black">Weekly Cashflow</h4>
                <h5>$370</h5>
              </div>
              <h6>Week 5 - 12/11/2022</h6>
            </div>
            <Linecart />
          </div>
        </div>
      </div>
    </>
  );
};
export default Recenttransaction;

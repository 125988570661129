import $ from 'jquery';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Backlog } from '../../assests/images/icons/back-goals.svg';
import { ReactComponent as Chevron } from '../../assests/images/icons/chevron-left.svg';
import { ReactComponent as Copy } from '../../assests/images/icons/copy.svg';
import Profile from '../../assests/images/icons/image.png';
import Anz from '../../assests/images/icons/Mortgage.png';
import MainSidebar from '../MainSidebar';

// import { Cart } from "./Cart";

const AddNewFinancial = () => {
  const sidebarRef = useRef(null);
  const [active, setActive] = useState(false);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };

  const handleChange = () => {
    setActive(true);
  };
  // const handle = () => {
  //   console.log("Razik");
  //   setActive(false);
  // };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            <div className="app-main__outer financial-goals-main">
              <div className="row">
                <div className="col-lg-5 col-sm-12 main-heading-financial">
                  <div className="fs-3 bold black big-head-new">Your Financial Goals</div>
                  <div className="fst-normal setting_text_color">
                    Let's set some goals, it’s always good having goals and something to aim for.
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mt-2 back-btn-financial">
                  <Link to="/Financial">
                    <div className="edit-btn-col">
                      <div className="edit-btn" href-="#">
                        <div>
                          <Backlog />
                        </div>
                        <div className="left">
                          <h4 className="bold">Back to Goals</h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-sm-12 right-section  back-to-portal-financial-goals">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link>
                    </div>

                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-7 col-md-12 add-new-goal-wrpp">
                  <div className="mt-3 edit-goal-ami">
                    <div>
                      <div className="bold sub-head-text">New goal</div>
                      <div className="edit-goal-box d-flex justify-content-between align-items-center">
                        <div className="fw-bold inter">Name</div>
                        <div className="edit-goal-house w-75 py-2 inter">House renovations</div>
                      </div>
                      <div className="edit-goal-box d-flex justify-content-between align-items-center">
                        <div className="fw-bold inter">Goal dollar value</div>
                        <div className="edit-goal-house w-50 py-2 inter">$5000</div>
                      </div>
                      <div className="edit-goal-box">
                        <div className="d-flex justify-content-between">
                          <div className="fw-bold inter mt-1">Contributions</div>
                          <div className="edit-goal-house w-75 py-2 inter">$250</div>
                        </div>
                        <div className="d-flex justify-content-between mt-2 align-items-center">
                          <div className="fw-bold inter">How often?</div>
                          <div className="col-md-9 col-lg-9 togger-wrp-financial">
                            <ul class="nav nav-pills budget-tabs img-back" id="pills-tab" role="tablist">
                              <li class="nav-item-financial" role="presentation">
                                <button
                                  class="nav-link active inter"
                                  id="pills-home-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-home"
                                  aria-selected="true"
                                >
                                  Weekly
                                </button>
                              </li>
                              <li class="nav-item-financial" role="presentation">
                                <button
                                  class="nav-link inter"
                                  id="pills-profile-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-profile"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-profile"
                                  aria-selected="false"
                                >
                                  Fortnightly
                                </button>
                              </li>
                              <li class="nav-item-financial" role="presentation">
                                <button
                                  class="nav-link inter"
                                  id="pills-contact-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-contact"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-contact"
                                  aria-selected="false"
                                >
                                  Monthly
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="edit-goal-box d-flex justify-content-between align-items-center">
                        <div className="fw-bold inter">Account</div>
                        <div className="edit-goal-house py-2 inter house-savings">
                          House savings
                          <Chevron className="edit-goal mt-1" />
                        </div>
                        <div>
                          <img src={Anz} alt="" />
                        </div>
                      </div>
                      <div className="edit-goal-box d-flex justify-content-between align-items-center w-75">
                        <div className="fw-bold inter-font-size inter">Reference</div>
                        <div className="edit-goal-house w-50 py-2 inter">
                          Goal 5 <Copy className="edit-goal mt-1" />
                        </div>
                      </div>
                      <div className="edit-goal-box d-flex justify-content-between align-items-center w-75">
                        <div className="fw-bold inter">Personalised colour</div>
                        <div className="edit-goal-house w-50 py-2 fw-bold personalised-color inter">
                          Blue
                          <Chevron className="edit-goal personalised-color" />
                        </div>
                      </div>
                      <div className="edit-goal-box d-flex justify-content-between w-75">
                        <div className="fw-bold inter">Personalised emoji</div>
                        <div className="edit-goal-house w-50 fs-4">🏝</div>
                      </div>
                      <div className="profile-dashboard mt-3 ms-0">
                        <Link to="">
                          <button className="comn-btn inter add-goals-btn">
                            Add Goal
                            <i class="fa-solid fa-plus plus-icon-style"></i>
                          </button>
                        </Link>
                      </div>
                      <div className="mt-4 fst-normal text-14px">
                        <span className="bold">Tip : </span>
                        <span className="text-14px">
                          Create separate bank accounts for each goal to keep on track of your progress.
                        </span>
                      </div>
                      <div className="mt-3 fst-normal text-14px">
                        Or if you have one account for multiple goals, use the reference provided above in your
                        transfers and we’ll keep track of your progress for you.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddNewFinancial;

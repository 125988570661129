import $ from 'jquery';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Kiwisaver from '../../assests/images/ConnectAccounts/Ellipse 10-1.png';
import { ReactComponent as Chevronleft } from '../../assests/images/icons/chevron-left.svg';
import Profile from '../../assests/images/icons/image.png';
import Mortgage from '../../assests/images/icons/Mortgage.png';
import Sharesis from '../../assests/images/icons/Sharesies.png';
import MainSidebar from '../MainSidebar';
import Accordian from './Accordianretirement/Accordian';

const Retirement = () => {
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            {/* <MainSidebar toggleSidebar={toggleSidebar} /> */}
            <div className="app-main__outer retirement-plan-main">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="bold big-head-new">Your Retirement Planner</div>
                  <div className="fst-normal setting_text_color">Let's have a look at your plan for retirement</div>
                </div>
                <div className="col-md-6 right-section back-portal-retirement">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-wrp-retirement mt-5">
                <div className="table-horizontal">
                  <div>
                    <div className="">
                      <div className="table-horizontal first-table-edit-cashflow">
                        <div class="col-12 table-horizontal">
                          <table class="table-image new-table income-after retirement-wrp-table-side">
                            <thead>
                              <tr>
                                <th scope="col">Your name</th>
                                <th scope="col">Current age</th>
                                <th scope="col">Preferred retirement age</th>
                                <th scope="col">Length of retirement</th>
                              </tr>
                            </thead>
                            <tbody className="space-table">
                              <tr className="cursor-pointer">
                                <th>
                                  <div className="air-image">
                                    <h3 className="table-content table-text-first display">Tim Preston</h3>
                                  </div>
                                </th>

                                <td className="table-text-color">
                                  <div className="planner"></div>
                                </td>
                                <td className="table-text-color">
                                  <div className="planner"></div>
                                </td>
                                <td className="table-text-color">
                                  <div className="planner"></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <div className="bold air-new mb-3">Your accounts & Assets</div>
                  <div className="row">
                    <div className="col-lg-8 account-assets-wrp">
                      <div className="row">
                        <div className="col-lg-6 account-assets-column">
                          <div className="merchant-box box-wrp-15">
                            <div className="d-flex">
                              <div>
                                <img src={Mortgage} alt="" />
                              </div>
                              <div className="ms-3 d-flex align-items-center saving-title">
                                <div className="text-merchant fw-bold inter">Savings account</div>
                              </div>
                            </div>
                            <div className="account-number inter text-16px">$15,675</div>
                          </div>
                        </div>
                        <div className="col-lg-6 account-assets-column">
                          <div className="merchant-box box-wrp-15">
                            <div className="d-flex">
                              <div className="acc-image">
                                <img src={Kiwisaver} alt="" className="kiwisaver-img" />
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="text-merchant fw-bold ms-3 inter">Kiwisaver</div>
                              </div>
                            </div>
                            <div className="account-number inter text-16px">$23,890</div>
                          </div>
                        </div>
                        <div className="col-lg-6 account-assets-column">
                          <div className="merchant-box mt-3 box-wrp-15">
                            <div className="d-flex">
                              <div className="acc-image">
                                <img src={Sharesis} alt="" className="kiwisaver-img" />
                              </div>
                              <div className=" d-flex align-items-center">
                                <div className="text-merchant fw-bold ms-3 inter">Sharesies</div>
                              </div>
                            </div>
                            <div className="account-number inter text-16px">$4560</div>
                          </div>
                        </div>
                        <div className="col-lg-6 account-assets-column">
                          <div className="merchant-box mt-3 last-house-wrp">
                            <div className="d-flex">
                              <div className="account-assets-inner">
                                <div className="text-merchant fw-bold inter">House</div>
                                <div className="text-merchant account-assets-mini-title inter">
                                  <span className="new-light-green inter">Value: $530,000 </span> |
                                  <span className="new-red-color inter"> Owing: $300,000</span>
                                </div>
                              </div>
                            </div>
                            <div className="account-number inter text-16px">$230,000</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 total-amt-now-box">
                      <div className="account-assets-total-amt">
                        <div className="retirement-planner-wrp">
                          <h4 className="retirement-planner-text text-center">Total amount now:</h4>
                          <h3 className="big-head text-center bold">$44,109</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="profile-dashboard mt-5">
                      <Link to="">
                        <button className="comn-btn add-account-asset-btn">
                          <i class="fa-solid fa-plus plus-icon-style"></i> Add account or Asset
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="bold mt-5 air-new">Your contributions</div>
                <div className="row">
                  <div className="col-lg-8 your-contribution-wrapper">
                    <div className="row">
                      <Accordian title="Savings account" image={Mortgage} />
                      <Accordian title="Kiwisaver" image={Kiwisaver} />
                      <Accordian title="Sharesies" image={Sharesis} />
                      <div className="col-lg-6 account-assets-column  house-wrp-retirement">
                        <div className="merchant-box mt-2">
                          <div className="d-flex">
                            <div className="account-assets-inner">
                              <div className="text-merchant bold">House</div>
                              <div className="text-merchant account-assets-mini-title inter">
                                <span className="new-light-green inter">Value: $530,000</span> |
                                <span className="new-red-color inter"> Owing: $300,000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-lg-6 contribution-inner-left-side">
                      <div className="merchant-box mt-2">
                        <div className="d-flex">
                          <div>
                            <img src={Mortgage} alt="" />
                          </div>
                          <div className="ms-3 d-flex align-items-center">
                            <div className="text-merchant fw-bold saving-account-title">Savings account</div>
                          </div>
                        </div>
                        <div className="account-number">
                          <Arrow />
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>

                  <div className="col-lg-4 contribute-more contribution-right-side">
                    <div className="">
                      <div className="retirement-planner-wrp mt-2">
                        <h4 className="retirement-planner-text bold mb-5 contribute-more-text">
                          Can you contribute more?
                        </h4>
                        <h4 className="retirement-planner-text  second-line-contri">
                          What you have left over per week in your budget:
                        </h4>
                        <div className="text-250">
                          <h2 className="big-head text-center bold">$250</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bold mt-5 air-new">What you’ll have at retirement</div>
                <div className="row">
                  <div className="col-lg-8 retirement-section-2">
                    <div className="row">
                      <div className="col-lg-6 retirement-inner-left-side">
                        <div className="merchant-box mt-2">
                          <div className="d-flex">
                            <div className="acc-image">
                              <img src={Mortgage} alt="" />
                            </div>
                            <div className="ms-3 d-flex align-items-center">
                              <div className="text-merchant bold everyday-account inter">Savings account</div>
                            </div>
                          </div>
                          <div className="account-number inter text-16px">$15,675</div>
                        </div>
                      </div>
                      <div className="col-lg-6 saving-account-box retirement-inner-left-side">
                        <div className="merchant-box mt-2">
                          <div className="d-flex">
                            <div className="acc-image">
                              <img src={Kiwisaver} alt="" className="kiwisaver-img" />
                            </div>
                            <div className=" d-flex align-items-center ms-3">
                              <div className="text-merchant inter bold">Kiwisaver</div>
                            </div>
                          </div>
                          <div className="account-number inter text-16px inter">$4,560</div>
                        </div>
                      </div>
                      <div className="col-lg-6 retirement-inner-left-side">
                        <div className="merchant-box mt-2">
                          <div className="d-flex">
                            <div className="acc-image">
                              <img src={Sharesis} alt="" className="kiwisaver-img" />
                            </div>
                            <div className=" d-flex align-items-center ms-3">
                              <div className="text-merchant bold inter">Sharesies</div>
                            </div>
                          </div>
                          <div className="account-number text-16px inter">$23,890</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 retirement-section-2-right-wrp">
                    <div className="retirement-section-2-right-side">
                      <div className="retirement-planner-wrp">
                        <h4 className="retirement-planner-text ">Total amount at retirement:</h4>
                        <h3 className="big-head bold text-center">$350,980</h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-4 veteran-pension-payment-first">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="bold">NZ Super & Veteran Pension Payments </div>
                        <div className="merchant-box mt-2 py-3">
                          <div className="d-flex">
                            {/* <div>
                            <img src={Mortgage} alt="" />
                          </div> */}
                            <div className=" d-flex align-items-center">
                              <div className="text-merchant fw-bold you-income-text inter">Your income (Pre-tax)</div>
                            </div>
                          </div>
                          <div className="account-number planner big-width"></div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="merchant-box mt-2 py-3 nz-super-option-wrp">
                          <div className="d-flex">
                            {/* <div>
                            <img
                              src={Kiwisaver}
                              alt=""
                              className="kiwisaver-img"
                            />
                          </div> */}
                            <div className="d-flex align-items-center">
                              <div className="text-merchant bold inter">NZ Super option</div>
                            </div>
                          </div>
                          <select class="planner black inter">
                            <option>Single</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8 veteran-pension-payment-second">
                    <div className="col-md-6 col-lg-6 left-section">
                      <div className="d-flex justify-content-between align-items-center taxed-retirement mb-2">
                        <div className="bold">Taxed retirement income</div>
                        <div className="position-relative blue-new bold">
                          Weekly
                          <Chevronleft className="your-account-Chevronleft-icon" />
                        </div>
                      </div>
                      <div className="retirement-planner-wrp">
                        <h4 className="retirement-planner-text"></h4>
                        <h1 className="big-head  text-center bold">$580.50</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Retirement;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Edit } from '../../../assests/images/icons/edit.svg';
import { ReactComponent as Search } from '../../../assests/images/icons/Search.svg';
import LoadingTxt from '../../Loading/LoadingTxt';
import weburls from '../../Weburls/weburls';
import SuperAdminHeader from '../SuperAdminHeader';
import SuperAdminSidebar from '../SuperAdminSidebar';

function SuperAdminPlans() {
  const [active, setActive] = useState(false);
  // const [searchTerm, setSearchTerm] = useState('');
  const [tablefilter,tableFilter] =useState([]);

  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState('');
  const [datasource, setDataSource] = useState([]);
  const filteredData = (e) => {
    // eslint-disable-next-line eqeqeq
    if (e && e.target && typeof e.target.value === 'string') {
      const inputValue = e.target.value.toLowerCase();
    if (inputValue !== '') {
      setValue(e.target.value);
      const filterTable = datasource.filter((o) =>
        Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())),
      );
      tableFilter([...filterTable]);
    } else {
      setValue(e.target.value);
      setDataSource([...datasource]);
    }
  }else{
    console.error('Invalid event or event target.');
  }
}
  const planList = async () => {
    try {
      const response = await axios.get(weburls.Super_admin_plans_list);
      console.log(response.data, 'planlist');
      tableFilter(response.data);
      setDataSource(response.data);
      setTimeout(()=>{
        setLoading(false);
       },2000)
    } catch (error) {
      console.log(error, 'error');
      setLoading(false)

    }
  };

  useEffect(() => {
    planList();
    // setLoading(true);
    // axios
    //   .get(weburls.Super_admin_plans_list)
    //   .then((res) => {
    //     if (res?.data?.data?.length > 0) {
    //       setApiData(res?.data?.data);
    //     }
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoading(false);
    //   });
    filteredData();
  }, []);

  const activeChange = () => {
    setActive(true);
  };

  // Search data
  // const filteredData = apiData?.filter((item) => item?.planName?.toLowerCase().includes(searchTerm?.toLowerCase()));

  return (
    <>
      <div className="container-fluid">
        <SuperAdminHeader />
        <div className="appmain">
          <SuperAdminSidebar />
          <div className="app-main__outer main-front-dashboard-container">
            <div className="row ">
              <div className="col-md-6 col-xl-6 top-head-wrp-company">
                <div className="fs-3 big-head-new ">
                  Good Morning,{' '}
                  <span className="text-jeremy">
                    Admin
                    <div className="hand-img">👋</div>
                  </span>
                </div>
                <div className="fst-normal light-grey">Here's your dashboard of super admin using BudgetBuddie.</div>
              </div>
              <div className="col-xl-6 col-md-6 super-btn">
                <div className="search-wrp-company col-xl-7">
                  <div className="col-lg-12 search-spacing">
                    <div className="search-block">
                      <form>
                        <Search />
                        <input
                          type="text"
                          className="search"
                          value={value}
                          placeholder="Search for a company here"
                          onChange={filteredData}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="button-grp-super col-xl-4">
                  <Link type="button" className="comn-btn-super purple" to="/Plans/NewPlans">
                    New Plans
                    <i class="fa-solid fa-plus plus-icon-style"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div class="table-wrp companies-list-wrapper" id="#down">
              <div className="bold mt-4 mb-3 heading-new">Plans list</div>
              <div class="">
                <div class="col-12 table-horizontal all-plans-list-wrapper">
                  <table class="table-image main-table">
                    <thead>
                      <tr className="purple">
                        <th scope="col">Plan Name</th>
                        <th scope="col">Plan Mode</th>
                        <th scope="col">Plan Amount ($)</th>
                        <th scope="col">Plan Description</th>

                        <th scope="col">Status</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody className="space-table">
                      {/* {console.log(tablefilter,"tablefilter-list")} */}
                      {loading ? (
                        <div className="no-data-found-td">
                          <LoadingTxt />
                        </div>
                      ) : value?.length > 0 ? (
                        tablefilter && tablefilter?.length > 0 ? (
                          tablefilter?.map((item) => (
                            <tr onClick={activeChange} className="cursor-pointer" key={item.planId}>
                              <th>
                                <div className="air-image">
                                  <h3 className="table-content display">{item.planName}</h3>
                                  {/* <Link className="amount-active" to="/Dashbaordpanel">
                                      Open <i class="fa-solid fa-arrow-right"></i>
                                    </Link> */}
                                </div>
                              </th>
                              <td className="table-text-color">{item.planMode}</td>
                              <td className="table-text-color">{`$ ${item.planAmount}`}</td>
  
                              <td className="table-text-color ">
                                <textarea className="description-plan">{item.planDescription}</textarea>
                              </td>
                              <td>
                                {item.status === 1 ? (
                                  <div className="active-status">
                                    <button className="active-btn table-text-color">
                                      <i class="fa-solid fa-circle"></i> Active
                                    </button>
                                  </div>
                                ) : (
                                  <div className="in-active-status-div">
                                    <button className="inactive-button table-text-color">
                                      <i class="fa-solid fa-circle"></i> Inactive
                                    </button>
                                  </div>
                                )}
                              </td>
                              <td className="table-text-color">
                                <Link to={`/Plans/EditPlans/${item.planId}`}>
                                  <Edit />
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : ("No data found")) : (
                          tablefilter?.map((item) => (
                            <tr onClick={activeChange} className="cursor-pointer" key={item.planId}>
                              <th>
                                <div className="air-image">
                                  <h3 className="table-content display">{item.planName}</h3>
                                  {/* <Link className="amount-active" to="/Dashbaordpanel">
                                      Open <i class="fa-solid fa-arrow-right"></i>
                                    </Link> */}
                                </div>
                              </th>
                              <td className="table-text-color">{item.planMode}</td>
                              <td className="table-text-color">{`$ ${item.planAmount}`}</td>
  
                              <td className="table-text-color ">
                                <textarea className="description-plan">{item.planDescription}</textarea>
                              </td>
                              <td>
                                {item.status === 1 ? (
                                  <div className="active-status">
                                    <button className="active-btn table-text-color">
                                      <i class="fa-solid fa-circle"></i> Active
                                    </button>
                                  </div>
                                ) : (
                                  <div className="in-active-status-div">
                                    <button className="inactive-button table-text-color">
                                      <i class="fa-solid fa-circle"></i> Inactive
                                    </button>
                                  </div>
                                )}
                              </td>
                              <td className="table-text-color">
                                <Link to={`/Plans/EditPlans/${item.planId}`}>
                                  <Edit />
                                </Link>
                              </td>
                            </tr>
                          ))
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuperAdminPlans;

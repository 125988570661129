import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Upload } from '../../assests/images/icons/upload.svg';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import weburls from '../Weburls/weburls';

const schema = yup.object().shape({
  firstName: yup.string().required('*Please enter firstname').min(3, '*First name must be consist min 3 character'),
  lastName: yup.string().required('*Please enter lastname').min(3, '*Last name must be consist min 3 character'),
  phoneNumber: yup
    .string()
    .required('*Please enter phone number')
    .matches(/^[0-9]+$/, '*Please enter only number')
    .matches(/^\d{10}$/, '*Enter a 10-digit number'),
  email: yup
    .string()
    .required('*Please enter email')
    .email('*Please enter a valid email')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, '*Please enter proper email'),
  groupName: yup.string().required('*Please enter group name').min(3, '*Group name must be consist min 3 character.'),
  groupWebsite: yup.string().required('*Please enter group website.'),
});
const Newgroup = ({ show }) => {
  const inputFile = useRef(null);

  // const handleCsvFile = () => {
  //   inputFile.current.click();
  // };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const advisorsId = JSON.parse(localStorage.getItem('userData'));
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    planDescription: '',
    groupWebsite: '',
    groupName: '',
    advisorId: advisorsId.advisorId,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const saveGroup = async (data) => {
    try {
      console.log(data);
      const response = await axios.post(weburls.Add_group, formData);
      setFormData(response);
      console.log(response,"add group")
      reset();
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate('/Groups');
        }, 3000);
      } else if (response.status === 404) {
        toast.error('Something went wrong');
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  return (
    <>
      <div className="container-fluid">
        <Header />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer add-new-group-main-section">
            <ToastContainer
              position="top-right"
              autoClose={5000} // Auto close after 5 seconds
              closeOnClick
            />

            <div className="row">
              <div className="col-md-12 mb-3">
                <h3 className="small-heading">
                  <Link to="/Groups">
                    <i class="fa-solid fa-arrow-left"></i> Back to groups
                  </Link>
                </h3>
              </div>
            </div>
            <form onSubmit={handleSubmit(saveGroup)}>
              <div className="setting_background_color">
                <div className="d-flex justify-content-between">
                  <div className="fs-3 main-heading-new-group">
                    <h2 className="bold black big-top-heading">Add a new group</h2>
                    <div className="setting_text_color">
                      Enter in your group details, they'll get an email once you're finished.
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Main contact </h5>
                    <div className="setting_text_color mb-3">This is who to contact about their plan.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="First name"
                            {...register('firstName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="firstName"
                            value={formData.firstName}
                          />
                        </div>
                        <p className="error-message">{errors.firstName?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Last name"
                            {...register('lastName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="lastName"
                            value={formData.lastName}
                          />
                        </div>
                        <p className="error-message">{errors.lastName?.message}</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-xl-7 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Email"
                            {...register('email', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="email"
                            value={formData.email}
                          />
                        </div>
                        <p className="error-message">{errors.email?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-5">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Phone number"
                            {...register('phoneNumber', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="phoneNumber"
                            value={formData.phoneNumber}
                          />
                        </div>
                        <p className="error-message">{errors.phoneNumber?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Group details</h5>
                    <div className="setting_text_color mb-3">Enter in the details of the group.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Group website"
                            {...register('groupWebsite', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="groupWebsite"
                            value={formData.groupWebsite}
                          />
                        </div>
                        <p className="error-message">{errors.groupWebsite?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Group name"
                            {...register('groupName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="groupName"
                            value={formData.groupName}
                          />
                        </div>
                        <p className="error-message">{errors.groupName?.message}</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div class="col-md-10 col-lg-10 input-upload">
                        {/* <div className="upload" onClick={handleCsvFile}>
                          <input type="text" class="form-control input_border " placeholder="Group logo" />
                          <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                          <Upload className="upload-logo" />
                        </div> */}
                        <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">Select a File
                          <input type="file" class="form-control input_border " placeholder="Profile Image" id='file-format' name="file-input"/>
                              <div className="upload">
                               <Upload className="upload-logo" />
                              </div>
                          </label>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="profile-img upload-img">
                          <img src={Profile} alt="profile" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Assigned advisors</h5>
                    <div className="setting_text_color mb-3">Enter in the assigned advisor to the group.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        {/* <div class="input-group mb-3">
                          <input type="text" class="form-control input_border" placeholder="Assigned advisor" />
                        </div> */}
                      </div>
                      <div className="col-md-12 col-xl-6">
                      <select class="form-select input_border" {...register('advisorName2')}>
                          <option selected>Assigned advisor</option>
                          <option> Bill to 2</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Plan details</h5>
                    <div className="setting_text_color">Enter in the type of plan the group is using.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7 mb-3">
                    <div className="row mt-3 plan-wrp">
                      <div className="col-md-12 col-xl-12 mb-4">
                        <input type="text" class="form-control input_border" placeholder="Number of plans" />
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <select class="form-select input_border mb-3">
                          <option selected>Select a plan</option>
                          <option selected>Select a plan</option>
                          <option selected>Select a plan</option>
                          <option selected>Select a plan</option>
                        </select>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <select class="form-select input_border mb-3">
                          <option selected> Billing Frequency</option>
                          <option selected> Billing Frequency</option>
                          <option selected> Billing Frequency</option>
                          <option selected> Billing Frequency</option>
                          <option selected> Billing Frequency</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-12 mt-4">
                  <h2 className="bold black big-top-heading mt-3">Add individual client details</h2>
                  <div className="setting_text_color">
                    Enter in your clients details, they’ll get an email once you’re finished.
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="table-wrp mt-3 add-new-group-table-wrp">
                  <div class="row">
                    <div class="col-12 table-horizontal">
                      <table class="table-image group-table">
                        <thead className="purple">
                          <tr>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone number</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody className="space-table">
                          <tr>
                            <th className="">
                              <div class="input-group">
                                <input type="text" className="form-control input_border comn-field" placeholder="" />
                              </div>
                            </th>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <a href="#" class="black">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th className="">
                              <div class="input-group">
                                <input type="text" className="form-control input_border comn-field" placeholder="" />
                              </div>
                            </th>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <a href="#" class="black">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th className="">
                              <div class="input-group">
                                <input type="text" className="form-control input_border comn-field" placeholder="" />
                              </div>
                            </th>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <a href="#" class="black">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="setting_background_color mt-5">
                <div className="d-flex justify-content-between">
                  <div className="fs-3 fw-bolder">
                    <h2 className="bold black big-top-heading">Upload a list of clients</h2>
                    <div className="setting_text_color">
                      If you have a bunch of clients to add, upload a csv file of all their details and we'll handle it.
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="bold black heading-new">Upload your file here</h5>
                    <div className="setting_text_color">Upload your csv file.</div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="col-md-12">
                          <div class="input-group mb-3">
                          <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">Select a File
                          <input type="file" class="form-control input_border " placeholder="Profile Image" id='file-format' name="file-input"/>
                              <div className="upload">
                               <Upload className="upload-logo" />
                              </div>
                          </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="setting_background_color mt-5">
                <div className="">
                  <div className="fs-3 fw-bolder">
                    <h2 className="bold black big-top-heading">Save your details</h2>
                    <div className="setting_text_color">
                      Once you've send all of your users, they'll get an email notification to use BudgetBuddie.
                    </div>
                  </div>
                  <hr />
                  <div className="row mt-3">
                    <div className="col-lg-6 col-sm-12"></div>
                    <div className="col-lg-6 col-sm-12 save-your-detail-btns">
                      <div class="button">
                        <button type="button" class="comn-btn button_white save-grp-btn w-236">
                          Cancel
                        </button>
                        <button type="submit" class="comn-btn save-grp-btn w-236">
                          Save group<i class="fa-solid fa-plus plus-icon-style"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Newgroup;

import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import faker from 'faker';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );
  export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    layout: {
      padding: {
        left: -10,
        bottom:-15,
      }
    },

  
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        stacked: true,
        border: {
          display: false,
        },
        ticks:{
          display: false,
          padding: -19,

        }
      },
      y: {
        ticks: {
          display: false,
        },
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','January', 'February', 'March', 'April', 'May', 'June', 'July','January', 'February', 'March', 'April', 'May', 'June', 'July','March', 'April', 'May', 'June', 'July','January', 'February', 'March', 'April','June'];

  export const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data:[0,1,2,3,2,3,4,2,5,3,2,4,5,3,5,6,4,3,4,5,6,5,4,6,5,7,5,6,8,9,10,11,4],
        borderColor: 'rgba(78, 195, 118, 1)',
        backgroundColor: 'rgba(78, 195, 118, 0.24)',
        borderWidth: 1,
        radius:0,
        tension:0.4,
      },
    ],
  };
  export function Linecart() {
    return <Line options={options} data={data} className="barcart"/>;
  }





import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Reactlogo from '../../assests/images/Black Logo 1.png';
import Profile from '../../assests/images/icons/image.png';
import Advice from '../../assests/images/icons/super-advice.png';

const Header = ({ toggleBanner, Group }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };
  return (
    <>
      <div class="row header-wrp">
        <div class="col-md-6 col-sm-4 left">
          <div className="logo">
            <a class="navbar-brand" href="#">
              <img src={Reactlogo} alt="logo" />
              <h4 className="heading inline black">BudgetBuddie</h4>
            </a>
            <div className="super-advice">
              <a href="#">
                <img src={Advice} alt="advice" />
              </a>
            </div>
            <div class="topnav">
              <div id="myLinks" className={isActive ? 'bg-salmon' : ''}>
                {/* <a href="">Dashborad</a> */}
                <Link to="/"> Dashborad</Link>
                <Link to="/Clients"> Clients</Link>
                <Link to="/Groups"> Group</Link>
                <Link to="/Setting"> Setting</Link>
                {/* <a href="#contact">Contact</a>
                <a href="#about">About</a> */}
              </div>
              <div className="button-grp">
                <Link type="button" className="comn-btn" to="/Clients/Newclient" onClick={toggleBanner}>
                  New client
                  <i class="fa-solid fa-plus plus-icon-style"></i>
                </Link>
                <Link type="button" className="comn-btn purple" to="/Groups/Newgroup" onClick={Group}>
                  New group
                  <i class="fa-solid fa-plus plus-icon-style"></i>
                </Link>
              </div>
              <a href="javascript:void(0);" class="icon" onClick={handleClick}>
                <i class="fa fa-bars"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-4 right-section">
          <div className="button-grp">
            <Link type="button" className="comn-btn" to="/Clients/Newclient" onClick={toggleBanner}>
              New client
              <i class="fa-solid fa-plus plus-icon-style"></i>
            </Link>
            <Link type="button" className="comn-btn purple" to="/Groups/Newgroup" onClick={Group}>
              New group
              <i class="fa-solid fa-plus plus-icon-style"></i>
            </Link>
          </div>
          <div className="profile-img">
            <Link to="/Setting">
              <img src={Profile} alt="profile" />
            </Link>
          </div>
          {/* <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Dropdown button
            </button>
            <ul
              class="dropdown-menu dropdown-menu-dark"
              aria-labelledby="dropdownMenuButton2"
            >
              <li>
                <a class="dropdown-item active" href="#">
                  Action
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
              <li>
                <hr class="dropdown-divider"></hr>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Separated link
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default Header;

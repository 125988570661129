import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../src/assests/bootstrap/css/bootstrap.css';
import './App.css';
import './assests/css/style.css';
import EditClient from './Components/ClientDetails/index';
import DetailsPage from './Components/DetailPage/index';
import Account from './Components/Innerpanel/Account';
import AccountBalances from './Components/Innerpanel/AccountBalances/index';
import AddFinancial from './Components/Innerpanel/AddNewGoals';
import Addwidgets from './Components/Innerpanel/Addwdigets/Addwidgets';
import BudgetSummary from './Components/Innerpanel/BudgetSummary';
import CashFlow from './Components/Innerpanel/CashFlow';
import Cashflowcart from './Components/Innerpanel/Cashflowcalender/Cashflowcalender';
import Dashboardinner from './Components/Innerpanel/Dashboardinner';
import Editbudget from './Components/Innerpanel/Editbudget';
import EditGoalBudget from './Components/Innerpanel/EditGoalBudget';
import Financial from './Components/Innerpanel/Financial';
import Groceriesedit from './Components/Innerpanel/Groceriesedit/Groceriesedit';
import NetWorth from './Components/Innerpanel/NetWorth';
import Retirement from './Components/Innerpanel/Retirement';
import Merchats from './Components/Merchats/index';
import Merchantdetails from './Components/Merchats/MerchatsDetail';
import Activepage from './Components/pages/Activepage';
import Clients from './Components/pages/Clients';
import Dashboard from './Components/pages/Dashboard';
import GroupDetail from './Components/pages/GroupDetail';
import Groups from './Components/pages/Groups';
import Login from './Components/pages/Login';
import Newclient from './Components/pages/Newclient';
import Newgroup from './Components/pages/Newgroup';
import Setting from './Components/pages/Settings';
import PrivateRoutes from './Components/Private';
import SettingPage from './Components/Setting/index';
import EditCompany from './Components/SuperAdminDashboard/EditSuperAdmin/EditCompany';
import EditPlans from './Components/SuperAdminDashboard/EditSuperAdmin/EditPlans';
import NewCompany from './Components/SuperAdminDashboard/NewCompany';
import NewPlans from './Components/SuperAdminDashboard/NewPlans';
import SuperAdminDashboard from './Components/SuperAdminDashboard/SuperAdminDashboard';
import CompaniesList from './Components/SuperAdminDashboard/SuperAdminLists/CompaniesList';
import SuperAdminPlans from './Components/SuperAdminDashboard/SuperAdminLists/SuperAdminPlans';
import Transactions from './Components/Transactions/index';
import EditIncome from './Components/ModalForm/AddIncome/EditIncome';

function App() {
  // const roles = JSON.parse(localStorage.getItem('roleId'));

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes allowedRoles={[2,3,4]} />}>
            <Route path="/Dashboard" element={<Dashboard />}></Route>
            <Route path="/Clients" element={<Clients />}></Route>
            <Route path="/Groups" element={<Groups />}></Route>
            <Route path="/Setting" element={<Setting />}></Route>
            <Route path="/Clients/Newclient" element={<Newclient />}></Route>
            <Route path="/Clients/Activepage" element={<Activepage />}></Route>
            <Route path="/Clients/Edit/:id" element={<EditClient />}></Route>
            <Route path="/Clients/Clientdetails" element={<Activepage />}></Route>
            <Route path="/Groups/GroupDetail/:id" element={<GroupDetail />}></Route>
            <Route path="/Groups/Details" element={<DetailsPage />}></Route>
            <Route path="/Groups/Newgroup" element={<Newgroup />}></Route>
            <Route path="/Dashbaordpanel" element={<Dashboardinner />}></Route>
            <Route path="/Merchats" element={<Merchats />}></Route>
            <Route path="/Merchatsdetails" element={<Merchantdetails />}></Route>
            <Route path="/Transactions" element={<Transactions />}></Route>
            <Route path="/AccountBalances" element={<AccountBalances />}></Route>
            <Route path="/SettingPage" element={<SettingPage />}></Route>
            <Route path="/BudgetSummary" element={<BudgetSummary />}></Route>
            <Route path="/Editbudget" element={<Editbudget />}></Route>
            <Route path="/Groceriesedit" element={<Groceriesedit />}></Route>
            <Route path="/NetWorth" element={<NetWorth />}></Route>
            <Route path="/Financial" element={<Financial />}></Route>
            <Route path="/Retirement" element={<Retirement />}></Route>
            <Route path="/CashFlow" element={<CashFlow />}></Route>
            <Route path="/Account" element={<Account />}></Route>
            <Route path="/Clients/Cashflowcalender" element={<Cashflowcart />}></Route>
            <Route path="/Financial/AddNewFinancial" element={<AddFinancial />}></Route>
            <Route path="/EditGoal" element={<EditGoalBudget />}></Route>
            <Route path="/Addwidgets" element={<Addwidgets />}></Route>

            {/* Super admin side */}
          </Route>

          <Route element={<PrivateRoutes allowedRoles={[1]} />}>
            <Route path="/SuperAdmin" element={<SuperAdminDashboard />} />
            <Route path="/Companies" element={<CompaniesList />}></Route>
            <Route path="/Companies/NewCompany" element={<NewCompany />}></Route>
            <Route path="/Plans" element={<SuperAdminPlans />}></Route>
            <Route path="/Plans/NewPlans" element={<NewPlans />}></Route>
            <Route path="/Companies/EditCompany" element={<EditCompany />}></Route>
            <Route path="/Plans/EditPlans/:id" element={<EditPlans />}></Route>
          </Route>
          <Route path="/" element={<Login />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;

import $ from 'jquery';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Ellipse11 from '../../assests/images/ConnectAccounts/Ellipse 10-1.png';
import Ellipse10 from '../../assests/images/ConnectAccounts/Ellipse 10.png';
import Ellipse12 from '../../assests/images/ConnectAccounts/Ellipse 11.png';
import MILFOR from '../../assests/images/ConnectAccounts/Ellipse 12.png';
import KIWIWEAITH from '../../assests/images/ConnectAccounts/Ellipse 13.png';
import JUNO from '../../assests/images/ConnectAccounts/Ellipse 14.png';
import GENERAYE from '../../assests/images/ConnectAccounts/Ellipse 15.png';
import KERNAL from '../../assests/images/ConnectAccounts/Ellipse 16.png';
import Ellipse17 from '../../assests/images/ConnectAccounts/Ellipse 17.png';
import Sharesies from '../../assests/images/ConnectAccounts/Ellipse 19.png';
import Ellipse2 from '../../assests/images/ConnectAccounts/Ellipse 2.png';
import Add from '../../assests/images/ConnectAccounts/Ellipse 20.png';
import TSB from '../../assests/images/ConnectAccounts/Ellipse 3.png';
import BNZ from '../../assests/images/ConnectAccounts/Ellipse 4.png';
import ANZ from '../../assests/images/ConnectAccounts/Ellipse 5.png';
import ASB from '../../assests/images/ConnectAccounts/Ellipse 6.png';
import Kiwibank from '../../assests/images/ConnectAccounts/Ellipse 7.png';
import Ellipse8 from '../../assests/images/ConnectAccounts/Ellipse 8.png';
import Ellipse9 from '../../assests/images/ConnectAccounts/Ellipse 9.png';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import Profile from '../../assests/images/icons/image.png';
import Refresh from '../../assests/images/icons/refresh.png';
import MainSidebar from '../MainSidebar';

const Account = () => {
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            {/* <MainSidebar toggleSidebar={toggleSidebar} /> */}
            <div className="app-main__outer connect-your-accounts-main-wrapper">
              <div className="row connects-wrp">
                <div className="col-md-4 col-xl-4 connect-your-acccount-title">
                  <div className="fs-3  bold big-head-new">Connect your accounts</div>
                  <div className="fst-normal setting_text_color">
                    Lets get your accounts connected so we can crunch the numbers for you.
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 right-section re-sync-connection-section">
                  <div className="profile-dashboard">
                    <Link to="/Clients/Activepage">
                      <button className="comn-btn bold">
                        Re-sync connections
                        <img src={Refresh} />
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 right-section connect-your-account-back-portal">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  mt-5">
                <div className="col-lg-7 connect-bank-wrp connect-your-account-bank-connection">
                  <div className="bold black rbc-header ">Add a bank connection</div>
                  <div className="bank-connection">
                    <div className="bank-connection-img">
                      <img src={TSB} alt="" />
                    </div>
                    <div className="bank-connection-img">
                      <img src={BNZ} alt="" />
                    </div>
                    <div className="bank-connection-img">
                      <img src={ANZ} alt="" />
                    </div>
                    <div className="bank-connection-img">
                      <img src={ASB} alt="" />
                    </div>
                    <div className="bank-connection-img">
                      <img src={Kiwibank} alt="" />
                    </div>
                    <div className="bank-connection-img">
                      <img src={Ellipse8} alt="" />
                    </div>
                    <div className="bank-connection-img">
                      <img src={Ellipse9} alt="" />
                    </div>
                    <div className="bank-connection-img">
                      <img src={Ellipse10} alt="" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="bold black rbc-header">Add a Kiwisaver connection</div>
                    <div className="bank-connection">
                      <div className="bank-connection-img">
                        <img src={Ellipse11} alt="" />
                      </div>
                      <div className="bank-connection-img">
                        <img src={Ellipse12} alt="" />
                      </div>
                      <div className="bank-connection-img">
                        <img src={MILFOR} alt="" />
                      </div>
                      <div className="bank-connection-img">
                        <img src={GENERAYE} alt="" />
                      </div>
                      <div className="bank-connection-img">
                        <img src={KERNAL} alt="" />
                      </div>
                      <div className="bank-connection-img">
                        <img src={KIWIWEAITH} alt="" />
                      </div>
                      <div className="bank-connection-img">
                        <img src={JUNO} alt="" />
                      </div>
                      <div className="bank-connection-img">
                        <img src={Ellipse17} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 add-an-investment-conn-wrapper">
                    <div className="bold black rbc-header ">Add an investment connection</div>
                    <div className="investment-connection">
                      <div className="investment-connection-img">
                        <img src={Sharesies} alt="" />
                      </div>
                      <div className="investment-connection-img">
                        <img src={Add} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="live-connections-box live-connection-main-wrapper">
                    <div className="d-flex justify-content-between">
                      <div className="bold black rbc-header">Live connections</div>
                      <div>
                        <Edit />
                      </div>
                    </div>

                    <div className="merchant-box mt-2">
                      <div className="d-flex">
                        <div className="investment-connection-img">
                          <img src={BNZ} alt="" />
                        </div>
                        <div className="ms-3 mt-2 lcs-inner">
                          <div className="live-connection">BNZ</div>
                          <div className="setting_text_color-2">Updated: 10/10/2022 1pm</div>
                        </div>
                      </div>
                      <div className="account-number">Connected</div>
                    </div>
                    <div className="merchant-box mt-2">
                      <div className="d-flex">
                        <div className="investment-connection-img">
                          <img src={Sharesies} alt="" />
                        </div>
                        <div className="ms-3 mt-2 lcs-inner">
                          <div className="live-connection">Sharesies</div>
                          <div className="setting_text_color-2">Updated: 10/10/2022 1pm</div>
                        </div>
                      </div>
                      <div className="account-number">Connected</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mt-4 ms-5 connect-account-akahu-box-wrp">
                  <div className="akahu-box">
                    <div className="d-flex align-items-center mb-3">
                      <div className="bank-connection-img">
                        <img src={Ellipse2} alt="" className="akahu-img" />
                      </div>
                      <div className="bold black who-akahu">Who is Akahu?</div>
                    </div>
                    <div className="setting_text_color mt-2">Akahu is New Zealand’s open finance platform.</div>
                    <div className="setting_text_color mt-4">
                      Akahu makes it simple to securely access the data that your bank holds about you and to provide
                      that data with platforms like BudgetBuddie.
                    </div>
                    <div className="setting_text_color mt-3">
                      Akahu uses a 256-bit bank grade encryption to keep your data safe & secure.
                    </div>
                    <div className="setting_text_color mt-3">To find out more about Akahu, click here.</div>
                    <div className="bold black who-akahu mt-4">How does the connection work?</div>
                    <div className="setting_text_color mt-3">
                      The secure connection with Akahu will pull through your bank balances, transactional information
                      and account details.
                    </div>
                    <div className="setting_text_color mt-3">
                      You’ll be redirected to Akahu’s website to establish the connection.
                    </div>
                    <div className="setting_text_color mt-3">To manage the connection use this link: my.akahu.io</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Account;

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import { ReactComponent as Upload } from '../../../assests/images/icons/upload.svg';
import weburls from '../../Weburls/weburls';
import '../AddIncome/addIncome.css';

const schema = yup.object().shape({
  liabilityName: yup.string().required('*Please enter liability name'),
  value: yup
    .string()
    .required('*Please enter liability value')
    .matches(/^[0-9]+$/, '*Please enter number only'),

  assetLink: yup.string().required('*Please enter liability assets link'),
});

const EditLiability = ({ liabilityOpen, setLiabilityOpen, liabilityUuid, fetchLiability }) => {
  const assetsId = JSON.parse(localStorage.getItem('assetsId'));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [editLiabilityData, setEditLiabilityData] = useState({
    userUuid: '',
    liabilityName: '',
    liabilityUuid: liabilityUuid,
    value: '',
    assetId: assetsId,
    assetLink: '',
    assetImage: '',
    assetAvatar: '',
  });
  const [formError, setformError] = useState('');

  let clientUuid = null;

  try {
    const clientUserUuidData = localStorage.getItem('client-userUuid');
    if (clientUserUuidData) {
      const parsedData = JSON.parse(clientUserUuidData);
      clientUuid = parsedData[0]?.userUuid;
    }
  } catch (error) {
    console.error('Error parsing client-userUuid data:', error);
  }

  const handleClose = () => {
    setLiabilityOpen(!liabilityOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const uuId = clientUuid;
    setEditLiabilityData((prev) => ({ ...prev, userUuid: uuId, [name]: value }));
  };

  const fetchData = () => {
    axios
      .get(`${weburls.Get_Liability_By_Id}${liabilityUuid}`)
      .then((response) => {
        console.log('res', response);
        setEditLiabilityData(response?.data);
      })
      .catch((error) => {
        console.error('Error fetching  data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmitData = async () => {
    try {
      const response = await axios.put(weburls.Update_Liability, editLiabilityData);
      if (response.status === 200) {
        toast.success(response.data.message || 'Liability update successfully', {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          setLiabilityOpen(false);
          fetchLiability();
        }, 3000);
      } else if (response.status === 500) {
        console.log('getting eroor');
      }
    } catch (error) {
      console.error('error', error);
      setLiabilityOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
    reset();
  };

  const handleDeleteLiability = async () => {
    try {
      const response = await axios.delete(`${weburls.Delete_Liability}${clientUuid}/${liabilityUuid}`);
      if (response.status === 200) {
        toast.success(response?.data?.message || 'Liability delete successfully', {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          setLiabilityOpen(false);
          fetchLiability();
        }, 3000);
      } else if (response.status === 500) {
        toast.error('Something went wrong please try again.');
      } else {
        console.error('Delete request failed please try again', response);
      }
    } catch (error) {
      console.error('Error:', error);
      setLiabilityOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };


  return (
    <div className={`modal-background ${liabilityOpen ? 'open-modal' : ''} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div className="modal-content">
        <button onClick={handleClose} className="close-modal-button">
          <i class="fa fa-times"></i>
        </button>
        <div className="setting_background_color">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black big-top-heading">Edit your liability</span>
            </div>
          </div>
          <hr />
          <form onSubmit={handleSubmit(onSubmitData)}>
            <div className="row add_income-wrp">
              <div className="col-md-12 col-sm-12 col-xl-4 mb-3">
                <h5 className="bold black heading-new">Liability details</h5>
                <div className="setting_text_color popup-text">This will be displayed on your profile.</div>
              </div>
              <div className="col-md-12 col-sm-12 col-xl-8">
                <div className="row">
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Liability Name :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="liabilityName"
                        class="form-control input_border"
                        placeholder="Liability name"
                        defaultValue={editLiabilityData?.liabilityName}
                        {...register('liabilityName', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.liabilityName?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Liability Value :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="value"
                        class="form-control input_border"
                        placeholder="Asset value"
                        defaultValue={editLiabilityData?.value}
                        {...register('value', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.value?.message}</p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Link to an asset :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="assetLink"
                        class="form-control input_border "
                        placeholder="Link to an asset?"
                        defaultValue={editLiabilityData?.assetLink}
                        {...register('assetLink', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.assetLink?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Liability Image :
                    </label>
                    <div class="input-group mb-3 date-picker-div">
                      <div class="input-group">
                        <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                          Liability image
                          <input
                            type="file"
                            class="form-control input_border "
                            placeholder="Liability Image"
                            id="file-format"
                            name="file-input"
                            // onChange={upload}
                          />
                          <div className="upload">
                            <Upload className="upload-logo" />
                          </div>
                        </label>
                      </div>
                    </div>
                    {/* <p className="error-message">{errors.lastPaymentDate?.message}</p> */}
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-12 col-xl-12 col-sm-12">
                    <div className="row mt-2 plan-wrp">
                      <div className="col-xl-6 col-md-12">
                        <label htmlFor="event-start" className="mb-1 labels-text">
                          How Often :
                        </label>
                        <select
                          class="form-select input_border mb-3"
                          {...register('howOften', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          onChange={handleChange}
                        >
                          <option value="">How often?</option>

                          <option value="weekly">Weekly</option>
                          <option value="fortnightly">Fortnightly</option>
                          <option value="monthly">Monthly</option>
                        </select>{' '}
                        <p className="error-message">{errors.howOften?.message}</p>
                      </div>

                      <div className="col-md-12 col-xl-6">
                        <label htmlFor="event-start" className="mb-1 labels-text">
                          Account Associated :
                        </label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            name="accountAssociated"
                            class="form-control input_border"
                            onChange={handleChange}
                            placeholder="Account associated"
                          />
                        </div>
                        <p className="error-message">{errors.accountAssociated?.message}</p>
                      </div>
                    </div>
                  </div>
                  <div class="button modal-popup-btn-wrp">
                    <button type="submit" class="comn-btn common-btn-style">
                      Save income
                    </button>
                  </div>
                </div> */}
              </div>
              <div class="button modal-popup-btn-wrp">
                <button type="submit" class="comn-btn common-btn-style">
                  Save edits
                </button>
                <button type="button" className="comn-btn delete-btn" onClick={handleDeleteLiability}>
                  Delete liability
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditLiability;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as Bigplus } from '../../../assests/images/icons/Add_round.svg';
import { ReactComponent as Edit } from '../../../assests/images/icons/more.svg';
import LoadingTxt from '../../Loading/LoadingTxt';
import AccountTransferModal from '../../ModalForm/AccountTransfer/AccountTransferModal';
import EditAccountTransfer from '../../ModalForm/AccountTransfer/EditAccountTransfer';
import weburls from '../../Weburls/weburls';
const Accountransfer = () => {
  const [active, setActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [editAccount, setEditAccount] = useState(false);
  const [accountApiData, setAccountApiData] = useState([]);
  const [transferUuid, setTransferUuid] = useState(null);
  // const clientData = JSON.parse(localStorage.getItem('client-userUuid'));
  // const clientUuid = clientData[0]?.userUuid;

  let clientUuid = null;

  try {
    const clientUserUuidData = localStorage.getItem('client-userUuid');
    if (clientUserUuidData) {
      const parsedData = JSON.parse(clientUserUuidData);
      clientUuid = parsedData[0]?.userUuid;
    }
  } catch (error) {
    console.error('Error parsing client-userUuid data:', error);
  }

  const activeChange = () => {
    setActive(true);
  };

  const handleModalForm = () => {
    setModalOpen(true);
    setEditAccount(false);
  };

  const handleModalEditForm = (id) => {
    setTransferUuid(id);
    setModalOpen(true);
    setEditAccount(true);
  };
  const formatDate = (datetimeString) => {
    const date = new Date(datetimeString);
    return date.toLocaleDateString();
  };

  const fetchAccountTransfer = async () => {
    try {
      const response = await axios.get(`${weburls.Account_Transfer_Get_List}${clientUuid}`);
      if (response?.data?.transfers?.length > 0) {
        setAccountApiData(response?.data?.transfers);
      } else {
        console.log('Getting error');
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);

      console.error('error', error);
      // toast.error('Something went wrong please try again.', {
      //   position: 'top-right',
      //   autoClose: 2000,
      // });
    }
  };

  useEffect(() => {
    fetchAccountTransfer();
  }, []);

  // const handleDelete = (id) => {
  //   const updatedItems = [...accountApiData];
  //   updatedItems.splice(id, 1);
  //   setAccountApiData(updatedItems);
  //   toast.success('Data deleted successfully..', {
  //     position: 'top-right',
  //     autoClose: 2000,
  //   });
  // };
  return (
    <>
      <div className="row reoccuring-wrp">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="col-xl-12 top-head-section">
            <h3 className="semi-heading-edit-budget">Your regular account transfers</h3>
            <p>
              Your transfers are things like transferring money regularly to a savings account. This allows you to
              forecast what your account balances will be in the future.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="col-lg-12 new-table regular-account-transfer-wrapper">
          <div>
            <div className="table-horizontal regular-account-transfer-main-side">
              <div class="col-12 table-horizontal">
                <table class="table-image new-table regular-account-transfer-inner">
                  <thead>
                    <tr>
                      <th scope="col">Expense name</th>
                      <th scope="col">Transfer amount</th>
                      <th scope="col">Last transfer date</th>
                      <th scope="col">How Often? </th>
                      <th scope="col">Transfer from</th>
                      <th scope="col">Transfer to</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="space-table">
                    {loading ? (
                      <div className="no-data-found-td">
                        <LoadingTxt />
                      </div>
                    ) : accountApiData?.length > 0 ? (
                      accountApiData &&
                      accountApiData.map((item, index) => {
                        return (
                          <tr onClick={activeChange} className="cursor-pointer">
                            <th>
                              <div className="air-image">
                                <h3 className="table-content display">{item.expenseType}</h3>
                              </div>
                            </th>
                            <td className="table-text-color">
                              <div className="planner">{item.transferAmount}</div>
                            </td>
                            <td className="table-text-color">
                              <div className="planner">{formatDate(item.lastTransferDate)}</div>
                            </td>

                            <td className="table-text-color">
                              <select className="planner weekly-month-planner">
                                <option>{item.howOften}</option>
                              </select>
                            </td>
                            <td className="table-text-color">
                              <div className="planner big-planner">{item.transferFrom}</div>
                            </td>
                            <td className="table-text-color">
                              <div className="planner big-planner">{item.transferTo}</div>
                            </td>
                            <td className="table-text-color" onClick={() => handleModalEditForm(item.transferUuid)}>
                              <Edit />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        {' '}
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Savings</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <div className="edit-delete-icon">
                            <td className="table-text-color" onClick={handleModalEditForm}>
                              <Edit />
                            </td>

                            <td className="table-text-color" 
                            // onClick={() => handleDelete('index')}
                            >
                              <i class="fa fa-trash delete-icon-style" aria-hidden="true"></i>
                            </td>
                          </div>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Holiday</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <div className="edit-delete-icon">
                            <td className="table-text-color" onClick={handleModalEditForm}>
                              <Edit />
                            </td>

                            <td className="table-text-color" 
                            // onClick={() => handleDelete('index')}
                            >
                              <i class="fa fa-trash delete-icon-style" aria-hidden="true"></i>
                            </td>
                          </div>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <div className="income-btn-wrp col-xl-12">
                  <button className="new-btn red-btn" onClick={handleModalForm}>
                    Add Transfer <Bigplus />
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-xl-9 mt-5 total-income d-flex income-after-background edit-your-budget-total-income-expenses-wrapper"
              style={{ alignItems: 'center' }}
            >
              <div className="inline col-md-4">
                <h4 className="table-content bold">Total expenses & transfers</h4>
              </div>
              <div className="inline col-md-4 expenses-transfers-section">
                <div className="content-blck"></div>
              </div>
              <div className="inline col-md-4 expenses-transfers-section">
                <div className="content-blck planner-for-weekly">
                  <select className="planner">
                    <option>Weekly</option>
                    <option>Weekly 2</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row reoccuring-wrp">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="col-xl-12 top-head-section">
                  <h3>Your money left over</h3>
                  <p>This shows what money you have left over after receiving your income and paying your expenses</p>
                </div>
              </div>
            </div>
            <div
              className="col-xl-8 mt-5 total-income d-flex edit-your-budget-total-income-expenses-wrapper"
              style={{ alignItems: 'center' }}
            >
              <div className="inline col-md-4 ">
                <h4 className="table-content bold">Total expenses & transfers</h4>
              </div>

              <div className="inline col-md-8 col-xl-8 expenses-transfers-section">
                <div className="content-blck last-wrp-account-transfer">
                  <select className="planner">
                    <option>Weekly</option>
                    <option>Weekly 2</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen === true && (
        <div>
          {editAccount === true ? (
            <EditAccountTransfer
              modalOpen={modalOpen}
              fetchAccountTransfer={fetchAccountTransfer}
              setModalOpen={setModalOpen}
              transferUuid={transferUuid}
            />
          ) : (
            <AccountTransferModal
              modalOpen={modalOpen}
              fetchAccountTransfer={fetchAccountTransfer}
              setModalOpen={setModalOpen}
            />
          )}
        </div>
      )}
    </>
  );
};
export default Accountransfer;

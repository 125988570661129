import $ from 'jquery';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Add } from '../../../assests/images/icons/Add_round.svg';
import Profile from '../../../assests/images/icons/image.png';
import Kiwisaver from '../../../assests/images/icons/Kiwisaver.png';
import Mortgage from '../../../assests/images/icons/Mortgage.png';
import Sharesies from '../../../assests/images/icons/Sharesies.png';
import { ReactComponent as Upload } from '../../../assests/images/icons/upload.svg';
import MainSidebar from '../../MainSidebar';

const AccountBalances = () => {
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };

  const Data = [
    {
      id: 1,
      title: 'Everyday account',
      number: '15-8478-383737-05',
      account: '$350.50',
      src: Mortgage,
      accClass: 'account-number-dark bold',
    },
    {
      id: 2,
      title: 'Groceries',
      number: '15-8478-383737-05',
      account: '$156.75',
      src: Mortgage,
      accClass: 'account-number-dark bold',
    },
    {
      id: 3,
      title: 'Power & Wifi',
      number: '15-8478-383737-05',
      account: '$96.89',
      src: Mortgage,
      accClass: 'account-number-dark bold',
    },
    {
      id: 4,
      title: 'Mortgage',
      number: '15-8478-383737-05',
      account: '$560.98',
      src: Mortgage,
      accClass: 'account-number-dark bold',
    },
    {
      id: 5,
      title: 'Saving',
      number: '15-8478-383737-05',
      account: '$12,000.45',
      src: Mortgage,
      accClass: 'account-number-dark bold',
    },
    {
      id: 6,
      title: 'Credit Card',
      number: '15-8478-383737-05',
      account: '-$356.86',
      src: Mortgage,
      accClass: 'account-number-dark-red bold',
    },
    {
      id: 7,
      title: 'Sharesies',
      number: '15-8478-383737-05',
      account: '$890.45',
      src: Sharesies,
      accClass: 'account-number-dark bold',
    },
    {
      id: 8,
      title: 'Kiwisaver',
      number: '15-8478-383737-05',
      account: '$15,657.34',
      src: Kiwisaver,
      accClass: 'account-number-dark bold',
    },
  ];

  const Datas = [
    {
      id: 1,
      title: 'Saving',
      number: '15-8478-383737-05',
      account: '$12,000.45',
      src: Mortgage,
      accClass: 'account-number-dark bold',
    },
    {
      id: 2,
      title: 'Credit Card',
      number: '15-8478-383737-05',
      account: '-$356.86',
      src: Mortgage,
      accClass: 'account-number-dark-red bold',
    },
    {
      id: 3,
      title: 'Sharesies',
      number: '15-8478-383737-05',
      account: '$890.45',
      src: Sharesies,
      accClass: 'account-number-dark bold',
    },
    {
      id: 4,
      title: 'Kiwisaver',
      number: '15-8478-383737-05',
      account: '$15,657.34',
      src: Kiwisaver,
      accClass: 'account-number-dark bold',
    },
  ];
  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            <div className="app-main__outer your-account-balances-container-main">
              <div className="row top-nav">
                <div className="col-md-6 col-lg-4 top-heading-account">
                  <div className="fs-3 bold big-head-new">Your account balances</div>
                  <div className="fst-normal setting_text_color">See what your account balances are today.</div>
                </div>
                <div className="col-md-6 col-xl-4 col-lg-4 col-sm-12 add-new-account-wrappee">
                  <div className="edit-btn-col">
                    <Link to="#">
                      <div className="edit-btn" href-="#">
                        <div className="left">
                          <h4 className="bold">Add a new account</h4>
                          <p style={{ color: '#04baff' }}>Bring everything into one place.</p>
                        </div>

                        <div className="right">
                          <Link to="#">
                            <Add />
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 right-section back-portal-account-balances">
                  <div className="setting_background_color right-section search-spacing">
                    <div className="profile-dashboard">
                      {/* <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link> */}
                      <Link className="comn-btn" to="/Clients/Activepage">
                        <i class="fa-solid fa-arrow-left"></i> Back to portal
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-xl-8 col-lg-4 col-md-6 acccount-balances-wrappper-left-side">
                  <div className="bold black account-bal-title">Account balances</div>
                  {Data.map((index) => (
                    <div className="merchant-box mt-3">
                      <div className="d-flex">
                        <div className="your-account-balance-wrp-logo-img">
                          <img src={index.src} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant fw-bold-600">{index.title}</div>
                          <div className="number-account inter">{index.number}</div>
                        </div>
                      </div>
                      <div className={index.accClass}>{index.account}</div>
                    </div>
                  ))}
                </div>
                <div className="col-lg-4 search-spacing your-account-bal-add-cash">
                  <div className="bold black account-bal-title">Add a cash account</div>
                  <div className="cash-account-box mt-3">
                    <div className="d-flex justify-content-between">
                      <div className="fw-bold">Name</div>
                      <div className="account-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Account balance</div>
                      <div className="account-input small-account">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Image</div>
                      <div className="account-input">
                        <div className="upload">
                          <input type="text" />
                          <Upload className="upload-logo" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="add-account-button bold">
                    <div>Add Account</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AccountBalances;

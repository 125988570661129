import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { ReactComponent as Chevron } from '../../assests/images/icons/chevron-left.svg';
import Profile from '../../assests/images/icons/image.png';
import weburls from '../Weburls/weburls';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';



const Settingadvisor = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [options, setOptions] = useState([]);
  const [payload,handlePayload] = useState('');
  const role_id = JSON.parse(localStorage.getItem('userData'));
  const [userRole, setUserRole] = useState(role_id.roleId);
  const selecthandleChange = (selectedValue) => {
    const selectedOptionObject = options.find((item) => item.roleName === selectedValue);

    if (selectedOptionObject) {
      const { roleId, roleName } = selectedOptionObject;

      // Now you have the roleId and roleName, you can use them in your payload
      const updatedFormdata = {
        ...formData,
        role: roleId,
        roleName: roleName,
      };

      // Example: pass the payload to a function for further processing
      setFormData(updatedFormdata);
  };
  setSelectedOption(selectedValue);
}
  const company_uuid = JSON.parse(localStorage.getItem('userData'));
  const user_Uuid = JSON.parse(localStorage.getItem('userData'));
  const [advisorlist, advisorList] = useState([]);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    companyId: company_uuid.companyId,
    user_uuid: user_Uuid.userUuid,
  });

  const schema = yup.object().shape({
    firstName: yup.string().required('*Please enter firstname').min(3, '*First name must be consist min 3 character'),
    lastName: yup.string().required('*Please enter lastname').min(3, '*Last name must be consist min 3 character'),
    phoneNumber: yup
      .string()
      .required('*Please enter phone number')
      .matches(/^[0-9]+$/, '*Please enter only number')
      .matches(/^\d{10}$/, '*Enter a 10-digit number'),
    email: yup
      .string()
      .required('*Please enter email')
      .email('*Please enter a valid email')
      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, '*Please enter proper email'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const getAdvisordata = async () => {
    const response = await axios.post(weburls.Add_advisor, formData);
    if (response.data.code === 1) {
      setFormData(response);
      setUserRole(response.roleId);
      reset()
      toast.success(response?.data?.message, {
        position: 'top-right',
        autoClose: 2000,
      });
    } else if(response.data.errors[0].code === 0){
      console.log("error in api detsails")
      toast.error("email is already used")
    }
    settingApi();
  };

  const roleList = async () => {
    try {
      const data = await axios.get(weburls.Advisor_role_list);
      console.log(data,"data rolelist")
      setOptions(data?.data);
      console.log(data.data, 'rolelist');
    } catch (error) {
      console.log(error);
    }
  };
  const [nid, setNid] = useState('');
    const id = JSON.parse(localStorage.getItem('userData'));
    console.log(id.advisor_id,"id hai.....")

    const settingApi = async () => {
    const response = await axios.get(weburls.Advisor_list + `${id.advisorId}`);
    console.log(response,"advisor-list")
    if (response?.data?.advisors) {
      advisorList(response?.data?.advisors)
      setNid(response?.data?.advisors?.advisorId);
    } else {
      // toast.error(response?.data?.errors?.detail || 'Error in Ids', {
      //   position: 'top-right',
      //   autoClose: 2000,
      // });
    }

    console.log(nid, 'id');
  };

  useEffect(() => {
    roleList();
    settingApi();
  }, []);


  return (
    <>
      <div className="row">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />

        <div className="col-md-7 ">
          <div className="setting_background_color mt-4">
            <div className="fs-3">
              <span className="bold black big-top-heading">Your advisors</span>
              <div className="setting_text_color">
                Edit & add your advisors to your plan so they can see every you see.
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <h5 className="bold black heading-new">Your contact details </h5>
                <div className="setting_text_color">This is who to contact about their plan</div>
              </div>
              <div className="col-md-12 col-sm-12 mt-4 me-3">
                <form onSubmit={handleSubmit(getAdvisordata)}>
                  <div className="row">
                    <div className="col-md-12 col-xl-6">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control input_border"
                          placeholder="First name"
                          {...register('firstName', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          name="firstName"
                          value={formData.firstName}
                        />
                      </div>
                      <p className="error-message">{errors.firstName?.message}</p>
                    </div>
                    <div className="col-md-12 col-xl-6">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control input_border"
                          placeholder="Last name"
                          {...register('lastName', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          name="lastName"
                          value={formData.lastName}
                        />
                      </div>
                      <p className="error-message">{errors.lastName?.message}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-xl-7">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control input_border "
                          placeholder="Email"
                          {...register('email', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          name="email"
                          value={formData.email}
                        />
                      </div>
                      <p className="error-message">{errors.email?.message}</p>
                    </div>
                    <div className="col-md-12 col-xl-5">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control input_border "
                          placeholder="Phone number"
                          {...register('phoneNumber', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          name="phoneNumber"
                          value={formData.phoneNumber}
                        />
                      </div>
                      <p className="error-message">{errors.phoneNumber?.message}</p>
                    </div>
                    <div className="col-md-12">
                      <select
                        class="form-select input_border"
                        onChange={(e) => selecthandleChange(e.target.value)}
                        value={selectedOption}
                      >
                       <option>Select any option</option>
                        {Array.isArray(options) && options.length > 0 ?
                            options?.map((item) => {
                              return(
                              <option key={item.roleId} value={item.roleName}>
                                {item.roleName}
                              </option>
                              )
                              }): (
                            'new'
                          )}
                      </select>
                      {selectedOption && <p>You selected: {selectedOption}</p>}
                    </div>
                    <div className="button mb-4 mt-4">
                      <button type="button" className="comn-btn button_white">
                        Cancel
                      </button>
                      <button type="submit" className="comn-btn common-btn-style">
                        Save & add
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 mt-4 last-advisor">
          {Array.isArray(advisorlist) && advisorlist.length > 0
            ? advisorlist &&
              advisorlist?.map((item, index) => (
                <div className="setting-your-advisor mt-3" key={index}>
                  <div className="setting-nw d-flex">
                    <div className="setting-img">
                      <img src={Profile} alt="profile" />
                    </div>
                    <div className="setting-your-advisor-text bold black">
                      {item?.firstName} {item?.lastName}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="setting-your-advisor-view ">{item?.roleName}</div>
                    <div className="chevron">
                      <Chevron className="chevron-logo" />
                    </div>
                  </div>
                </div>
              ))
            : ('No advisor found')}
        </div>
      </div>
    </>
  );
};
export default Settingadvisor;

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import { default as webUrls } from '../Weburls/weburls';
import SuperAdminHeader from './SuperAdminHeader';
import SuperAdminSidebar from './SuperAdminSidebar';

const schema = yup.object().shape({
  planName: yup
    .string()
    .required('*Plan name is required')
    .min(3, '*Plan name should have at least 3 characters'),
    // .matches(/^[A-Za-z]+$/, '*Plan name should contain only alphabetic characters'),
  planMode: yup.string().required('*Plan mode is required'),
  planAmount: yup
    .string()
    .required('*Plan amount is required')
    .matches(/^[0-9]+$/, '*Please enter only number.'),
  planDescription: yup
    .string()
    .required('*Please enter plan description.')
    .min(3, '*Plan description must be consist min 3 character.')
    .max(255, '*Plan description character should have only 255 characters.'),

});

const NewPlans = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const [plansData, setPlansData] = useState({
    planName: '',
    planMode: '',
    planDescription: '',
    planAmount: '',
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setPlansData({ ...plansData, [name]: files[0], fileName: files[0].name });
    } else {
      setPlansData({ ...plansData, [name]: value });
    }
  };

  const onSubmitData = async (plansData) => {
    try {
      const response = await axios.post(webUrls.Super_admin_add_plan, plansData);
      console.log('Response', response);
      if (response.status === 200) {
        toast.success('Plan added successfully..', {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate('/Plans');
        }, 3000);
      } else if (response.status === 500) {
        toast.error('Plan details not saved. Please try again.');
      }
    } catch (error) {
      console.error('error', error);
    }
    reset();
  };

  return (
    <>
      <div className="container-fluid">
        <SuperAdminHeader />

        <div className="appmain">
          <SuperAdminSidebar />
          <div className="app-main__outer main-page-details-settings">
            <ToastContainer
              position="top-right"
              autoClose={5000} // Auto close after 5 seconds
              closeOnClick
            />
            <div className="col-md-12 mb-3">
              <h3 className="small-heading">
                <Link to="/Plans">
                  <i class="fa-solid fa-arrow-left"></i> Back to plans
                </Link>
              </h3>
            </div>
            <div className="setting_background_color">
              <form onSubmit={handleSubmit(onSubmitData)}>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h2 className="bold black big-top-heading">Add a new plan</h2>
                    <p className="setting_text_color">Add your plan details.</p>
                  </div>
                </div>
                <hr />

                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Plan details</h5>
                    <div className="setting_text_color">Enter plan details.</div>
                  </div>
                  <div className="col-md-12 col-xl-7 col-sm-12 mb-5 plan-details">
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-6 mb-3">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Enter a plan name"
                            id="planName"
                            name="planName"
                            onChange={handleChange}
                            {...register('planName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        <p className="error-message">{errors.planName?.message}</p>
                      </div>
                      <div className="col-xl-6 col-md-12 mb-3">
                        <select
                          class="form-select input_border"
                          name="planMode"
                          id="planMode"
                          onChange={handleChange}
                          {...register('planMode', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                        >
                          <option value="">Select a plan mode</option>
                          <option value="monthly">Monthly</option>
                          <option value="yearly">Yearly</option>
                          <option value="weekly">Weekly</option>
                        </select>
                        <p className="error-message mt-3">{errors.planMode?.message}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-xl-6 mb-3">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Plan amount"
                            id="planAmount"
                            name="planAmount"
                            onChange={handleChange}
                            {...register('planAmount', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        <p className="error-message">{errors.planAmount?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-6 mb-3">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Currency name"
                            name="currencyName"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-7 col-lg-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            id="planDescription"
                            class="form-control input_border "
                            placeholder="Plan description"
                            name="planDescription"
                            onChange={handleChange}
                            {...register('planDescription', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        <p className="error-message">{errors.planDescription?.message}</p>
                      </div>

                      <div className="button mb-4">
                        <Link to="/Plans">
                          <button type="button" className="comn-btn button_white">
                            Cancel
                          </button>
                        </Link>
                        <button type="submit" className="comn-btn common-btn-style">
                          Save details
                          <i class="fa-solid fa-plus plus-icon-style"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewPlans;

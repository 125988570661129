import React from 'react';
import { Link } from 'react-router-dom';
// import { useRef } from "react";
import { ReactComponent as Arrow } from '../../assests/images/icons/Arrow_right_card.svg';
import Generate from '../../assests/images/icons/generate.png';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Edit } from '../../assests/images/icons/more.svg';
import Mortage from '../../assests/images/icons/Mortgage.png';
import Sharesis from '../../assests/images/icons/Sharesies.png';
import MainSidebar from '../MainSidebar';
import { Cart } from './Cart';
import Merchant from './Merchant';
import Networthprogress from './Netwrothprogress';
import Recenttransaction from './Recenttransaction';

const Dashboardinner = () => {
  // const sidebarRef = useRef(null);

  // const toggleSidebar = () => {
  //   $(sidebarRef.current).toggleClass("sidebarnew");
  // };

  const CardData = [
    {
      id: 1,
      title: 'Groceries',
      amount: '$699',
      amountLeft: '$575 left',
      progressBarClassFirst: 'progress red-progress',
      progressBarClassSecond: 'progress-bar w-50 new-red',
      ariaValueNow: '50',
    },
    {
      id: 2,
      title: 'Clothes',
      amount: '$199',
      amountLeft: '$273 left',
      progressBarClassFirst: 'progress light-pink-progress',
      progressBarClassSecond: 'progress-bar w-50 light-pink',
      ariaValueNow: '50',
    },
    {
      id: 3,
      title: 'House',
      amount: '$800',
      amountLeft: '$453 left',
      progressBarClassFirst: 'progress dark-blue',
      progressBarClassSecond: 'progress-bar w-25 dark-blue-progress',
      ariaValueNow: '25',
    },
    {
      id: 4,
      title: 'House',
      amount: '$800',
      amountLeft: '$453 left',
      progressBarClassFirst: 'progress dark-blue',
      progressBarClassSecond: 'progress-bar w-50 dark-blue-progress',
      ariaValueNow: '50',
    },
    {
      id: 5,
      title: 'Car',
      amount: '$200',
      amountLeft: '$575 left',
      progressBarClassFirst: 'progress light-yellow',
      progressBarClassSecond: 'progress-bar w-50 light-yellow-progress',
      ariaValueNow: '50',
    },
    {
      id: 6,
      title: 'Car',
      amount: '$200',
      amountLeft: '$575 left',
      progressBarClassFirst: 'progress light-green',
      progressBarClassSecond: 'progress-bar w-50 light-green-progress',
      ariaValueNow: '50',
    },
    {
      id: 7,
      title: 'Car',
      amount: '$200',
      amountLeft: '$575 left',
      progressBarClassFirst: 'progress light-pink',
      progressBarClassSecond: 'progress-bar w-50 light-pink-progress',
      ariaValueNow: '50',
    },
    {
      id: 8,
      title: 'Car',
      amount: '$200',
      amountLeft: '$575 left',
      progressBarClassFirst: 'progress red-second',
      progressBarClassSecond: 'progress-bar w-75 red-second-progress',
      ariaValueNow: '75',
    },
  ];

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            <div className="app-main__outer client-dashboard-main-wrapper">
              <div className="row align-items-center">
                <div className="col-md-6 top-head">
                  <div className="big-head-new">
                    Good Morning ,
                    <span className="text-jeremy">
                      Tim 👋
                    </span>
                  </div>
                  <div className="fst-normal light-grey">Here's an update on your financial position.</div>
                </div>
                <div className="col-md-6 right-section dashboard-inner-back-to-portal">
                  <div className="setting_background_color right-section main-dash-back">
                    <div className="profile-dashboard">
                      <Link className="comn-btn" to="/Clients/Activepage">
                        <i class="fa-solid fa-arrow-left"></i> Back to portal
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5 income-expenses">
                <div className="col-md-6 col-lg-6 left-section total-balances-card-wrp">
                  <div className="total-balance-wrp">
                    <h4 className="med-head">Total Balance</h4>
                    <h3 className="big-head">$25,000.40</h3>
                    <div className="row mt-5">
                      <div className="col-md-7 img-sec">
                        <div className="img">
                          <img src={Mortage} alt="Mortage_img" />
                        </div>
                        <div className="img">
                          <img src={Generate} alt="Mortage_img" />
                        </div>
                        <div className="img">
                          <img src={Sharesis} alt="Mortage_img" />
                        </div>
                      </div>
                      <div className="col-md-5 right">
                        <h4>
                          My Balances <Arrow />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3  col-md-3 col-sm-12 dashboard-inner-income-expenses-wrp">
                  <div class="total-clients">
                    <div className="top-wrp">
                      <div class="fw-bold profile-dashboard ">Income</div>
                      <div className="profile-dashboard right-section">
                        <select>
                          <option>This week</option>
                          <option>This week</option>
                          <option>This week</option>
                          <option>This week</option>
                        </select>
                      </div>
                    </div>
                    <div class="margin-nu d-flex">
                      <h3 class="number-heading">$5,000</h3>
                      <div class="percentage bold">+75.5%</div>
                    </div>
                    <div class="text-client">This weeks expenses</div>
                  </div>
                </div>
                <div class="col-lg-3  col-md-3 col-sm-12 dashboard-inner-income-expenses-wrp">
                  <div class="total-clients">
                    <div className="top-wrp">
                      <div class="fw-bold profile-dashboard ">Expenses</div>
                      <div className="profile-dashboard right-section">
                        <select>
                          <option>This week</option>
                          <option>This week</option>
                          <option>This week</option>
                          <option>This week</option>
                        </select>
                      </div>
                    </div>
                    <div class="margin-nu d-flex">
                      <h3 class="number-heading">$1,000</h3>
                      <div class="percentage bold red">+15.5%</div>
                    </div>
                    <div class="text-client">This weeks expenses</div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 ">
                <div className="col-md-12 col-lg-6 bar-cart income-exp-mnth">
                  <div className="recent-see-all">
                    <div className="col-md-6 left">
                      <h5>Income vs Expenses - Monthly</h5>
                    </div>
                    <div className="col-md-6 right-section">
                      <select>
                        <option>
                          <a href="#">Last 12 Months</a>
                        </option>
                        <option>Last 12 Months</option>
                        <option>Last 12 Months</option>
                        <option>Last 12 Months</option>
                      </select>
                    </div>
                  </div>
                  <div className="income-exp-bar-chart">
                    {/* <div className="income-exp-mnth-chart-edit">
                      <div className="d-flex mt-5">
                        <h4 className="bar-chart-heading-h4 fw-bold">
                          Income: <span className="green-text">$2567</span>
                        </h4>
                        <h4 className="bar-chart-heading-h4 fw-bold">
                          Expenses: <span className="red-dark-text">$1890</span>
                        </h4>
                      </div>
                    </div> */}

                    <div className="col-md-12 col-sm-12">
                      <div className="main-barcart-wrapper">
                        <div className="title-legend-wrp col-xl-11 col-md-11">
                          <h3>April 2022</h3>
                          <div className="income-exp-legend">
                            <span className="pointer-green"></span>
                            <h5>Income</h5>
                            <span className="pointer-red"></span>
                            <h5>Expenses</h5>
                            <Link to="#">
                              <Edit />
                            </Link>
                          </div>
                        </div>

                        <div className="bar-cart-data-detail">
                          <h3 className="income-bar">
                            Income: <span>$2567</span>
                          </h3>
                          <h3 className="expense-bar">
                            Expenses: <span>$1890</span>
                          </h3>
                        </div>
                        <Cart />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 bar-cart top-expenses top-5-expenses-wrapper">
                  <div className="recent-see-all">
                    <div className="col-md-6 left">
                      <h5>Top 5 Expenses</h5>
                    </div>
                    <div className="col-md-6 right-section">
                      <select>
                        <option>
                          <a href="#">This week</a>
                        </option>
                        <option>
                          <a href="#">This week</a>
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="main-blck row mt-4 ">
                    <div className="sml-blck col-sm-5 col-md-5">
                      <div className="number">1</div>
                      <h4>Groceries</h4>
                      <h3 className="number-heading">$ 3,000</h3>
                    </div>
                    <div className="sml-blck green col-md-5 col-sm-5">
                      <div className="number">2</div>
                      <h4>Car</h4>
                      <h3 className="number-heading">$ 2,000</h3>
                    </div>
                    <div className="sml-blck blue-expenses col-sm-5 col-md-5">
                      <div className="number">3</div>
                      <h4>Power</h4>
                      <h3 className="number-heading">$ 1,000</h3>
                    </div>
                    <div className="sml-blck yellow-expenses col-sm-5 col-md-5">
                      <div className="number">4</div>
                      <h4>Power</h4>
                      <h3 className="number-heading">$ 1,000</h3>
                    </div>
                    <div className="sml-blck pink col-sm-5 col-md-5">
                      <div className="number">5</div>
                      <h4>Clothes</h4>
                      <h3 className="number-heading">$ 1,000</h3>
                    </div>
                  </div>
                </div>
                <Merchant />
              </div>
              <div className="row mt-4">
                <div className="col-md-12 bar-cart spend-budget">
                  <div className="col-md-6 inline mb-4 left">
                    <h5>Spend vs budget</h5>
                  </div>
                  <div className="col-md-6 right-section right">
                    <select>
                      <option>Last 12 Months</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row monthly-wrp">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xl-12 monthly-section">
                  {CardData &&
                    CardData.map((item) => {
                      return (
                        <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                          <div className="inner-blck">
                            <div className="merchant">
                              <h6>{item.title}</h6>
                              <h5>{item.amount}</h5>
                            </div>
                            <div className="arrow">
                              <i class="fas fa-angle-right"></i>
                            </div>
                          </div>
                          <div className="left-block name-merchant">
                            <h6>{item.amountLeft}</h6>
                          </div>
                          <div className={item.progressBarClassFirst} style={{ height: '10px' }}>
                            <div
                              className={item.progressBarClassSecond}
                              role="progressbar"
                              aria-valuenow={item.ariaValueNow}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      );
                    })}

                  {/* <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                    <div className="inner-blck">
                      <div className="merchant">
                        <h6>Clothes</h6>
                        <h5>$199</h5>
                      </div>
                      <div className="arrow">
                        <i class="fas fa-angle-right"></i>
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6>$273 left</h6>
                    </div>
                    <div
                      class="progress light-pink-progress"
                      style={{ height: "10px" }}
                    >
                      <div
                        class="progress-bar w-50 light-pink"
                        role="progressbar"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                    <div className="inner-blck">
                      <div className="merchant">
                        <h6>House</h6>
                        <h5>$800</h5>
                      </div>
                      <div className="arrow">
                        <i class="fas fa-angle-right"></i>
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6>$453 left</h6>
                    </div>
                    <div class="progress dark-blue" style={{ height: "10px" }}>
                      <div
                        class="progress-bar w-25 dark-blue-progress"
                        role="progressbar"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                    <div className="inner-blck">
                      <div className="merchant">
                        <h6>House</h6>
                        <h5>$800</h5>
                      </div>
                      <div className="arrow">
                        <i class="fas fa-angle-right"></i>
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6>$453 left</h6>
                    </div>
                    <div class="progress dark-blue" style={{ height: "10px" }}>
                      <div
                        class="progress-bar w-50 dark-blue-progress"
                        role="progressbar"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                    <div className="inner-blck">
                      <div className="merchant">
                        <h6>Car</h6>
                        <h5>$200</h5>
                      </div>
                      <div className="arrow">
                        <i class="fas fa-angle-right"></i>
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6>$575 left</h6>
                    </div>
                    <div
                      class="progress light-yellow"
                      style={{ height: "10px" }}
                    >
                      <div
                        class="progress-bar w-50 light-yellow-progress"
                        role="progressbar"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                    <div className="inner-blck">
                      <div className="merchant">
                        <h6>Car</h6>
                        <h5>$200</h5>
                      </div>
                      <div className="arrow">
                        <i class="fas fa-angle-right"></i>
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6>$575 left</h6>
                    </div>
                    <div
                      class="progress light-green"
                      style={{ height: "10px" }}
                    >
                      <div
                        class="progress-bar w-50 light-green-progress"
                        role="progressbar"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                    <div className="inner-blck">
                      <div className="merchant">
                        <h6>Car</h6>
                        <h5>$200</h5>
                      </div>
                      <div className="arrow">
                        <i class="fas fa-angle-right"></i>
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6>$575 left</h6>
                    </div>
                    <div class="progress light-pink" style={{ height: "10px" }}>
                      <div
                        class="progress-bar w-50 light-pink-progress"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                    <div className="inner-blck">
                      <div className="merchant">
                        <h6>Car</h6>
                        <h5>$200</h5>
                      </div>
                      <div className="arrow">
                        <i class="fas fa-angle-right"></i>
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6>$575 left</h6>
                    </div>
                    <div class="progress red-second" style={{ height: "10px" }}>
                      <div
                        class="progress-bar w-75 red-second-progress"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div> */}
                </div>
                {/* <div className="col-md-12 col-lg-12 col-sm-12 monthly-section">
                  <div className="income-merchant col-lg-5 col-xl-5 col-sm-12">
                    <div className="inner-blck">
                      <div className="merchant">
                        <h6>Groceries</h6>
                        <h5>$699</h5>
                      </div>
                      <div className="arrow">
                        <i class="fas fa-angle-right"></i>
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6>$575 left</h6>
                    </div>
                    <div class="progress" style={{ height: "10px" }}>
                      <div
                        class="progress-bar w-75"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="income-merchant col-lg-5 col-xl-5 col-sm-12">
                    <div className="inner-blck">
                      <div className="merchant">
                        <h6>Cloths</h6>
                        <h5>$199</h5>
                      </div>
                      <div className="arrow">
                        <i class="fas fa-angle-right"></i>
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6>$575 left</h6>
                    </div>
                    <div class="progress" style={{ height: "10px" }}>
                      <div
                        class="progress-bar w-75"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="income-merchant col-lg-5 col-xl-5 col-sm-12">
                    <div className="inner-blck">
                      <div className="merchant">
                        <h6>House</h6>
                        <h5>$800</h5>
                      </div>
                      <div className="arrow">
                        <i class="fas fa-angle-right"></i>
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6>$575 left</h6>
                    </div>
                    <div class="progress" style={{ height: "10px" }}>
                      <div
                        class="progress-bar w-75"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="income-merchant col-lg-5 col-xl-5 col-sm-12">
                    <div className="inner-blck">
                      <div className="merchant">
                        <h6>House</h6>
                        <h5>$800</h5>
                      </div>
                      <div className="arrow">
                        <i class="fas fa-angle-right"></i>
                      </div>
                    </div>
                    <div className="left-block name-merchant">
                      <h6>$575 left</h6>
                    </div>
                    <div class="progress" style={{ height: "10px" }}>
                      <div
                        class="progress-bar w-75"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div> */}
              </div>
              <Recenttransaction />
              <Networthprogress />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboardinner;

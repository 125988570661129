import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import Tableimg from '../../assests/images/icons/profile.png';
import { ReactComponent as Search } from '../../assests/images/icons/Search.svg';
import Header from '../Header/Header';
import LoadingTxt from '../Loading/LoadingTxt';
import Sidebar from '../Sidebar/Sidebar';
import weburls from '../Weburls/weburls';
import Newclient from './Newclient';

const Clients = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const toggleBanner = () => {
    setShowBanner(showBanner);
  };
  const companyId = JSON.parse(localStorage.getItem('userData'));
  console.log('compnayId', companyId);
  const activeChange = () => {
    setActive(true);
    navigate('/Clients/Clientdetails');
  };

  const [collection, setCollection] = useState([]);

  const [value, setValue] = useState('');
  const [tableFilter, setTableFilter] = useState([]);
  const [datasource, setDataSource] = useState({});
  // const [ndatasource, nsetDataSource] = useState([]);

  // const handleSearch = (e) => {
  //   setSearchTerm(e.target.value);
  //   setDataSource([...datasource])
  // };

  // const filteredData = tableData.filter(
  //   (item) =>
  //     item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     item.group.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     item.plan.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     item.frequency.toLowerCase().includes(searchTerm.toLowerCase()),
  // );
  const filteredData = (e) => {
    // eslint-disable-next-line eqeqeq
    const searchTerm = e.target.value.toLowerCase();
    setValue(searchTerm);

    if (searchTerm.length > 0) {
      const filterTable = datasource.filter((o) =>
        Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(searchTerm)),
      );
      setTableFilter([...filterTable]);
    } else {
      setTableFilter([...datasource]);
    }
  };
  const fetchedData = async () => {
    try {
      const response = await axios.get(weburls.Get_clients + `${companyId.companyId}`);
      console.log('getClients', response.data.clients);
      localStorage.setItem('client-userUuid', JSON.stringify(response.data.clients));
      if (response?.data?.clients?.length > 0) {
        setTableFilter(response.data.clients);
        setDataSource(response.data.clients);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        // toast.error('Something went wrong');
      }
    } catch (error) {
      console.error('Error fetching Data: ', error);
      setLoading(false);
    }
  };
  const group_filter = (itemData) => {
    console.log(itemData, 'itemdata-------');
    // const filterData = tableData.filter(checkAdult);

    function checkAdult(item) {
      return item.group == itemData;
    }
    // const filterData =  tableData.filter((item) => item.group == itemData)

    // console.log(filterData , "harshit-------")
    // xxx(filterData);
  };
  // console.log(tableData,"tabledata")
  useEffect(() => {
    fetchedData();
    // setCollection([...new Set(tableData.map((item) => item.group))])
  }, []);

  console.log('table-filter', tableFilter);
  console.log('datasource', datasource);

  return (
    <>
      <div className="container-fluid">
        <Header toggleBanner={toggleBanner} />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer your-clients-list-section-wrapper">
            <div className="row group-wrp">
              <div className="col-lg-8 top-heading-wrp-main">
                <div className="fs-3 bold black your-clients-title">Your Clients</div>
                <div className="setting_text_color">Here's a list of each of your client using BudgetBuddie.</div>
              </div>

              <div className="col-lg-4 search-spacing">
                <div className="search-block">
                  <form>
                    <Search />
                    <input
                      type="text"
                      className="search"
                      value={value}
                      placeholder="Search for a client here"
                      onChange={filteredData}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="bold mt-4 mb-3 heading-new">Filter Clients</div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3 mb-3">Group</div>
                  <div className="group-btn">
                    {console.log(collection, 'collectionlist')}
                    {/* {
                      // eslint-disable-next-line array-callback-return
                       collection && collection.map((item,index) => {
                        return(
                          <>
                          <div className="button-blck" key={index}>
                            <div className={item.groupBtnClass} onClick={() => {group_filter(item.group)}}>
                             {item.group}
                            </div>
                      </div>
                          </>
                        )
                        })
} */}
                    <div className="button-blck">
                      <a href="#" className="group-button purple">
                        Individual
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Individual
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Individual
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Individual
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 mb-3 plan-section-area">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3 mb-3">Plan</div>
                  <div className="group-btn yellow-btn">
                    <div className="button-blck">
                      <a href="#" className="group-button dark-yellow">
                        All
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Standard
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Pro
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 mb-3">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3 mb-3">Status</div>
                  <div className="group-btn green-btn">
                    <div className="button-blck">
                      <a href="#" className="group-button green">
                        All
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Active
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Inactive
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Not-Activated
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-wrp mt-2 your-clients-list-table-wrapper">
              <div class="row">
                <div class="col-12">
                  <table className="table-image">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Group</th>
                        <th scope="col">Plan</th>
                        <th scope="col">Frequency</th>
                        <th scope="col">Status</th>
                        <th scope="col">Email</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody className="space-table">
                      {loading ? (
                        <div className="no-data-found-td">
                          <LoadingTxt />
                        </div>
                      ) : tableFilter && tableFilter?.length > 0 ? (
                        tableFilter?.map((item) => (
                          <tr className="cursor-pointer" key={item.id}>
                            <th>
                              <div className="air-image">
                                <div className="air-image" onClick={activeChange}>
                                  <img src={Tableimg} alt="table-img" />
                                  <h3 className="table-content display">{item.firstName}</h3>
                                </div>
                                <Link className="amount-active dark-green" to="/Dashbaordpanel">
                                  Open <i class="fa-solid fa-arrow-right"></i>
                                </Link>
                              </div>
                            </th>
                            <td className="table-text-color">Individual</td>
                            <td className="table-text-color">Standard</td>
                            <td className="table-text-color">Monthly</td>
                            <td>
                              {item.status != 0 ? (
                                <div className="active-status">
                                  <button className="active-btn table-text-color">
                                    <i class="fa-solid fa-circle"></i> Active
                                  </button>
                                </div>
                              ) : (
                                <div className="in-active-status-div">
                                  <button className="inactive-button table-text-color">
                                    <i class="fa-solid fa-circle"></i> Inactive
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="table-text-color">{item.email}</td>
                            <td>
                              <Link to={`/Clients/Edit/${item.clientId}`}>
                                <Edit />
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="no-data-found-td">No data found</div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Newclient show={showBanner} />
      </div>
      {/* )} */}
    </>
  );
};
export default Clients;

const BudgetBuddie = 'http://localhost:5010/';

// Advisor login api

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Advisor_Login: BudgetBuddie + 'api/auth/login',
  Advisor_Details: BudgetBuddie + 'api/advisor/getAdvisorByUser/',
  Advisor_Details_Update: BudgetBuddie + 'api/advisor/updateAdvisor',
  Advisor_role_list: BudgetBuddie + 'api/role/getRoles',
  Advisor_list: BudgetBuddie + 'api/advisor/getAdvisorByCompany/',
  Add_advisor: BudgetBuddie + 'api/advisor/addAdvisor',
  //    super admin add company
  Super_admin_company_add: BudgetBuddie + 'api/super_admin/company',
  Super_admin_company_list: BudgetBuddie + 'api/super_admin/company',
  Super_admin_add_plan: BudgetBuddie + 'api/plan/addPlan',
  Super_admin_plans_list: BudgetBuddie + 'api/plan/list',
  Super_admin_edit_plans: BudgetBuddie + 'api/plan/getPlanById/',
  Super_admin_update_plans: BudgetBuddie + 'api/plan/updatePlan',
  Super_admin_delete_plans: BudgetBuddie + 'api/plan/deletePlan/',
  Super_admin_status_edit: BudgetBuddie + 'api/plan/updatePlanStatus',


  //Groups api
  Add_group: BudgetBuddie + 'api/groups/addGroup',
  Group_list: BudgetBuddie + 'api/groups/list',
  Get_group_details : BudgetBuddie + 'api/groups/getGroupById/',
  Update_group: BudgetBuddie + 'api/groups/updateGroup',
  Delete_group: BudgetBuddie + 'api/groups/delete/',


  //Clients api

  Add_clients: BudgetBuddie + 'api/clients/addClient',
  Get_clients: BudgetBuddie + 'api/clients/getClientsByCompany/',
  Get_clients_cliendid: BudgetBuddie + 'api/clients/getClientsByClientId/',
  Update_clients: BudgetBuddie + 'api/clients/updateClient',
  // Edit budget Page

  Add_income: BudgetBuddie + 'api/budgetSummary/incomes',
  Update_Income: BudgetBuddie + 'api/budgetSummary/updateIncome',
  Get_Income: BudgetBuddie + 'api/budgetSummary/incomes/',
  Get_Income_ById : BudgetBuddie + 'api/budgetSummary/incomes/getIncomeById/',
  Delete_income: BudgetBuddie + 'api/budgetSummary/incomes/',


  Add_expense: BudgetBuddie + 'api/budgetSummary/addExpenses',
  Get_Expense: BudgetBuddie + 'api/budgetSummary/expenses/',
  Update_Expense: BudgetBuddie + 'api/budgetSummary/updateExpenses',
  Get_Expense_By_Id: BudgetBuddie + 'api/budgetSummary/expenses/getExpensById/',
  Delete_Expense : BudgetBuddie + 'api/budgetSummary/expenses/',


  Account_Transfer_Add: BudgetBuddie + 'api/budgetSummary/addAccountTransfer',
  Update_Account_Transfer : BudgetBuddie + 'api/budgetSummary/updateAccountTransfer',
  Account_Transfer_Get_List: BudgetBuddie + 'api/budgetSummary/getAccountTransfer/',
  Get_Account_Transfer_By_Id : BudgetBuddie + 'api/budgetSummary/getAccountTransfer/getAccountTransferById/',
  Delete_Account_Transfer : BudgetBuddie + 'api/budgetSummary/deleteAccountTransfer/',

  How_Often : BudgetBuddie + 'api/howOften/getHowOften',

// Assets

Add_Assets : BudgetBuddie + 'api/networth/assets',
Get_Assets : BudgetBuddie + 'api/networth/assets/',
Get_Asset_By_Id : BudgetBuddie + 'api/networth/assets/getAssetById/',
Update_Asset : BudgetBuddie + 'api/networth/updateAssets',
Delete_Asset : BudgetBuddie + 'api/networth/assets/',

// Liability


Add_Liability : BudgetBuddie + 'api/networth/liability',
Get_Liability : BudgetBuddie + 'api/networth/liability/',
Get_Liability_By_Id : BudgetBuddie + 'api/networth/liability/getLiabilityById/',
Update_Liability: BudgetBuddie + 'api/networth/updateLiability',
Delete_Liability : BudgetBuddie + 'api/networth/liability/'


};

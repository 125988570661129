import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Airnewzealand from '../../assests/images/icons/AirNewZealand.png';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import Tableimg from '../../assests/images/icons/image.png';
import McDonald from '../../assests/images/icons/Mcdonald2-.png';
import Oznew from '../../assests/images/icons/One NZ.png';
import Tower from '../../assests/images/icons/Tower.png';
import Activebutton from '../Buttons/Activebutton';
import Inactivebutton from '../Buttons/Inactivebutton';
import Notactivatedbutton from '../Buttons/Notactivatedbutton';
import Header from '../Header/Header';
import Activepage from '../pages/Activepage';
import Sidebar from '../Sidebar/Sidebar';
const Dashboard = () => {
  const [active, setActive] = useState(false);

  const activeChange = () => {
    setActive(true);
  };
  const dataTable = [
    {
      id: 1,
      title: 'Tim Preston',
      group: 'Individual',
      plan: 'Pro',
      frequency: 'Monthly',
      status: 'Active',
      email: 'tim@budgetbuddie.co.nz',
    },
    {
      id: 2,
      title: 'Tim Preston',
      group: 'Individual',
      plan: 'Pro',
      frequency: 'Monthly',
      status: 'Active',
      email: 'tim@budgetbuddie.co.nz',
    },
    {
      id: 3,
      title: 'Tim Preston',
      group: 'Individual',
      plan: 'Pro',
      frequency: 'Monthly',
      status: 'Active',
      email: 'tim@budgetbuddie.co.nz',
    },
    {
      id: 4,
      title: 'Tim Preston',
      group: 'Individual',
      plan: 'Pro',
      frequency: 'Monthly',
      status: 'Active',
      email: 'tim@budgetbuddie.co.nz',
    },
    {
      id: 5,
      title: 'Tim Preston',
      group: 'Individual',
      plan: 'Pro',
      frequency: 'Monthly',
      status: 'Active',
      email: 'tim@budgetbuddie.co.nz',
    },
    {
      id: 6,
      title: 'Tim Preston',
      group: 'Individual',
      plan: 'Pro',
      frequency: 'Monthly',
      status: 'Active',
      email: 'tim@budgetbuddie.co.nz',
    },
    {
      id: 7,
      title: 'Tim Preston',
      group: 'Individual',
      plan: 'Pro',
      frequency: 'Monthly',
      status: 'Active',
      email: 'tim@budgetbuddie.co.nz',
    },
    {
      id: 8,
      title: 'Tim Preston',
      group: 'Individual',
      plan: 'Pro',
      frequency: 'Monthly',
      status: 'Active',
      email: 'tim@budgetbuddie.co.nz',
    },
    {
      id: 9,
      title: 'Tim Preston',
      group: 'Individual',
      plan: 'Pro',
      frequency: 'Monthly',
      status: 'Active',
      email: 'tim@budgetbuddie.co.nz',
    },
  ];

  return (
    <>
      {active ? (
        <Activepage />
      ) : (
        <div className="container-fluid">
          <Header />
          <div className="appmain">
            <Sidebar />
            <div className="app-main__outer main-front-dashboard-container">
              <div className="fs-3 big-head-new">
                Good Morning,{' '}
                <span className="text-jeremy">
                  Jeremy
                  <div className="hand-img">👋</div>
                </span>
              </div>
              <div className="fst-normal light-grey">Here's your dashboard of clients using BudgetBuddie.</div>
              <div className="row mt-3 dashboard-wrp">
                <div className="col-md-12 col-xl-3">
                  <div className="total-clients">
                    <div className="bold black first-heading-total sub-head-text mb-4">Total clients</div>
                    <div className="margin-nu d-flex">
                      <h3 className="number-heading acc-num-head">5000</h3>
                      <div className="percentage bold">+15%</div>
                    </div>
                    <div className="text-client">Clients using BudgeBuddie</div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-4">
                  <div className="total-clients">
                    <div className="bold black sub-head-text">Amount of users</div>
                    <div className="margin-amount">
                      <h2 className="number-heading second-num-head">5000</h2>
                    </div>
                    <div className="d-flex justify-content-between mt-5">
                      <div className="user-wrp">
                        <div className="amount-number acc-num-head">4700</div>

                        <Activebutton />
                      </div>
                      <div className="user-wrp">
                        <div className="amount-number acc-num-head">200</div>

                        <Inactivebutton />
                      </div>
                      <div className="user-wrp">
                        <div className="amount-number acc-num-head">100</div>

                        <Notactivatedbutton />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-2">
                  <div className="total-clients">
                    <div className="bold black text-center sub-head-text">Share requests</div>
                    <div className="share-number fs-3">15</div>
                    <button className="comn-btn">View requests</button>
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 col-xl-3">
                  <div className="total-clients">
                    <div className="bold black sub-head-text">Your top Group</div>
                    <div className="air-new group-name-tab air-new-zealand-tab">
                      <div className="air-image">
                        <img src={Airnewzealand} alt="Air" />
                      </div>
                      Air New Zealand
                    </div>
                    <div className="air-new-active active">
                      <div className="amount-number acc-num-head">4700</div>
                      <Activebutton />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="fw-bold heading-main">Top 3 Groups</div>
                <div className="col-xl-4 col-sm-12 col-md-12">
                  <div className="total-top-group">
                    <div className="bold black ranked-heading">Ranked 2nd </div>
                    <div className="air-new group-name-tab">
                      <div className="air-image">
                        <img src={McDonald} alt="Air" />
                      </div>
                      McDonalds
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="user-wrp">
                        <div className="amount-number">4700</div>

                        <div className="amount-active">Active</div>
                      </div>
                      <div className="user-wrp">
                        <div className="amount-number">200</div>

                        <div className="amount-inactive">Inactive</div>
                      </div>
                      <div className="user-wrp">
                        <div className="amount-number">100</div>

                        <div className="amount-activated">Not activated</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-md-12">
                  <div className="total-top-group">
                    <div className="bold black ranked-heading">Ranked 3rd</div>
                    <div className="air-new group-name-tab">
                      <div className="air-image">
                        <img src={Tower} alt="Air" />
                      </div>
                      Tower Insurance
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="user-wrp">
                        <div className="amount-number">4700</div>

                        <div className="amount-active">Active</div>
                      </div>
                      <div className="user-wrp">
                        <div className="amount-number">200</div>

                        <div className="amount-inactive">Inactive</div>
                      </div>
                      <div className="user-wrp">
                        <div className="amount-number">100</div>

                        <div className="amount-activated">Not activated</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-md-12">
                  <div className="total-top-group">
                    <div className="bold black ranked-heading">Ranked 4th</div>
                    <div className="air-new group-name-tab">
                      <div className="air-image">
                        <img src={Oznew} alt="Air" />
                      </div>
                      One NZ
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="user-wrp">
                        <div className="amount-number">4700</div>

                        <div className="amount-active">Active</div>
                      </div>
                      <div className="user-wrp">
                        <div className="amount-number">200</div>

                        <div className="amount-inactive">Inactive</div>
                      </div>
                      <div className="user-wrp">
                        <div className="amount-number">100</div>

                        <div className="amount-activated">Not activated</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-wrp clients-with-share-rqst-wrapper" id="#down">
                <div className="fw-bold heading-main mt-5">Clients with share requests</div>
                <div class="">
                  <div class="col-12 table-horizontal">
                    <table class="table-image main-table">
                      <thead>
                        <tr className="green">
                          <th scope="col">Name</th>
                          <th scope="col">Group</th>
                          <th scope="col">Plan</th>
                          <th scope="col">Frequency</th>
                          <th scope="col">Status</th>
                          <th scope="col">Email</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody className="space-table">
                        {dataTable &&
                          dataTable.map((item) => {
                            return (
                              <tr onClick={activeChange} className="cursor-pointer">
                                <th>
                                  <div className="air-image">
                                    <img src={Tableimg} alt="table-img" />
                                    <h3 className="table-content display">{item.title}</h3>
                                    <Link className="amount-active" to="/Dashbaordpanel">
                                      Open <i class="fa-solid fa-arrow-right"></i>
                                    </Link>
                                  </div>
                                </th>
                                <td className="table-text-color">{item.group}</td>
                                <td className="table-text-color">{item.plan}</td>
                                <td className="table-text-color">{item.frequency}</td>
                                <td>
                                  <div className="active-status">
                                    <button className="active-btn table-text-color">
                                      <i class="fa-solid fa-circle"></i> {item.status}
                                    </button>
                                  </div>
                                </td>
                                <td className="table-text-color">{item.email}</td>
                                <td>
                                  <Link to="/Clients/Edit">
                                    <Edit />
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}

                        {/* <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <img src={Tableimg} alt="table-img" />
                              <h3 className="table-content display">Tim Preston</h3>
                              <Link className="amount-active" to="/Dashbaordpanel">
                                Open <i class="fa-solid fa-arrow-right"></i>
                              </Link>
                            </div>
                          </th>
                          <td className="table-text-color">Individual</td>
                          <td className="table-text-color">Pro</td>
                          <td className="table-text-color">Monthly</td>
                          <td>
                            <div className="active-status">
                              <button className="active-btn table-text-color">
                                <i class="fa-solid fa-circle"></i> Active
                              </button>
                            </div>
                          </td>
                          <td className="table-text-color">
                            tim@budgetbuddie.co.nz
                            <Link to="/Clients/Edit">
                              <Edit />
                            </Link>
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <img src={Tableimg} alt="table-img" />
                              <h3 className="table-content display">Tim Preston</h3>
                              <Link className="amount-active" to="/Dashbaordpanel">
                                Open <i class="fa-solid fa-arrow-right"></i>
                              </Link>
                            </div>
                          </th>
                          <td className="table-text-color">Individual</td>
                          <td className="table-text-color">Pro</td>
                          <td className="table-text-color">Monthly</td>
                          <td>
                            <div className="active-status">
                              <button className="active-btn table-text-color" onClick={activeChange}>
                                <i class="fa-solid fa-circle"></i> Active
                              </button>
                            </div>
                          </td>
                          <td className="table-text-color">
                            tim@budgetbuddie.co.nz
                            <Link to="/Clients/Edit">
                              <Edit />
                            </Link>
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <img src={Tableimg} alt="table-img" />
                              <h3 className="table-content display">Tim Preston</h3>
                              <Link className="amount-active" to="/Dashbaordpanel">
                                Open <i class="fa-solid fa-arrow-right"></i>
                              </Link>
                            </div>
                          </th>
                          <td className="table-text-color">Individual</td>
                          <td className="table-text-color">Pro</td>
                          <td className="table-text-color">Monthly</td>
                          <td>
                            <div className="active-status">
                              <button className="active-btn table-text-color" onClick={activeChange}>
                                <i class="fa-solid fa-circle"></i> Active
                              </button>
                            </div>
                          </td>
                          <td className="table-text-color">
                            tim@budgetbuddie.co.nz
                            <Link to="/Clients/Edit">
                              <Edit />
                            </Link>
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <img src={Tableimg} alt="table-img" />
                              <h3 className="table-content display">Tim Preston</h3>
                              <Link className="amount-active" to="/Dashbaordpanel">
                                Open <i class="fa-solid fa-arrow-right"></i>
                              </Link>
                            </div>
                          </th>
                          <td className="table-text-color">Individual</td>
                          <td className="table-text-color">Pro</td>
                          <td className="table-text-color">Monthly</td>
                          <td>
                            <div className="active-status">
                              <button className="active-btn table-text-color" onClick={activeChange}>
                                <i class="fa-solid fa-circle"></i> Active
                              </button>
                            </div>
                          </td>
                          <td className="table-text-color">
                            tim@budgetbuddie.co.nz
                            <Link to="/Clients/Edit">
                              <Edit />
                            </Link>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Dashboard;

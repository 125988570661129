import $ from 'jquery';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Arrowright from '../../assests/images/Expand_right.png';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import Profile from '../../assests/images/icons/image.png';
import MainSidebar from '../MainSidebar';
import './BudgetSummary.css';
import Piechart from './Donutpiechart/Piechart';
import Smallcart from './Smallcart/Smallcart';
import Spendbudget from './SpendvsBudget/Spendbudget';
const BudgetSummary = () => {
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            <div className="app-main__outer budget-summary-main">
              <div className="row top-nav">
                <div className="col-md-12 col-xl-4 col-lg-4 col-sm-12 page-heading-title-sub-title">
                  <div className="bold big-head-new">Budget summary</div>
                  <div className="fst-normal setting_text_color">
                    Here's your summary of your budget and how you're tracking
                  </div>
                </div>
                <div className="col-md-12 col-xl-4 col-lg-4 col-sm-12 edit-button-top">
                  <div className="edit-btn-col">
                    <Link to="/Editbudget">
                      <div className="edit-btn" href-="#">
                        <div className="left">
                          <h4>Edit your budget</h4>
                          <p style={{ color: '#04baff' }}>Make any needed changes.</p>
                        </div>

                        <div className="right">
                          <Link to="/Editbudget">
                            <Edit />
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-12 col-xl-4 col-lg-4 col-sm-12 right-section back-to-portal-top">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row middle-seciton budget-summ-tabber-wrp">
                <div className="col-md-8 col-lg-8">
                  <ul class="nav nav-pills mb-5 budget-tabs img-back" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        This Week
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        This Month
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        This Quarter
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="tab-content col-xl-12 col-lg-12 col-sm-12" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row">
                      <div className="col-md-8 col-xl-8 col-sm-12 top-section mb-2 income-vs-budget-wrapper">
                        <div className="col-md-6">
                          <h5 className="title main-title">Income vs Budget</h5>
                        </div>
                        <div class="col-md-6 right-section">
                          <select className="bold big-select">
                            <option>
                              <a href="#">This week</a>
                            </option>
                            <option>Last 12 Months</option>
                            <option>Last 12 Months</option>
                            <option>Last 12 Months</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-8 col-lg-8 col-md-8">
                        <div className="wrp">
                          <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                            <div className="inner-blck">
                              <div className="merchantSummary">
                                <div className="icon-img red-img">💰</div>
                                <div className="content">
                                  <h6 className="small-content">Pay</h6>
                                  <h5 className="bold">$1250</h5>
                                </div>
                              </div>
                              <div className="arrow">
                                <img src={Arrowright} />
                              </div>
                            </div>
                            <div className="left-block name-merchant mb-2">
                              <h6>$300 remaining</h6>
                            </div>
                            <div class="progress blue-progress" style={{ height: '12px' }}>
                              <div
                                class="progress-bar w-75 blue"
                                role="progressbar"
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                          <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                            <div className="inner-blck">
                              <div className="merchantSummary">
                                <div className="icon-img red-img light-red">🏡</div>
                                <div className="content">
                                  <h6 className="small-content">Rental income</h6>
                                  <h5 className="bold">$699</h5>
                                </div>
                              </div>
                              <div className="arrow">
                                <img src={Arrowright} />
                              </div>
                            </div>
                            <div className="left-block name-merchant mb-2">
                              <h6>$90 remaining</h6>
                            </div>
                            <div class="progress red-progress" style={{ height: '12px' }}>
                              <div
                                class="progress-bar w-75 new-red"
                                role="progressbar"
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                          <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 investment-blck">
                            <div className="inner-blck">
                              <div className="merchantSummary">
                                <div className="icon-img red-img purple-img">💸</div>
                                <div className="content">
                                  <h6 className="small-content">Investments</h6>
                                  <h5 className="bold">$890</h5>
                                </div>
                              </div>
                              <div className="arrow">
                                <img src={Arrowright} />
                              </div>
                            </div>
                            <div className="left-block name-merchant mb-2">
                              <h6>$100 remaining</h6>
                            </div>
                            <div class="progress purple-img" style={{ height: '12px' }}>
                              <div
                                class="progress-bar w-75 new-red new-purple"
                                role="progressbar"
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                          <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 investment-blck">
                            <div className="inner-blck">
                              <div className="merchantSummary">
                                <div className="icon-img red-img light-yellow">🤔</div>
                                <div className="content">
                                  <h6 className="small-content">Pay</h6>
                                  <h5 className="bold">$1250</h5>
                                </div>
                              </div>
                              <div className="arrow">
                                <img src={Arrowright} />
                              </div>
                            </div>
                            <div className="left-block name-merchant mb-2">
                              <h6>$300 remaining</h6>
                            </div>
                            <div class="progress red-progress light-yellow" style={{ height: '12px' }}>
                              <div
                                class="progress-bar w-75 new-red yellow"
                                role="progressbar"
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-12 col-sm-12 mt-6 income-vs-budget">
                        <div className="right-top-blck">
                          <div className="new-blck">
                            <div className="content">
                              <h3 className="title">Total income vs budget</h3>
                              <p className="common-content">See how you’re tracking</p>
                            </div>
                            <div className="right">
                              <h3 className="per-head bold">12.5%</h3>
                            </div>
                          </div>
                          <div className="pie-blck first-circular-block ">
                            <Piechart />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8">
                        <div className="wrp">
                          <div className="col-lg-12 col-xl-12 col-sm-12 merchant-blck">
                            <div className="inner-blck">
                              <div className="merchantSummary">
                                <div className="content">
                                  <h6 className="small-content little-big-font">Total income vs budget</h6>
                                  <h5 className="bold">$1520</h5>
                                </div>
                              </div>
                              <div className="arrow">
                                <img src={Arrowright} />
                              </div>
                            </div>
                            <div className="left-block name-merchant mb-2 mt-3">
                              <h6>$300 remaining</h6>
                            </div>
                            <div class="progress blue-progress light-green" style={{ height: '16px' }}>
                              <div
                                class="progress-bar w-75 green"
                                role="progressbar"
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="right-top-blck new-top-blck">
                          <div className="new-blck">
                            <div className="content">
                              <h3 className="title">Income breakdown</h3>
                              <div class="right-section">
                                <select>
                                  <option>
                                    <a href="#">This month</a>
                                  </option>
                                  <option>Last 12 Months</option>
                                  <option>Last 12 Months</option>
                                  <option>Last 12 Months</option>
                                </select>
                              </div>
                            </div>
                            <div className="right">
                              <h3 className="per-head bold">$5600</h3>
                            </div>
                          </div>
                          <div className="pie-blck">
                            <Smallcart />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Spendbudget />
                  </div>
                  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    ...
                  </div>
                  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    ...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BudgetSummary;

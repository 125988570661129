import $ from 'jquery';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Countdown from '../../assests/images/icons/Countdown.png';
import { ReactComponent as Filter } from '../../assests/images/icons/Filter.svg';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Search } from '../../assests/images/icons/Search.svg';
import MainSidebar from '../MainSidebar';

const Transactions = () => {
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            {/* <MainSidebar toggleSidebar={toggleSidebar} /> */}
            <div className="app-main__outer your-transaction-main-container">
              <div className="row top-nav">
                <div className="col-md-6">
                  <div className="bold big-head-new">Your transactions</div>
                  <div className="fst-normal setting_text_color">Here’s all of your transactions from all accounts</div>
                </div>
                <div className="col-md-6 right-section your-trans-back-to-portal">
                  <div className="setting_background_color right-section search-spacing">
                    <div className="profile-dashboard">
                      {/* <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link> */}

                      <Link className="comn-btn" to="/Clients/Activepage">
                        <i class="fa-solid fa-arrow-left"></i> Back to portal
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 search-spacing filter-transaction-wrapper">
                <div className="bold heading-sml filter-trans">Filter Transactions</div>
                <div className="col-md-4 sa filter-group-wrapp">
                  <div className="group-backgroud-color mt-3">
                    <div className="bold black">Merchant type</div>
                    <div className="group-btn green-btn mt-4">
                      <div className="button-blck">
                        <a href="#" className="group-button green">
                          All
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Groceries
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Car
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Insurance
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Clothes
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Travel
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          House
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 search-spacing for-speed-range-section filter-group-wrapp">
                  <div className="group-backgroud-color mt-3">
                    <div className="bold black">Spend range</div>
                    <div className="group-btn green-btn mt-4">
                      <div className="button-blck">
                        <a href="#" className="group-button green">
                          All
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          $10-50
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          $50-$100
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          $100-$300
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          $300-$600
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          $600+
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 search-spacing for-date-range-section filter-group-wrapp">
                  <div className="group-backgroud-color mt-3">
                    <div className="bold black">Date range</div>
                    <div className="group-btn green-btn mt-4">
                      <div className="button-blck">
                        <a href="#" className="group-button green">
                          All
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          This week
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          This month
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          This quarter
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button ms-1">
                          This year
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Last week
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Last month
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-12 transaction-side">
                  <div className="d-flex justify-content-between mb-3">
                    <div className="bold heading-sml filter-trans">Transactions</div>
                    <div className="d-flex">
                      <div>
                        <Search className="trabs" />
                      </div>
                      <div className="">
                        <i class="fa-solid fa-plus ms-4"></i>
                      </div>
                      <div>
                        <Filter className="ms-4" />
                      </div>
                    </div>
                  </div>
                  <div className="table-horizontal">
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction inter">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction inter">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction inter">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction inter">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction inter">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction inter">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction inter">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction inter">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                    <div className="transactions-table table-transaction">
                      <div className="d-flex">
                        <div className="countdown-transaction">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-4">
                          <div className="transactions-text inter">Countdown</div>
                          <div className="select-transaction">
                            <select class="form-select transactions-select">
                              <option selected>Groceries</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="transactions-text big-text-transaction">Food account</div>
                      <div className="transactions-text big-text-transaction inter">
                        19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am
                      </div>
                      <div className="transactions-text pending inter">Pending</div>
                      <div className="transactions-text light-red-trans inter">-$32.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Transactions;

import React from 'react';
import Countdown from '../../assests/images/icons/Countdown.png';
import KFC from '../../assests/images/icons/KFC.png';
import MightyApe from '../../assests/images/icons/MightyApe.png';
import Torpedo from '../../assests/images/icons/Torpedo 7.png';
import Trademe from '../../assests/images/icons/Trademe.png';

const Merchant = () => {
  return (
    <>
      <div className="col-md-6 col-lg-3 bar-cart top-expenses top-5-merchants-wrapper">
        <div className="recent-see-all">
          <div className="col-md-6 inline mb-4 left">
            <h5>Top 5 Merchants</h5>
          </div>
          <div className="col-md-6 right-section right">
            <select>
              <option>Last 12 Months</option>
            </select>
          </div>
        </div>
        <div className="col-md-12 main-blck">
          <div className="sideblck">
            <div className="icons-mn d-flex">
              <div className="merchnat-img">
                <img src={Countdown} alt="CountDown-img" />
              </div>
              <div className="name-merchant bar-chart">
                <h5>Countdown</h5>
                <h6 className='text-client'>10 Transactions</h6>
              </div>
            </div>
            <div className="transaction-amt ">
              <h4>$560.53</h4>
            </div>
          </div>
          <div className="sideblck">
            <div className="icons-mn d-flex">
              <div className="merchnat-img">
                <img src={Trademe} alt="CountDown-img" />
              </div>
              <div className="name-merchant bar-chart">
                <h5>Trademe</h5>
                <h6 className='text-client'>8 Transactions</h6>
              </div>
            </div>
            <div className="transaction-amt ">
              <h4>$560.53</h4>
            </div>
          </div>
          <div className="sideblck">
            <div className="icons-mn d-flex">
              <div className="merchnat-img">
                <img src={Torpedo} alt="CountDown-img" />
              </div>
              <div className="name-merchant bar-chart">
                <h5>Torpedo 7</h5>
                <h6 className='text-client'>8 Transactions</h6>
              </div>
            </div>
            <div className="transaction-amt ">
              <h4>$560.53</h4>
            </div>
          </div>
          <div className="sideblck">
            <div className="icons-mn d-flex">
              <div className="merchnat-img">
                <img src={MightyApe} alt="CountDown-img" />
              </div>
              <div className="name-merchant bar-chart">
                <h5>MightyApe</h5>
                <h6 className='text-client'>5 Transactions</h6>
              </div>
            </div>
            <div className="transaction-amt ">
              <h4>$560.53</h4>
            </div>
          </div>
          <div className="sideblck">
            <div className="icons-mn d-flex">
              <div className="merchnat-img">
                <img src={KFC} alt="CountDown-img" />
              </div>
              <div className="name-merchant bar-chart">
                <h5>KFC</h5>
                <h6 className='text-client'>3 Transactions</h6>
              </div>
            </div>
            <div className="transaction-amt ">
              <h4>$560.53</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Merchant;

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import Reactlogo from '../../assests/images/Black Logo 1.png';
import Loginimage from '../../assests/images/icons/loginimage.png';
import { Loading } from '../Loading/Loading';
import OverlayContainer from '../OverlayContainer/OverlayContainer';
import weburls from '../Weburls/weburls';

const schema = yup.object().shape({
  username: yup
    .string()
    .required('*Required')
    .email('*Please enter a valid email')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, '*Please enter proper email'),
  password: yup.string().required('*Please enter your password'),
});

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [passwordType, setPasswordType] = useState('password');
  const [loading, setLoading] = useState(false);
  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };

  const onSubmitHandler = async (formData) => {
    setLoading(true);
    try {
      const response = await axios.post(weburls.Advisor_Login, formData);
      setFormData(response);
      setLoading(false);

      localStorage.setItem('budget-token', response.data.data.token);
      console.log(localStorage, 'local');
      localStorage.setItem('userData', JSON.stringify(response.data.data.data));
      localStorage.setItem('roleId', JSON.stringify(response.data.data.data.roleId));
      localStorage.setItem('userUuid',(response.data.data.data.userUuid));

      const userRole = JSON.parse(localStorage.getItem('roleId'));
      setTimeout(() => {
        userRole === 1 ? navigate('/SuperAdmin') : navigate('/Dashboard');
      }, 1000);
      if (response.status === 200) {
        console.log('new response');
        toast.success('API Request Successful', {
          position: 'top-right',
          autoClose: 2000,
        });
      }
      // reset();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Login failed. Please try again.');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section class="login-section">
        <div class="container-fluid h-custom">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-md-9 col-lg-6 col-xl-5">
              <div className="image-new">
                <img src={Loginimage} class="img-fluid" alt="Sample image" />
              </div>
            </div>
            <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <div className="form-wrp">
                <a class="navbar-brand" href="#">
                  <img src={Reactlogo} alt="logo" />
                  <h4 className="heading inline black">BudgetBuddie</h4>
                </a>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div class="form-outline mb-4">
                    <label class="form-label" for="form3Example3">
                      Email address
                    </label>
                    <input
                      {...register('username', {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                      value={formData.username}
                      name="username"
                      type="text"
                      id="form3Example3"
                      class="form-control form-control-lg"
                      placeholder="Enter a valid username"
                    />
                    <p className="mt-1">{errors.username?.message}</p>
                  </div>

                  <div class="form-outline mb-5">
                    <label class="form-label" for="form3Example4">
                      Password
                    </label>
                    <div className="formwrp">
                      <input
                        {...register('password', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                        type={passwordType}
                        name="password"
                        id="form3Example4"
                        class="form-control form-control-lg"
                        placeholder="Enter password"
                      />
                      <div className="" onClick={togglePassword}>
                        {passwordType === 'password' ? (
                          <i class="fa-solid fa-eye"></i>
                        ) : (
                          <i class="fa-solid fa-eye-slash"></i>
                        )}
                      </div>
                    </div>
                    <p className="mt-1">{errors.password?.message}</p>
                  </div>

                  <div class="d-flex justify-content-between align-items-center">
                    <div class="form-check mb-0 form-outline">
                      <input class="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                      <label class="form-check-label" for="form2Example3">
                        Remember me
                      </label>
                    </div>
                    <a href="#!" class="text-body">
                      Forgot password?
                    </a>
                  </div>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000} // Auto close after 5 seconds
                    closeOnClick
                  />
                  {loading === true ? (
                    <OverlayContainer>
                      <div>
                        <Loading />
                      </div>
                    </OverlayContainer>
                  ) : (
                    ''
                  )}
                  <div class="text-center mt-4 pt-2">
                    <button type="submit" class="comn-btn">
                      Login
                    </button>
                    <div class="divider my-4">
                      <p class="text-center fw-bold mx-3 mb-0">Or</p>
                    </div>
                    <div class="d-flex flex-row align-items-center justify-content-center">
                      <p class="lead fw-normal mb-0 me-3">Sign in with</p>
                      <button type="button" class="btn btn-floating mx-1">
                        <i class="fab fa-facebook-f"></i>
                      </button>

                      <button type="button" class="btn btn-floating mx-1">
                        <i class="fab fa-twitter"></i>
                      </button>

                      <button type="button" class="btn btn-floating mx-1">
                        <i class="fab fa-linkedin-in"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;

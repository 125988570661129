import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import axios from 'axios';
import weburls from '../Weburls/weburls';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const schema = yup.object().shape({
  firstName: yup.string().min(3, '*Required').required('*Required'),
  lastName: yup.string().required('*Required'),
  // phoneNumber:yup.number().typeError("That doesn't look like a phone number")
  // .positive("A phone number can't start with a minus")
  // .integer("A phone number can't include a decimal point")
  // .min(10)
  phoneNumber: yup.string().required('*Required'),
  email: yup.string().email('*Please enter a valid email').required('*Required'),
  groupName: yup.string().required('*Required'),
  selectPlan: yup.string().min(3, '*Required').required('*Required'),
  billingFrequency: yup.string().required('*Required'),
  billTo: yup.string().required('*Required'),
  advisorName: yup.string().required('*Required'),
  advisorName2: yup.string().required('*Required'),
});

const ClientDetails = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  // const onSubmitHandler = (data) => {
  //   console.log({ data });
  //   reset();
  // };
  const { id } = useParams();
  const [clientdetails,clientDetails] = useState([]);
  
  const getClientid = async() => {
    const response = await axios.get(weburls.Get_clients_cliendid + `${id}`)
    console.log(response);
    clientDetails(response.data)
    setEdit(response.data,
      {groupId: response.data.groupId})
      setSelectedOption(response.data.groupId)
  }
  const navigate = useNavigate();
  const [edit, setEdit] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    groupId: '',
    // groupWebsite: '',
    // groupName: '',
    // advisorId: advisorid,
    // eslint-disable-next-line no-undef
    // groupId: groupid,
  });
  const [formError, setformError] = useState('');

  const validateInput = (fieldName, value) => {
    if (!value) {
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`, // Generate a custom error message based on the field name
      }));
    } else {
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '',
      }));
    }
  };
  const advisorsDetail = JSON.parse(localStorage.getItem('userData'));

  const Updategroup = async (event) => {
    event.preventDefault();
    console.log('cliecked');
    const updatedData = {
      ...edit,
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      phoneNumber: event.target.phoneNumber.value,
      // groupId: event.target.groupId.value,
      // groupName: event.target.groupName.value,
    };
    try{
      const update = await axios.put(weburls.Update_clients, updatedData);
      console.log(update.data.message, 'updated');
      if (update.status === 200) {
        toast.success('Client edited successfully..', {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate('/Clients');
        }, 3000);
      } else if (update.status === 500) {
        toast.error('Something went wrong please try again.');
      }
    }
    catch(error){
      console.log(error)
    }
  };
  const [grouplist, setGroupList] = useState([]);
  const [planlist, setPlanList] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    const selectedOptionDetails = grouplist.find((item) => item.groupId === e.target.value);
    setEdit({
      groupId: selectedOptionDetails.groupId,
      groupName: selectedOptionDetails.groupName,
    });
  };
  const getGrouplist = async () => {
    try {
      const response = await axios.post(weburls.Group_list, {
        advisorId: advisorsDetail.advisorId,
      });
      if (response?.data?.length > 0) {
        setGroupList(response.data);
        // setLoading(false);
      }
      // setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const getPlanlist = async () => {
    const response = await axios.get(weburls.Super_admin_plans_list);
    setPlanList(response.data);
  };
  useEffect(() =>{
    getClientid()
    getPlanlist();
    getGrouplist()
  },[id])
  return (
    <>
    {console.log(clientdetails,"details")}
      <div className="container-fluid">
        <Header />

        <div className="appmain">
          <Sidebar />
          <ToastContainer position="top-right" autoClose={5000} closeOnClick />
          <div className="app-main__outer edit-client-details-page">
            <div className="row">
              <div className="col-md-12 back-to-dashboard">
                <h3 className="small-heading">
                  <Link to="/Clients"><i class="fa-solid fa-arrow-left"></i> Back to clients</Link>
                </h3>
              </div>
            </div>
            <div className="setting_background_color mt-3">
              <form onSubmit={Updategroup}>
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black big-top-heading">Edit a clients details</span>
                  <div className="setting_text_color">
                    Edit your clients details. they'll get updated on the clients list.
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xl-5">
                  <h5 className="bold black heading-new">Personal details </h5>
                  <div className="setting_text_color">This will be displayed on their profile</div>
                </div>
                <div className="col-md-12 col-sm-12 col-xl-7">
                  <div className="row">
                    <div className="col-md-12 col-xl-6">
                      <div class="input-group mb-3">
                        <input
                          {...register('firstName')}
                          type="text"
                          class="form-control input_border"
                          defaultValue={edit?.firstName}
                          placeholder="First name"
                          name='firstName'
                          onChange={(e) => validateInput('firstName', e.target.value)}

                        />
                      </div>
                      {formError.firstName && <p className="error-message">{formError.firstName}</p>}
                    </div>
                    <div className="col-md-12 col-xl-6">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control input_border"
                          placeholder="Last name"
                          {...register('lastName')}
                          name='lastName'
                          defaultValue={edit?.lastName}
                          onChange={(e) => validateInput('lastName', e.target.value)}

                        />
                      </div>
                      {formError.lastName && <p className="error-message">{formError.lastName}</p>}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 col-xl-7">
                      <div class="input-group mb-3">
                        <input
                          {...register('email')}
                          type="email"
                          class="form-control input_border "
                          placeholder="Email"
                          name='email'
                          defaultValue={edit?.email}
                          onChange={(e) => validateInput('email', e.target.value)}
                        />
                      </div>
                      {formError.email && <p className="error-message">{formError.email}</p>}
                    </div>
                    <div className="col-md-12 col-xl-5">
                      <div class="input-group mb-3">
                        <input
                          {...register('phoneNumber')}
                          type="number"
                          class="form-control input_border "
                          placeholder="Phone number"
                          defaultValue={edit?.phoneNumber}
                          onChange={(e) => validateInput('phoneNumber', e.target.value)}
                        />
                      </div>
                      {formError.phoneNumber && <p className="error-message">{formError.phoneNumber}</p>}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xl-5">
                  <h5 className="bold black heading-new">Group details</h5>
                  <div className="setting_text_color">This is if they're part of a group package</div>
                </div>
                <div className="col-md-12 col-sm-12 col-xl-7">
                <div class="input-group mb-3">
                          <select
                            class="form-select input_border mb-4"
                            name="grouplsit"
                            value={selectedOption}
                            onChange={handleSelectChange}
                          >
                            {/* <option value={edit.groupId}>{edit.groupName}</option> */}
                            {grouplist && grouplist.length > 0
                              ? grouplist.map((item) => (
                                  <option key={item.groupId} value={item.groupId}>
                                    {item.groupName}
                                  </option> 
                                ))
                              : 'No data found'}
                          </select>{' '}
                        </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xl-5">
                  <h5 className="bold black">Plan details</h5>
                  <div className="setting_text_color">Enter in the type of plan the user has selected</div>
                </div>
                <div className="col-md-12 col-sm-12 col-xl-7">
                  <div className="row">
                    <div className="col-md-12 col-xl-6">
                    <div class="input-group mb-3">
                          <select
                            class="form-select input_border mb-4"
                            name="grouplsit"
                            value={selectedOption}
                            onChange={handleSelectChange}
                          >
                            {/* <option value={edit.groupId}>{edit.groupName}</option> */}
                            {planlist && planlist.length > 0
                              ? planlist.map((item) => (
                                  <option key={item.planId} value={item.planId}>
                                    {item.planName}
                                  </option> 
                                ))
                              : 'No data found'}
                          </select>{' '}
                        </div>
                    </div>
                    <div className="col-md-12 col-xl-6">
                      <select class="form-select input_border" {...register('billingFrequency')}>
                        <option selected>Billing Frequency </option>
                        <option> Billing Frequency 2</option>
                        <option> Billing Frequency 3</option>
                      </select>
                      <p className="error-message">{errors.billingFrequency?.message}</p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 col-xl-6"></div>
                    <div className="col-md-6">
                      <select class="form-select input_border " {...register('billTo')}>
                        <option>Bill to</option>
                        <option>Bill to 2</option>
                        <option>Bill to 3</option>
                      </select>
                      <p className="error-message">{errors.billTo?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-4 edit-client-last-side">
                <div className="col-md-12 col-sm-12 col-xl-5">
                  <h5 className="bold black heading-new">Assigned advisors</h5>
                  <div className="setting_text_color">Enter in the assigned advisor to this group</div>
                </div>
                <div className="col-md-12 col-sm-12 col-xl-7">
                  <div className="row">
                    <div className="col-md-12 col-xl-6">
                      <div class="input-group mb-3">
                        <input
                          {...register('advisorName')}
                          type="text"
                          class="form-control input_border"
                          placeholder="Assigned advisor"
                        />
                      </div>
                      <p className="error-message">{errors.advisorName?.message}</p>
                    </div>
                    <div className="col-md-12 col-xl-6">
                      <div class="input-group mb-3">
                        <input
                          {...register('advisorName2')}
                          type="text"
                          class="form-control input_border"
                          placeholder="Assigned advisor"
                        />
                      </div>
                      <p className="error-message">{errors.advisorName2?.message}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="button mt-5 client-button edit-clients-buttons">
                    <button type="button" className="comn-btn button_white">
                      <Link to="/Clients" className="black">
                        {' '}
                        Cancel
                      </Link>
                    </button>
                    <button
                      type="submit"
                      className="comn-btn save-details common-btn-style"
                    >
                      Save edits
                      <i class="fa-solid fa-plus"></i>
                    </button>
                    <button type="button" className="comn-btn delete-btn">
                      Delete client
                      <i class="fa-solid fa-minus"></i>
                    </button>
                  </div>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ClientDetails;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as Bigplus } from '../../../assests/images/icons/Add_round.svg';
import { ReactComponent as Edit } from '../../../assests/images/icons/more.svg';
import { ReactComponent as Plus } from '../../../assests/images/icons/QuickModeOn.svg';
import LoadingTxt from '../../Loading/LoadingTxt';
import EditExpense from '../../ModalForm/ReoccuringExpense/EditExpense';
import ReoccuringExpense from '../../ModalForm/ReoccuringExpense/ReoccuringExpense';
import weburls from '../../Weburls/weburls';

const Reoccuring = () => {
  const [active, setActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expenseApiData, setExpenseApiData] = useState([]);
  const [editExpense, setEditExpense] = useState(false);
  const [expenseUuid, setExpenseUuid] = useState(null);
  // const clientData = JSON.parse(localStorage.getItem('client-userUuid'));
  // const clientUuid = clientData[0]?.userUuid;

  let clientUuid = null;

  try {
    const clientUserUuidData = localStorage.getItem('client-userUuid');
    if (clientUserUuidData) {
      const parsedData = JSON.parse(clientUserUuidData);
      clientUuid = parsedData[0]?.userUuid;
    }
  } catch (error) {
    console.error('Error parsing client-userUuid data:', error);
  }

  const activeChange = () => {
    setActive(true);
  };
  const handleModalForm = () => {
    setModalOpen(true);
    setEditExpense(false);
  };

  const handleModalEditForm = (id) => {
    setExpenseUuid(id);
    setModalOpen(true);
    setEditExpense(true);
  };

  const formatDate = (datetimeString) => {
    const date = new Date(datetimeString);
    return date.toLocaleDateString();
  };

  const fetchExpense = async () => {
    try {
      const response = await axios.get(`${weburls.Get_Expense}${clientUuid}`);
      if (response?.data?.expenses?.length > 0) {
        setExpenseApiData(response?.data?.expenses);
      } else {
        console.log('Getting error');
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);

      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    fetchExpense();
  }, []);

  return (
    <>
      <div className="row reoccuring-wrp">
        <div className="col-xl-6">
          <div className="col-xl-12 top-head-section">
            <h3 className="semi-heading-edit-budget">Your reoccurring expenses</h3>
            <p>Your reoccurring expenses are what bills you pay each week, fortnight or month.</p>
          </div>
        </div>
        <div className="col-xl-6 right-section">
          <button className="new-btn">
            Auto expense <Plus />
          </button>
        </div>
      </div>
      <div className="mt-2">
        <div className="col-lg-12 new-table second-table-edit-budget">
          <div>
            <div className="table-horizontal">
              <div class="col-12 table-horizontal">
                <table class="table-image new-table">
                  <thead>
                    <tr>
                      <th scope="col">Expense type</th>
                      <th scope="col">Expense amount</th>
                      <th scope="col">Last payment date</th>
                      <th scope="col">How Often? </th>
                      <th scope="col">Account associated</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="space-table">
                    {loading ? (
                      <div className="no-data-found-td">
                        <LoadingTxt />
                      </div>
                    ) : expenseApiData?.length > 0 ? (
                      expenseApiData &&
                      expenseApiData.map((item, index) => {
                        return (
                          <tr onClick={activeChange} className="cursor-pointer">
                            <th>
                              <div className="air-image">
                                <h3 className="table-content display">{item.expenseType}</h3>
                              </div>
                            </th>
                            <td className="table-text-color">
                              <div className="planner">{item.expenseAmount}</div>
                            </td>
                            <td className="table-text-color">
                              <div className="planner">{formatDate(item.lastPaymentDate)}</div>
                            </td>

                            <td className="table-text-color">
                              <select className="planner weekly-month-planner">
                                <option>{item.howOften}</option>
                              </select>
                            </td>
                            <td className="table-text-color">
                              <div className="planner big-planner">{item.accountAssociated}</div>
                            </td>
                            <td className="table-text-color" onClick={() => handleModalEditForm(item.expenseUuid)}>
                              <Edit />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        {' '}
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Food</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color" onClick={handleModalEditForm}>
                            <Edit />
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Power & Wifi</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color" onClick={handleModalEditForm}>
                            <Edit />
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Insurance</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color" onClick={handleModalEditForm}>
                            <Edit />
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Rent</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color" onClick={handleModalEditForm}>
                            <Edit />
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Car</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color" onClick={handleModalEditForm}>
                            <Edit />
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Phone Plan</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color" onClick={handleModalEditForm}>
                            <Edit />
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Rate</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color" onClick={handleModalEditForm}>
                            <Edit />
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Clothes</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color" onClick={handleModalEditForm}>
                            <Edit />
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Eating out</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color" onClick={handleModalEditForm}>
                            <Edit />
                          </td>
                        </tr>
                        <tr onClick={activeChange} className="cursor-pointer">
                          <th>
                            <div className="air-image">
                              <h3 className="table-content display">Gym</h3>
                            </div>
                          </th>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <div className="planner"></div>
                          </td>
                          <td className="table-text-color">
                            <select className="planner weekly-month-planner">
                              <option>Weekly</option>
                            </select>
                          </td>
                          <td className="table-text-color">
                            <div className="planner big-planner"></div>
                          </td>
                          <td className="table-text-color" onClick={handleModalEditForm}>
                            <Edit />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <div className="income-btn-wrp col-xl-12">
                  <button className="new-btn red-btn" onClick={handleModalForm}>
                    Add Expense <Bigplus />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpen === true && (
        <div>
          {editExpense === true ? (
            <EditExpense
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              expenseUuid={expenseUuid}
              fetchExpense={fetchExpense}
            />
          ) : (
            <ReoccuringExpense modalOpen={modalOpen} setModalOpen={setModalOpen} fetchExpense={fetchExpense} />
          )}
        </div>
      )}
    </>
  );
};
export default Reoccuring;

import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import faker from 'faker';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );
  export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    layout: {
      padding: {
        left: -100,
        bottom:-100
      }
    },

  
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        stacked: true,
        border: {
          display: false,
        },
        ticks:{
          display: false,

        }
      },
      y: {
        ticks: {
          display: false,
        },
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
    },
  };

  const labels= ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul","Aug"]

  export const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data:[5,4,5,4,5,6,4,5,3,5,6,7],
        borderColor: 'rgba(78, 195, 118, 1)',
        backgroundColor: 'rgba(78, 195, 118, 0.24)',
        borderWidth: 1,
        radius:0,
        tension:0.3,
      },
    ],
  };
  export function LinecartNetworth() {
    return <Line options={options} data={data} className="barcart" />;
  }





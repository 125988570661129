import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import AirNewZealand from '../../assests/images/icons/AirNewZealand.png';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Upload } from '../../assests/images/icons/upload.svg';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Header from '../Header/Header';
import weburls from '../Weburls/weburls';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GroupDetail = () => {
  const { id } = useParams();
  // const groupid = localStorage.getItem('userData')
  // console.log(groupid,"groupname")
  const schema = yup.object().shape({
    first_name: yup.string().min(3, '*Required').required('*Required'),
    last_name: yup.string().required('*Required'),
    phoneNumber: yup.string().required('*Required'),
    email: yup.string().email('*Please enter a valid email').required('*Required'),
    groupName: yup.string().required('*Required'),
    selectPlan: yup.string().min(3, '*Required').required('*Required'),
    billingFrequency: yup.string().required('*Required'),
    billTo: yup.string().required('*Required'),
    advisorName: yup.string().required('*Required'),
    advisorName2: yup.string().required('*Required'),
  });
  const [formError, setformError] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setEdit((prev) => ({ ...prev, [name]: value }));
  // };
  const navigate = useNavigate();
  const [groupid, groupId] = useState('');
  const [advisorid, advisorId] = useState('');
  const [data, setData] = useState('');
  // const history = useHistory();
  const Getgroupdetails = async () => {
    const response = await axios.get(weburls.Get_group_details + `${id}`);
    console.log(response.data);
    groupId(response.data.group_id);
    advisorId(response.data.advisor_id);
    setData(response.data);
    setEdit(response.data);
  };
  const [edit, setEdit] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    groupWebsite: '',
    groupName: '',
    advisorId: advisorid,
    // eslint-disable-next-line no-undef
    groupId: groupid,

  });

  useEffect(() => {
    Getgroupdetails();
  }, []);

  const upload = (file) => {
    console.log(file.target.files[0]);
  };
  const validateInput = (fieldName, value) => {
    if (!value) {
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`, // Generate a custom error message based on the field name
      }));
    } else {
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '',
      }));
    }
  };
  // const navigate = useNavigate();
  // const [updatedata,updatedData] = useState('')
  const Updategroup = async (event) => {
    event.preventDefault();
    console.log('cliecked');

    const updatedData = {
      ...edit,
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      phoneNumber: event.target.phoneNumber.value,
      groupWebsite: event.target.groupWebsite.value,
      groupName: event.target.groupName.value,
    };
      try{
        const update = await axios.put(weburls.Update_group, updatedData);
        console.log(update.data.message, 'updated');
        if (update.status === 200) {
          toast.success('Group edited successfully..', {
            position: 'top-right',
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate('/Groups');
          }, 3000);
        } else if (update.status === 500) {
          toast.error('Something went wrong please try again.');
        }
      }
      catch(error){
        console.log(error)
      }
  };

  const deleteGroup = async() => {
    try{
      const response = await axios.delete(weburls.Delete_group + `${id}`)
      console.log(response,"delete item");
      if (response.status === 200) {
        toast.success('Deleted group successfully..', {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate('/Groups');
        }, 3000);
      } else if (response.status === 500) {
        toast.error('Something went wrong please try again.');
      }
    }
    catch(error){
      console.log(error)
    }
  }
  return (
    <>
      <div className="container-fluid">
        <Header />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer edit-group-page-main">
            <div className="row">
              <div className="col-md-12 mb-3">
                <h3 className="small-heading">
                  <i class="fa-solid fa-arrow-left"></i>
                  <Link to="/Groups">Back to groups</Link>
                </h3>
              </div>
            </div>
            <div className="setting_background_color">
              <div className="edit-group-title-wrapper col-lg-12 col-md-12">
                <div className="fs-3">
                  <span className="bold black edit-heading">Edit your group details</span>
                  <div className="setting_text_color">
                    Edit your groups details, they'll get an email once you're finished
                  </div>
                </div>
                <div className="mt-3">
                  <div className="profile-dashboard client-summary-page-main ">
                    <div className="profile-dashboard">
                      <img src={AirNewZealand} alt="" />
                    </div>

                    <div className="profile-dashboard">
                      <h4 className="bold client-name">Air New Zealand</h4>
                    </div>
                    <div className="profile-dashboard">
                      <button className="active-btn">
                        <i class="fa-solid fa-circle"></i> Active
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <form onSubmit={Updategroup}>
                <div className="row">
                  <ToastContainer position="top-right" autoClose={5000} closeOnClick />
                  <div className="col-md-6 col-sm-12">
                    <h5 className="bold black heading-new">Main contact </h5>
                    <div className="setting_text_color">This is who to contact about their plan</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-6">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="First name"
                            defaultValue={edit?.firstName}
                            // {...register('firstName')}
                            name="firstName"
                            onChange={(e) => validateInput('firstName', e.target.value)}
                          />
                        </div>
                        {formError.firstName && <p className="error-message">{formError.firstName}</p>}
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Last name"
                            defaultValue={edit?.lastName}
                            {...register('lastName')}
                            name="lastName"
                            onChange={(e) => validateInput('lastName', e.target.value)}
                          />
                        </div>
                        {formError.lastName && <p className="error-message">{formError.lastName}</p>}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-7">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Email"
                            defaultValue={edit?.email}
                            {...register('email')}
                            name="email"
                            onChange={(e) => validateInput('email', e.target.value)}
                          />
                        </div>
                        {formError.email && <p className="error-message">{formError.email}</p>}
                      </div>
                      <div className="col-xl-5 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="phone_number"
                            defaultValue={edit?.phoneNumber}
                            {...register('phoneNumber')}
                            name="phoneNumber"
                            onChange={(e) => validateInput('phoneNumber', e.target.value)}
                          />
                        </div>
                        {formError.phoneNumber && <p className="error-message">{formError.phoneNumber}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12 col-xl-6 col-sm-12">
                    <h5 className="bold black heading-new">Group details</h5>
                    <div className="setting_text_color">Enter in the details of the group.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-6">
                    <div className="row mt-2">
                      <div className="col-xl-6 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Group website"
                            defaultValue={edit?.groupWebsite}
                            {...register('groupWebsite')}
                            name="groupWebsite"
                            onChange={(e) => validateInput('groupWebsite', e.target.value)}
                          />
                        </div>
                        {formError.groupWebsite && <p className="error-message">{formError.groupWebsite}</p>}
                      </div>
                      <div className="col-xl-6 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Group name"
                            defaultValue={edit?.groupName}
                            {...register('groupName')}
                            name="groupName"
                            onChange={(e) => validateInput('groupName', e.target.value)}
                          />
                        </div>
                        {formError.groupName && <p className="error-message">{formError.groupName}</p>}
                      </div>
                    </div>
                    <div className="row mt-2 upload-csv-file">
                      <div class="col-md-10 col-lg-10">
                        <div className="input-group">
                          <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                            Profile image
                            <input
                              type="file"
                              class="form-control input_border "
                              placeholder="Profile Image"
                              id="file-format"
                              name="file-input"
                              onChange={upload}
                            />
                            <div className="upload">
                              <Upload className="upload-logo" />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="profile-img">
                          <img src={Profile} alt="profile" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="bold black heading-new">Assigned advisors</h5>
                    <div className="setting_text_color">Enter in the assigned advisor to this group</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-6">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input type="text" class="form-control input_border" placeholder="Assigned advisor" />
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input type="text" class="form-control input_border" placeholder="Assigned advisor" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="bold black heading-new">Plan details</h5>
                    <div className="setting_text_color">Enter in the type of plan the group is using</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-6 mb-3">
                    <div className="row mt-3 plan-wrp">
                      <div className="col-md-12 mb-4">
                        <input type="text" class="form-control input_border" placeholder="Number of plans" />
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <select class="form-select input_border mb-3">
                          <option selected>Select a plan</option>
                          <option selected>Select a plan</option>
                          <option selected>Select a plan</option>
                          <option selected>Select a plan</option>
                        </select>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <select class="form-select input_border mb-3">
                          <option selected> Billing Frequency</option>
                          <option selected> Billing Frequency</option>
                          <option selected> Billing Frequency</option>
                          <option selected> Billing Frequency</option>
                          <option selected> Billing Frequency</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-12 mt-5">
                    <h2 className="heading bold black edit-heading">Edit individual client details</h2>
                    <div className="setting_text_color">
                      Enter in your group details, they'll get an email once you're finished.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="table-wrp mt-3">
                    <div class="row">
                      <div class="col-12 table-edit-group-page">
                        <table class="table-image group-table">
                          <thead className="purple">
                            <tr>
                              <th scope="col">First Name</th>
                              <th scope="col">Last Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Phone number</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody className="space-table">
                            <tr>
                              <th>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </th>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <a href="#" className="black">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </th>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <a href="#" className="black">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </th>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <a href="#" className="black">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </th>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <a href="#" className="black">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </th>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <div class="input-group">
                                  <input type="text" class="form-control input_border " placeholder="" />
                                </div>
                              </td>
                              <td>
                                <a href="#" className="black">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="setting_background_color mt-5">
                  <div className="">
                    <div className="fs-3">
                      <span className="bold black edit-heading"> Save your details</span>
                      <div className="setting_text_color">
                        Once you've send all of your users, they'll get an email notification to use BudgetBuddie.
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6 col-sm-12"></div>
                    <div>
                      <div className="button mb-5">
                        <button type="button" className="comn-btn button_white">
                          Cancel
                        </button>
                        <button type="submit" className="comn-btn common-btn-style">
                          Save edits<i class="fa-solid fa-plus plus-icon-style"></i>
                        </button>
                        <button type="button" className="comn-btn delete-btn" onClick={deleteGroup}>
                          Delete group
                          <i class="fa-solid fa-minus minus-icon-style"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default GroupDetail;

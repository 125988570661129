import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { ReactComponent as Plus } from '../../assests/images/icons/Add_round.svg';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Edit } from '../../assests/images/icons/more.svg';
import { ReactComponent as Arrow } from '../../assests/images/icons/New_Arrow_right.svg';
import LoadingTxt from '../Loading/LoadingTxt';
import MainSidebar from '../MainSidebar';
import AddIncomeForm from '../ModalForm/AddIncome/AddIncomeForm';
import EditIncome from '../ModalForm/AddIncome/EditIncome';
import weburls from '../Weburls/weburls';
import Accountransfer from './Accounttransfer/Accountransfer';
import Reoccuring from './Recoccuringexpenses/Reoccuring';

const Editbudget = () => {
  const [incomeApiData, setIncomeApiData] = useState([]);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [editIncome, setEditIncome] = useState(false);
  const [incomeUuid, setIncomeUuid] = useState(null);

  let clientUuid = null;

  try {
    const clientUserUuidData = localStorage.getItem('client-userUuid');
    if (clientUserUuidData) {
      const parsedData = JSON.parse(clientUserUuidData);
      clientUuid = parsedData[0]?.userUuid;
    }
  } catch (error) {
    console.error('Error parsing client-userUuid data:', error);
  }

  const activeChange = () => {
    setActive(true);
  };

  const handleModalForm = () => {
    setModalOpen(true);
    setEditIncome(false);
  };

  const handleModalEditForm = (id) => {
    setIncomeUuid(id);
    setModalOpen(true);
    setEditIncome(true);
  };

  // Get Income List:--------

  const fetchIncome = async () => {
    try {
      const response = await axios.get(`${weburls.Get_Income}${clientUuid}`);
      if (response?.data?.incomes?.length > 0) {
        setIncomeApiData(response?.data?.incomes);
      } else {
        console.log('Getting error');
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);

      console.error('error', error);
      // toast.error('Something went wrong please try again.', {
      //   position: 'top-right',
      //   autoClose: 2000,
      // });
    }
  };

  useEffect(() => {
    fetchIncome();
  }, []);

  const formatDate = (datetimeString) => {
    const date = new Date(datetimeString);
    return date.toLocaleDateString();
  };

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            <div className="app-main__outer edit-your-budget-main-wrapper">
              <ToastContainer position="top-right" autoClose={5000} closeOnClick />

              <div className="row top-nav">
                <div className="col-md-4 col-lg-3 col-xl-4 edit-your-budget-heading-wrp">
                  <div className="bold top-head big-head-new">Edit your Budget</div>
                  <div className="fst-normal setting_text_color">
                    Work out your income & expenses or let us to the hard work for you.
                  </div>
                </div>
                <div className="col-md-4 edit-budget-view-summary">
                  <div className="edit-btn-col">
                    <Link to="/BudgetSummary">
                      <div className="edit-btn" href-="#">
                        <div className="left">
                          <h4>View budget summary</h4>
                          <p style={{ color: '#04baff' }}>Get the full overview.</p>
                        </div>

                        <div className="right">
                          <Link to="/BudgetSummary">
                            <Arrow />
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4 col-lg-5 col-xl-4 right-section edit-your-budget-back-portal-wrp">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row middle-seciton">
                <div className="col-xl-12 top-head-section">
                  <h3 className="semi-heading-edit-budget bold">Your income after tax</h3>
                  <p>Regular income is what you consistently get paid after tax each week, fortnight or month. </p>
                </div>
                <div className="mt-2">
                  <div className="col-lg-12 new-table first-table-edit-budget">
                    <div>
                      <div className="table-horizontal">
                        <div class="col-12 table-horizontal">
                          <table class="table-image new-table edit-table">
                            <thead>
                              <tr>
                                <th scope="col">Income type</th>
                                <th scope="col">Income name</th>
                                <th scope="col">Income amount</th>
                                <th scope="col">Last payment date</th>
                                <th scope="col">How Often?</th>
                                <th scope="col">Account associated</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody className="space-table">
                              {loading ? (
                                <div className="no-data-found-td">
                                  <LoadingTxt />
                                </div>
                              ) : incomeApiData?.length > 0 ? (
                                incomeApiData &&
                                incomeApiData.map((item, index) => {
                                  return (
                                    <tr onClick={activeChange} className="cursor-pointer">
                                      <th>
                                        <div className="air-image">
                                          <h3 className="table-content display">{item.incomeType}</h3>
                                        </div>
                                      </th>
                                      <td className="table-text-color">
                                        <select className="planner select-income">
                                          <option selected>{item.incomeName}</option>
                                        </select>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner">{item.incomeAmount}</div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner">{formatDate(item.lastPaymentDate)}</div>
                                      </td>
                                      <td className="table-text-color">
                                        <select className="planner weekly-month-planner">
                                          <option>{item.howOften}</option>
                                        </select>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner big-planner">{item.accountAssociated}</div>
                                      </td>
                                      <td
                                        className="table-text-color"
                                        onClick={() => handleModalEditForm(item.incomeUuid)}
                                      >
                                        <Edit />
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <>
                                  <tr onClick={activeChange} className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">Pay</h3>
                                      </div>
                                    </th>
                                    <td className="table-text-color">
                                      <select className="planner">
                                        <option selected></option>
                                      </select>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <select className="planner weekly-month-planner">
                                        <option>Weekly</option>
                                      </select>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner big-planner"></div>
                                    </td>
                                    <td className="table-text-color" onClick={handleModalEditForm}>
                                      <Edit />
                                    </td>
                                  </tr>
                                  <tr onClick={activeChange} className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">Rental Income</h3>
                                      </div>
                                    </th>
                                    <td className="table-text-color">
                                      <select className="planner">
                                        <option selected></option>
                                      </select>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <select className="planner weekly-month-planner">
                                        <option>Weekly</option>
                                      </select>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner big-planner"></div>
                                    </td>
                                    <td className="table-text-color" onClick={handleModalEditForm}>
                                      <Edit />
                                    </td>
                                  </tr>
                                  <tr onClick={activeChange} className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">Investments</h3>
                                      </div>
                                    </th>
                                    <td className="table-text-color">
                                      <select className="planner">
                                        <option selected></option>
                                      </select>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <select className="planner weekly-month-planner">
                                        <option>Weekly</option>
                                      </select>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner big-planner"></div>
                                    </td>
                                    <td className="table-text-color" onClick={handleModalEditForm}>
                                      <Edit />
                                    </td>
                                  </tr>
                                  <tr onClick={activeChange} className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">Other</h3>
                                      </div>
                                    </th>
                                    <td className="table-text-color">
                                      <select className="planner">
                                        <option selected></option>
                                      </select>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <select className="planner weekly-month-planner">
                                        <option>Weekly</option>
                                      </select>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner big-planner"></div>
                                    </td>
                                    <td className="table-text-color" onClick={handleModalEditForm}>
                                      <Edit />
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                          <div className="income-btn-wrp col-xl-12">
                            <button className="new-btn" onClick={handleModalForm}>
                              Add Income <Plus />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="mt-5 col-xl-9 col-md-12 total-income d-flex edit-your-budget-total-income-wrapper"
                      // style={{ alignItems: "center" }}
                    >
                      <div className="col-md-12 col-xl-12">
                        <div className="col-md-9 col-xl-9 total-income-block-section">
                          <div className="inline col-md-3 col-xl-4">
                            <h4 className="table-content bold">Total income</h4>
                          </div>
                          <div className="inline col-md-4 col-xl-6 total-inc-first">
                            <div className="content-blck"></div>
                          </div>
                          <div className="inline col-md-4 col-xl-6 total-inc-first">
                            <div className="content-blck planner-for-weekly edit-table">
                              <select className="planner">
                                <option>Weekly</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Reoccuring />
              <Accountransfer />
            </div>
          </div>
        </div>
      </div>

      {modalOpen === true && (
        <div>
          {editIncome === true ? (
            <EditIncome
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              incomeUuid={incomeUuid}
              fetchIncome={fetchIncome}
            />
          ) : (
            <AddIncomeForm modalOpen={modalOpen} setModalOpen={setModalOpen} fetchIncome={fetchIncome} />
          )}
        </div>
      )}
    </>
  );
};
export default Editbudget;

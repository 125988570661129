import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Tableimg from '../../assests/images/icons/AirNewZealand.png';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import { ReactComponent as Search } from '../../assests/images/icons/Search.svg';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Header from '../Header/Header';
import LoadingTxt from '../Loading/LoadingTxt';
import weburls from '../Weburls/weburls';

const Groups = () => {
  const [showGroup, setShowGroup] = useState(false);
  const [active, setActive] = useState(false);

  const advisorsDetail = JSON.parse(localStorage.getItem('userData'));

  // const [advId, setAdvId] = useState(advisorsDetail.advisor_id);
  const [groupData, setGroupData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const activeChange = () => {
    setActive(true);
    navigate('/Groups/Details');
  };

  const Group = () => {
    setShowGroup(showGroup);
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(weburls.Group_list, {
        advisorId: advisorsDetail.advisorId,
      });
      if (response?.data?.length > 0) {
        setGroupData(response.data);
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* {active ? (
        <DetailPage />
      ) : ( */}
      <div className="container-fluid">
        <Header Group={Group} />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer your-groups-main-front-wrapper">
            <div className="row group-wrp">
              <div className="col-lg-8">
                <div className="fs-3 bold black main-heading-title-group">Your Groups</div>
                <div className="setting_text_color">Here’s a list of your groups using BudgetBuddie.</div>
              </div>
              <div className="col-xl-4 search-spacing col-md-12 col-sm-12">
                <div className="search-block">
                  <form>
                    <Search />
                    <input type="text" className="search" placeholder="Search for a group here" />
                  </form>
                </div>
              </div>
            </div>
            <div className="bold mt-4 mb-3 heading-new">Filter Clients</div>
            <div className="row">
              <div className="col-lg-6 mb-3 filter-clients-top-wrapper">
                <div className="group-backgroud-color">
                  <div className="bold mb-3 m-left">Group</div>
                  <div className="group-btn">
                    <div className="button-blck">
                      <a href="#" className="group-button purple">
                        All
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Individual
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 1
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 2
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 2
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 3
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 4
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 5
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 2
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Company 2
                      </a>
                    </div>
                  </div>
                  <div className="group-btn"></div>
                </div>
              </div>
              <div className="col-lg-3 mb-3 filter-clients-top-wrapper">
                <div className="group-backgroud-color">
                  <div className="bold mb-3 m-left">Bill Frequency</div>
                  <div className="group-btn yellow-btn">
                    <div className="button-blck">
                      <a href="#" className="group-button dark-yellow">
                        All
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button new-dark-yellow">
                        Monthly
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button new-dark-yellow">
                        Annual
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 mb-3 filter-clients-top-wrapper">
                <div className="group-backgroud-color">
                  <div className="bold mb-3 m-left">Status</div>
                  <div className="group-btn green-btn">
                    <div className="button-blck">
                      <a href="#" className="group-button green">
                        All
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Active
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Inactive
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Not-Activated
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-wrp mt-2 your-groups-page-table-list">
              <div class="row">
                <div class="col-12">
                  <table class="table-image">
                    <thead className="purple">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Amount of users</th>
                        <th scope="col">Bill Frequency</th>
                        <th scope="col">Status</th>
                        <th scope="col">Email</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    {groupData?.length > 0 ? (
                      <tbody className="space-table">
                        {groupData &&
                          groupData.map((item, index) => {
                            return (
                              <tr className="cursor-pointer" key={index}>
                                <th>
                                  <div className="air-image" onClick={activeChange}>
                                    <img src={Tableimg} alt="table-img" />
                                    <h3 className="table-content display">{`${item.firstName}${' '}${
                                      item.lastName
                                    }`}</h3>
                                  </div>
                                </th>
                                <td className="table-text-color">120</td>
                                <td className="table-text-color">Annual</td>
                                <td>
                                  {item?.status === 1 ? (
                                    <div className="active-status">
                                      <button className="active-btn table-text-color">
                                        <i class="fa-solid fa-circle"></i> Active
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="in-active-status-div">
                                      <button className="inactive-button table-text-color">
                                        <i class="fa-solid fa-circle"></i> Inactive
                                      </button>
                                    </div>
                                  )}
                                </td>
                                <td className="table-text-color">{item.email}</td>
                                <td>
                                  <Link to={`/Groups/GroupDetail/${item.groupId}`}>
                                    <Edit />
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    ) : loading ? (
                      <div className="no-data-found-td">
                        <LoadingTxt />
                      </div>
                    ) : (
                      <div className="no-data-found-td">No group found</div>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};
export default Groups;

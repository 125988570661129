import $ from 'jquery';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Profileimg from '../../assests/images/icons/Big-profile.png';
import Camera from '../../assests/images/icons/camera.png';
import Profile from '../../assests/images/icons/image.png';
import MainSidebar from '../MainSidebar';
import './setting.css';

const Setting = () => {
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            {/* <MainSidebar toggleSidebar={toggleSidebar} /> */}
            <div className="app-main__outer your-setting-page-container">
              <div className="row">
                <div className="col-md-6">
                  <div className="fs-3 bold big-head-new">Your settings</div>
                  <div className="fst-normal setting_text_color">
                    Lets get the nuts and bolts sorted to optimise your account experience.
                  </div>
                </div>
                <div className="col-md-6 right-section your-setting-page-back-portal">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row setting-page">
                <div className="col-xl-6 setting-wrp">
                  <ul class="nav nav-pills mb-2 setting-tab" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Your details
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Notifications
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        Security
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="tab-content mt-4" id="pills-tabContent">
                  <div
                    class="tab-pane col-xl-12 fade show active d-flex"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="col-xl-6 setting-left-section mt-4">
                      <div className="main-section-left">
                        <div className="heading bold black">
                          <h3>Your details</h3>
                        </div>
                        <form className="row">
                          <div className="field-form col-xl-6">
                            <label>Name</label>
                            <input type="text" className="input-form" alt="name" />
                          </div>
                          <div className="field-form col-xl-6">
                            <label>Phone number</label>
                            <input type="number" className="input-form" alt="phonenumber" />
                          </div>
                          <div className="field-form col-xl-8">
                            <label>Your email</label>
                            <input type="mail" className="input-form" alt="email" />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-xl-6 profile-wrp">
                      <div className="profile-img-setting">
                        <img src={Profileimg} alt="profile imag" />
                        <div className="camera">
                          <img src={Camera} alt="camera" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    ...
                  </div>
                  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    ...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Setting;

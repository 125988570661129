import axios from 'axios';
import $ from 'jquery';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Arrowleft from '../../assests/images/icons/arrow-left.png';
import { ReactComponent as ArrowRight } from '../../assests/images/icons/Arrow_right.svg';
import Car from '../../assests/images/icons/car.png';
import Creditcard from '../../assests/images/icons/credit-card.png';
import HomeValue from '../../assests/images/icons/Home-value.png';
import HouseholdItem from '../../assests/images/icons/Household-items.png';
import Profile from '../../assests/images/icons/image.png';
import Laybuy from '../../assests/images/icons/Laybuy.png';
import { ReactComponent as Edit } from '../../assests/images/icons/more.svg';
import Mortgage from '../../assests/images/icons/Mortgage.png';
import Sharesis from '../../assests/images/icons/Sharesies.png';
import { ReactComponent as Upload } from '../../assests/images/icons/upload.svg';
import LoadingTxt from '../Loading/LoadingTxt';
import MainSidebar from '../MainSidebar';
import AddAssets from '../ModalForm/AddAsset/AddAssets';
import EditAssets from '../ModalForm/AddAsset/EditAssets';
import AddLiability from '../ModalForm/AddLiability/AddLiability';
import EditLiability from '../ModalForm/AddLiability/EditLiability';
import weburls from '../Weburls/weburls';
import { Linecart } from './Linecart';

const NetWorth = () => {
  const sidebarRef = useRef(null);
  const inputFile = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [liabilityOpen, setLiabilityOpen] = useState(false);

  const [editAssets, setEditAssets] = useState(false);
  const [editLiability, setEditLiability] = useState(false);

  const [assetData, setAssetData] = useState([]);
  const [liabilityData, seLiabilityData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [assetsUuid, setAssetsUuid] = useState(null);
  const [liabilityUuid, setLiabilityUuid] = useState(null);

  let clientUuid = null;

  try {
    const clientUserUuidData = localStorage.getItem('client-userUuid');
    if (clientUserUuidData) {
      const parsedData = JSON.parse(clientUserUuidData);
      clientUuid = parsedData[0]?.userUuid;
    }
  } catch (error) {
    console.error('Error parsing client-userUuid data:', error);
  } 
  // const toggleSidebar = () => {
  //   $(sidebarRef.current).toggleClass('sidebarnew');
  // };
  const handleCsvFile = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleModalForm = () => {
    setModalOpen(true);
    setEditAssets(false);
  };

  const handleModalLiabilityForm = () => {
    setLiabilityOpen(true);
    setEditLiability(false);
  };

  const handleEditForm = (id) => {
    setAssetsUuid(id);
    setEditAssets(true);
    setModalOpen(true);
  };

  const handleEditLiability = (id) => {
    setLiabilityUuid(id);
    setEditLiability(true);
    setLiabilityOpen(true);
  };

  // Get Asset List:--------

  const fetchAssets = async () => {
    try {
      const response = await axios.get(`${weburls.Get_Assets}${clientUuid}`);

      if (response?.data?.assets?.length > 0) {
        setAssetData(response?.data?.assets);
        localStorage.setItem('assetsId', JSON.stringify(response?.data?.assets[0]?.assetsId));
      } else {
        console.log('Getting error');
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };

  const fetchLiability = async () => {
    try {
      const response = await axios.get(`${weburls.Get_Liability}${clientUuid}`);
      console.log('lia', response);
      if (response?.data?.liability?.length > 0) {
        seLiabilityData(response?.data?.liability);
      } else {
        console.log('Getting error');
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };
  useEffect(() => {
    fetchAssets();
    fetchLiability();
  }, []);

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            {/* <MainSidebar toggleSidebar={toggleSidebar} /> */}
            <div className="app-main__outer net-worth-main">
              <div className="row">
                <div className="col-md-6">
                  <div className="fs-3 bold big-head-new">Net worth</div>
                  <div className="fst-normal setting_text_color">
                    See what you own & what your owe to know your net worth.
                  </div>
                </div>
                <div className="col-md-6 right-section search-spacing">
                  <div className="setting_background_color right-section back-portal-btn">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <img src={Arrowleft} alt="srrowleft" /> Back to portal
                        </button>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12 col-lg-5 left-section total-bal-main-first-wrp">
                  <div className="total-balance-wrp">
                    <div className="balance-wrp-heading">
                      <h4 className="med-head">Current Net Worth</h4>
                      <h2 className="big-head"> $350,000</h2>
                    </div>
                    <div className="row mt-3 balance-wrp-sub-heading">
                      <div className="text-white med-new-font">
                        Assets: <span className="new-green">$550,000</span> | Liabilities:
                        <span className="text-danger new-light-red"> $200,000</span>
                      </div>
                      <div className="col-md-7 img-sec"></div>
                      <div className="col-md-5 right mt-5 full-breakdown-arrow">
                        <h4>
                          Full Breakdown <ArrowRight />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="net-worth-line-cart-canvas col-lg-7 col-md-12">
                  <div className="line-cart-net">
                    <div className="bar-cart-heading-wrp col-lg-12 col-md-4">
                      <div className="top-heading-canvas-price">
                        <h4 className="bold black">Net Worth Progress</h4>
                        <h5>$13,780</h5>
                      </div>
                      <h6>May 2022</h6>
                    </div>
                    <Linecart />
                  </div>
                </div>
              </div>
              <div className="row mt-5 assets-wrp">
                <h5>Your assets </h5>
                <div className="text-14px">These are the things you own.</div>
                <div className="col-lg-9 col-md-9 asset-wrp-first-side">
                  <div className="col-lg-12 new-table">
                    <div>
                      <div className="table-horizontal net-worth-table">
                        <div class="col-12 table-horizontal">
                          <table class="table-image new-table">
                            <thead>
                              <tr>
                                <th scope="col">Asset name</th>
                                <th scope="col">Value</th>
                                <th scope="col">Do you owe any money on this asset?</th>
                                <th scope="col">Image</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody className="space-table">
                              {loading ? (
                                <div className="no-data-found-td">
                                  <LoadingTxt />
                                </div>
                              ) : assetData?.length > 0 ? (
                                assetData &&
                                assetData.map((item, index) => {
                                  return (
                                    <tr className="cursor-pointer">
                                      <th>
                                        <div className="air-image new-img">
                                          <div className="img-box">
                                            <img src={HomeValue} alt="" />
                                          </div>
                                          <h3 className="table-content display">{item.assetsName}</h3>
                                        </div>
                                      </th>
                                      <td className="table-text-color">
                                        <div className="planner">{item.value}</div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner big-planner-new">{item.amount}</div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner position-relative" onClick={handleCsvFile}>
                                          <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                          <Upload className="upload-logo" />
                                        </div>
                                      </td>
                                      <td className="table-text-color" onClick={() => handleEditForm(item.assetsUuid)}>
                                        <Edit />
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <div style={   { width:"100% !important",display: "contents"}}>
                                  {' '}
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image new-img">
                                        <div className="img-box">
                                          <img src={HomeValue} alt="" />
                                        </div>
                                        <h3 className="table-content display">Home value</h3>
                                      </div>
                                    </th>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner big-planner-new"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner position-relative" onClick={handleCsvFile}>
                                        <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                        <Upload className="upload-logo" />
                                      </div>
                                    </td>
                                    <td>
                                      <Link to="#">
                                        <Edit />
                                      </Link>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image new-img">
                                        <div className="img-box">
                                          <img src={Car} alt="" />
                                        </div>
                                        <h3 className="table-content display">Car</h3>
                                      </div>
                                    </th>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner big-planner-new"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner position-relative" onClick={handleCsvFile}>
                                        <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                        <Upload className="upload-logo" />
                                      </div>
                                    </td>
                                    <td>
                                      <Link to="#">
                                        <Edit />
                                      </Link>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image new-img">
                                        <div className="img-box">
                                          <img src={Sharesis} alt="" />
                                        </div>
                                        <h3 className="table-content display">Shares value</h3>
                                      </div>
                                    </th>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner big-planner-new"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner position-relative" onClick={handleCsvFile}>
                                        <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                        <Upload className="upload-logo" />
                                      </div>
                                    </td>
                                    <td>
                                      <Link to="#">
                                        <Edit />
                                      </Link>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image new-img">
                                        <div className="img-box">
                                          <img src={HouseholdItem} alt="" />
                                        </div>
                                        <h3 className="table-content display">Household items</h3>
                                      </div>
                                    </th>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner big-planner-new"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner position-relative" onClick={handleCsvFile}>
                                        <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                        <Upload className="upload-logo" />{' '}
                                      </div>
                                    </td>
                                    <td>
                                      <Link to="#">
                                        <Edit />
                                      </Link>
                                    </td>
                                  </tr>
                                </div>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 left-section asset-wrp-second-side">
                  <div className="total-balance-wrp d-block saving-head">
                    <h4 className="text-white">Total Assets</h4>
                    <h2 className="big-head-new light-green-new"> $350,000</h2>
                    <div className="row mt-3">
                      <div className="text-white mt-2 this-week">
                        Home value: <span>45%</span>
                      </div>
                      <div className="text-white mt-2 this-week">Car: 45%</div>
                      <div className="text-white mt-2 this-week">
                        Shares value: <span>45%</span>
                      </div>
                      <div className="text-white mt-2 this-week">
                        Household items: <span>45%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-dashboard mt-2 ms-0 add-asset-btn-first">
                <Link to="">
                  <button className="comn-btn" onClick={handleModalForm}>
                    Add asset
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </Link>
              </div>
              <div className="row big-margin liability-wrp">
                <h5>Your liabilities </h5>
                <div className="text-14px">These are the things you own.</div>
                <div className="col-lg-9 col-md-9 liability-wrp-first-side">
                  <div>
                    <div className="table-horizontal ">
                      <div>
                        <div className="table-horizontal net-worth-table-second">
                          <div class="col-12 table-horizontal">
                            <table class="table-image new-table">
                              <thead>
                                <tr>
                                  <th scope="col">Liability name</th>
                                  <th scope="col">Value</th>
                                  <th scope="col">Link to an asset?</th>
                                  <th scope="col">Image</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody className="space-table">
                                {loading ? (
                                  <div className="no-data-found-td">
                                    <LoadingTxt />
                                  </div>
                                ) : liabilityData?.length > 0 ? (
                                  liabilityData &&
                                  liabilityData.map((item, index) => {
                                    return (
                                      <tr className="cursor-pointer">
                                        <th>
                                          <div className="air-image new-img">
                                            <div className="img-box">
                                              <img src={Mortgage} alt="" />
                                            </div>
                                            <h3 className="table-content display">{item.liabilityName}</h3>
                                          </div>
                                        </th>
                                        <td className="table-text-color">
                                          <div className="planner">{item.value}</div>
                                        </td>
                                        <td className="table-text-color">
                                          <div className="planner big-planner-new networth"></div>
                                        </td>
                                        <td className="table-text-color">
                                          <div className="planner position-relative" onClick={handleCsvFile}>
                                            <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                            <Upload className="upload-logo" />
                                          </div>
                                        </td>
                                        <td
                                          className="table-text-color"
                                          onClick={() => handleEditLiability(item.liabilityUuid)}
                                        >
                                          <Edit />
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <div style={{ width:"100% !important",display: "contents"}}>
                                    <tr className="cursor-pointer">
                                      <th>
                                        <div className="air-image new-img">
                                          <div className="img-box">
                                            <img src={Mortgage} alt="" />
                                          </div>
                                          <h3 className="table-content display">Mortgage</h3>
                                        </div>
                                      </th>
                                      <td className="table-text-color">
                                        <div className="planner"></div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner big-planner-new networth"></div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner position-relative" onClick={handleCsvFile}>
                                          <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                          <Upload className="upload-logo" />
                                        </div>
                                      </td>
                                      <td>
                                        <Link to="#">
                                          <Edit />
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr className="cursor-pointer">
                                      <th>
                                        <div className="air-image new-img">
                                          <div className="img-box">
                                            <img src={Creditcard} alt="" />
                                          </div>
                                          <h3 className="table-content display">Credit Card</h3>
                                        </div>
                                      </th>
                                      <td className="table-text-color">
                                        <div className="planner"></div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner big-planner-new"></div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner position-relative" onClick={handleCsvFile}>
                                          <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                          <Upload className="upload-logo" />
                                        </div>
                                      </td>
                                      <td>
                                        <Link to="#">
                                          <Edit />
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr className="cursor-pointer">
                                      <th>
                                        <div className="air-image new-img">
                                          <div className="img-box">
                                            <img src={Mortgage} alt="" />
                                          </div>
                                          <h3 className="table-content display">Personal loan</h3>
                                        </div>
                                      </th>
                                      <td className="table-text-color">
                                        <div className="planner"></div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner big-planner-new"></div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner position-relative" onClick={handleCsvFile}>
                                          <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                          <Upload className="upload-logo" />
                                        </div>
                                      </td>
                                      <td>
                                        <Link to="#">
                                          <Edit />
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr className="cursor-pointer">
                                      <th>
                                        <div className="air-image new-img">
                                          <div className="img-box">
                                            <img src={Mortgage} alt="" />
                                          </div>
                                          <h3 className="table-content display">Car loan</h3>
                                        </div>
                                      </th>
                                      <td className="table-text-color">
                                        <div className="planner"></div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner big-planner-new"></div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner position-relative" onClick={handleCsvFile}>
                                          <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                          <Upload className="upload-logo" />
                                        </div>
                                      </td>
                                      <td>
                                        <Link to="#">
                                          <Edit />
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr className="cursor-pointer">
                                      <th>
                                        <div className="air-image new-img">
                                          <div className="img-box">
                                            <img src={Laybuy} alt="" />
                                          </div>
                                          <h3 className="table-content display">Laybuy</h3>
                                        </div>
                                      </th>
                                      <td className="table-text-color">
                                        <div className="planner"></div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner big-planner-new"></div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner position-relative" onClick={handleCsvFile}>
                                          <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                          <Upload className="upload-logo" />
                                        </div>
                                      </td>
                                      <td>
                                        <Link to="#">
                                          <Edit />
                                        </Link>
                                      </td>
                                    </tr>
                                  </div>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 left-section liability-wrp-second-side">
                  <div className="total-balance-wrp d-block button-assets saving-head">
                    <h4 className="text-white">Total Liabilities</h4>
                    <h2 className="button-assets-text big-head-new">$180,000</h2>
                    <div className="row mt-3">
                      <div className="text-white mt-2 this-week">
                        Mortgage: <span>45%</span>
                      </div>
                      <div className="text-white mt-2 this-week">
                        Credit card: <span>45%</span>
                      </div>
                      <div className="text-white mt-2 this-week">
                        Personal loan: <span>45%</span>
                      </div>
                      <div className="text-white mt-2 this-week">
                        Car loan: <span>45%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-dashboard mt-3 ms-0 add-liability-btn-first">
                <Link to="">
                  <button className="comn-btn add-liability" onClick={handleModalLiabilityForm}>
                    Add liability
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpen === true && (
        <div>
          {editAssets === true ? (
            <EditAssets
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              fetchAssets={fetchAssets}
              assetsUuid={assetsUuid}
            />
          ) : (
            <AddAssets modalOpen={modalOpen} setModalOpen={setModalOpen} fetchAssets={fetchAssets} />
          )}
        </div>
      )}
      {liabilityOpen === true && (
        <div>
          {editLiability === true ? (
            <EditLiability
              liabilityOpen={liabilityOpen}
              setLiabilityOpen={setLiabilityOpen}
              liabilityUuid={liabilityUuid}
              fetchLiability={fetchLiability}
            />
          ) : (
            <AddLiability liabilityOpen={liabilityOpen} setLiabilityOpen={setLiabilityOpen} fetchLiability={fetchLiability}/>
          )}
        </div>
      )}
    </>
  );
};
export default NetWorth;

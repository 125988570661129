import React from "react";
import { useRef } from "react";
import Profile from "../../assests/images/icons/image.png";
import $ from "jquery";
import MainSidebar from "../MainSidebar";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assests/images/icons/arrow.svg";
import Arrowleft from "../../assests/images/icons/arrow-left.png";

const CashFlow = () => {
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass("sidebarnew");
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            {/*  <MainSidebar toggleSidebar={toggleSidebar} /> */}
            <div className="app-main__outer edit-cashflow-container-main">
              <div className="row top-nav">
                <div className="col-md-4 edit-cashflow-title-heading">
                  <div className="bold big-head-new">Edit Cashflow</div>
                  <div className="fst-normal setting_text_color">
                    See a forecast of where your moneys coming in and going out.
                  </div>
                </div>
                <div className="col-md-4 view-calendar-cashflow">
                  <div className="edit-btn-col">
                    <div className="edit-btn" href-="#">
                      <div className="left">
                        <h4>View Cashflow Calendar</h4>
                        <p style={{ color: "#04baff" }}>
                          <Link to="/Clients/Cashflowcalender">
                            Get the full overview.
                          </Link>
                        </p>
                      </div>

                      <div className="right">
                        <a href="#">
                          <Arrow />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 right-section back-portal-edit-cashflow">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <img src={Arrowleft} alt="srrowleft" /> Back to portal
                        </button>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row middle-seciton edit-cashflow-week-month-wrp">
                <div className="col-md-12 col-lg-12">
                  <ul
                    class="nav nav-pills mb-3 budget-tabs img-back tabs-background-color"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Weekly
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Monthly
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        Quarterly
                      </button>
                    </li>
                  </ul>
                </div>
                <div
                  class="tab-content col-xl-12 col-lg-12 col-sm-12 edit-cashflow-new-table-section" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row mt-3">
                      <div className="col-xl-12 top-head-section">
                        <h3 className="bold">Your weekly income after tax</h3>
                        <p>
                          Regular income is what you consistently get paid after
                          tax each week.
                        </p>
                      </div>
                      <div className="col-lg-12 new-table">
                        <div>
                          <div className="table-horizontal first-table-edit-cashflow">
                            <div class="col-12 table-horizontal">
                              <table class="table-image new-table income-after ">
                                <thead>
                                  <tr>
                                    <th scope="col">Income type</th>
                                    <th scope="col">This week</th>
                                    <th scope="col">Week 2</th>
                                    <th scope="col">Week 3</th>
                                    <th scope="col">Week 4</th>
                                    <th scope="col">Week 5</th>
                                    <th scope="col">Week 6</th>
                                    <th scope="col">Week 7</th>
                                  </tr>
                                </thead>
                                <tbody className="space-table">
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Balance in spendings
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody className="space-table">
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Pay
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody className="space-table">
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Rental income
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody className="space-table">
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Investments
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody className="space-table">
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Other
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody className="space-table">
                                  <tr className="cursor-pointer income-type-background">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Total income
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="profile-dashboard mt-3">
                          <Link to="">
                            <button className="comn-btn">
                              Add Income <i class="fa-solid fa-plus"></i>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row expenses-wrp">
                      <div className="col-xl-12 top-head-section">
                        <h3 className="bold">Your weekly expenses & transfers</h3>
                        <p>
                          Your reoccurring expenses are what bills you pay each
                          week.
                        </p>
                      </div>
                      <div className="col-lg-12 new-table">
                        <div>
                          <div className="table-horizontal first-table-edit-cashflow">
                            <div class="col-12 table-horizontal">
                              <table class="table-image new-table income-after ">
                                <thead>
                                  <tr>
                                    <th scope="col">Expense type</th>
                                    <th scope="col">This week</th>
                                    <th scope="col">Week 2</th>
                                    <th scope="col">Week 3</th>
                                    <th scope="col">Week 4</th>
                                    <th scope="col">Week 5</th>
                                    <th scope="col">Week 6</th>
                                    <th scope="col">Week 7</th>
                                  </tr>
                                </thead>
                                <tbody className="space-table">
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Food
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Power & Wifi
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Insurance
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Rent
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Car
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Phone plan
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Rates
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Clothes
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Eating out
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Gym
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Transfer to savings
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner"></div>
                                    </td>
                                  </tr>
                                  <tr className="cursor-pointer income-after-background">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Total expenses & transfers
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner bg-white"></div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="profile-dashboard mt-3">
                          <Link to="">
                            <button className="comn-btn add-expense">
                              Add expense <i class="fa-solid fa-plus"></i>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row expenses-wrp">
                      <div className="col-xl-12 top-head-section">
                        <h3 className="bold">Your money left over</h3>
                        <p>
                          This shows what money you have left over after
                          receiving your income and paying your expenses
                        </p>
                      </div>
                      <div className="col-lg-12 new-table third-table-edit-cashflow">
                        <div>
                          <div className="table-horizontal">
                            <div class="col-12 table-horizontal">
                              <table class="table-image new-table income-after ">
                                <thead>
                                  <tr>
                                    <th scope="col"></th>
                                    <th scope="col">This week</th>
                                    <th scope="col">Week 2</th>
                                    <th scope="col">Week 3</th>
                                    <th scope="col">Week 4</th>
                                    <th scope="col">Week 5</th>
                                    <th scope="col">Week 6</th>
                                    <th scope="col">Week 7</th>
                                  </tr>
                                </thead>
                                <tbody className="space-table">
                                  <tr className="cursor-pointer">
                                    <th>
                                      <div className="air-image">
                                        <h3 className="table-content display">
                                          Balance left over
                                        </h3>
                                      </div>
                                    </th>

                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-type-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-after-background"></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-after-background "></div>
                                    </td>
                                    <td className="table-text-color">
                                      <div className="planner income-after-background "></div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CashFlow;

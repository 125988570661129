import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Airnewzealand from '../../assests/images/icons/AirNewZealand.png';
import Activebutton from '../Buttons/Activebutton';
import Inactivebutton from '../Buttons/Inactivebutton';
import Notactivatedbutton from '../Buttons/Notactivatedbutton';
import './superAdmin.css';
import SuperAdminHeader from './SuperAdminHeader';
import SuperAdminSidebar from './SuperAdminSidebar';

const SuperAdminDashboard = () => {
  const [active, setActive] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);

  const activeChange = () => {
    setActive(true);
  };

  return (
    <>
      {/* {active ? (
        <Activepage />
      ) : ( */}
      <div className="container-fluid">
        <SuperAdminHeader />
        <div className="appmain">
          <SuperAdminSidebar />
          <div className="app-main__outer main-front-dashboard-container">
            <div className="fs-3 big-head-new">
              Good Morning,{' '}
              <span className="text-jeremy">
                Admin
                <div className="hand-img">👋</div>
              </span>
            </div>
            <div className="fst-normal light-grey">Here's your dashboard of super admin using BudgetBuddie.</div>
            <div className="row mt-3 dashboard-wrp">
              <div className="col-md-12 col-xl-3">
                <div className="total-clients">
                  <div className="bold black first-heading-total sub-head-text mb-2">Total companies</div>
                  <div className="margin-nu d-flex">
                    <h3 className="number-heading acc-num-head">5000</h3>
                    <div className="percentage bold">+15%</div>
                  </div>
                  <div className="text-client">Companies using BudgeBuddie</div>
                </div>
              </div>
              <div className="col-md-12 col-xl-4">
                <div className="total-clients">
                  <div className="bold black sub-head-text mb-2">Total advisors</div>
                  <div className="margin-amount">
                    <h2 className="number-heading second-num-head">5000</h2>
                  </div>
                  <div className="d-flex justify-content-between mt-5">
                    <div className="user-wrp">
                      <div className="amount-number acc-num-head">4700</div>

                      <Activebutton />
                    </div>
                    <div className="user-wrp">
                      <div className="amount-number acc-num-head">200</div>

                      <Inactivebutton />
                    </div>
                    <div className="user-wrp">
                      <div className="amount-number acc-num-head">100</div>

                      <Notactivatedbutton />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-2">
                <div className="total-clients">
                  <div className="bold black text-center sub-head-text">Total plans</div>
                  <div className="share-number fs-3">5</div>
                  <div className="view-plans-btn-div">
                    {' '}
                    <Link to="/Plans">
                      <button className="view-plans-btn">View plans </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xl-3">
                <div className="total-clients">
                  <div className="bold black sub-head-text">Top Company</div>
                  <div className="air-new group-name-tab air-new-zealand-tab">
                    <div className="air-image">
                      <img src={Airnewzealand} alt="Air" />
                    </div>
                    Air New Zealand
                  </div>
                  <div className="air-new-active active">
                    <div className="amount-number acc-num-head">4700</div>
                    <Activebutton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};
export default SuperAdminDashboard;

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import weburls from '../../Weburls/weburls';
import '../AddIncome/addIncome.css';

const schema = yup.object().shape({
  expenseType: yup.string().required('*Please enter expense type'),
  expenseAmount: yup
    .string()
    .required('*Please enter expense amount')
    .matches(/^[0-9]+$/, '*Please enter number only'),
  howOften: yup.string().required('*Please select expense frequency'),
  lastPaymentDate: yup.string().required('*Please select income date'),
});

const ReoccuringExpense = ({ modalOpen, fetchExpense, setModalOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [howOftenList, setOftenList] = useState([]);

  let clientUuid = null;

  try {
    const clientUserUuidData = localStorage.getItem('client-userUuid');
    if (clientUserUuidData) {
      const parsedData = JSON.parse(clientUserUuidData);
      clientUuid = parsedData[0]?.userUuid;
    }
  } catch (error) {
    console.error('Error parsing client-userUuid data:', error);
  }

  const [expenseData, setExpenseData] = useState({
    userUuid: '',
    expenseType: '',
    expenseAmount: '',
    howOften: '',
    lastPaymentDate: '',
    accountAssociated: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const uuId = clientUuid;
    setExpenseData({ ...expenseData, userUuid: uuId, [name]: value });
  };

  const handleClose = () => {
    setModalOpen(!modalOpen);
  };

  const onSubmitHandler = async () => {
    try {
      const response = await axios.post(weburls.Add_expense, expenseData);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          setModalOpen(false);
          fetchExpense();
        }, 3000);
      } else if (response.status === 404) {
        toast.error('Something went wrong please try again.', {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error('error', error);
      setModalOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
    reset();
  };

  const howOften = async () => {
    try {
      const data = await axios.get(weburls.How_Often);
      setOftenList(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    howOften();
  }, []);

  return (
    <div className={`modal-background ${modalOpen ? 'open-modal' : ''} `}>
      <div className="modal-content">
        <button onClick={handleClose} className="close-modal-button">
          <i class="fa fa-times"></i>
        </button>
        <div className="setting_background_color">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black big-top-heading">Add your expense</span>
            </div>
          </div>
          <hr />
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row add-expense-wrp">
              <div className="col-md-12 col-sm-12 col-xl-5 mb-3">
                <h5 className="bold black heading-new">Expense details</h5>
                <div className="setting_text_color">This will be displayed on your profile.</div>
              </div>
              <div className="col-md-12 col-sm-12 col-xl-7">
                <div className="row">
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Expense Type :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="expenseType"
                        class="form-control input_border"
                        placeholder="Expense type"
                        {...register('expenseType', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.expenseType?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Expense Amount :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="expenseAmount"
                        class="form-control input_border"
                        placeholder="Expense amount"
                        {...register('expenseAmount', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.expenseAmount?.message}</p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      How Often :
                    </label>
                    <select
                      class="form-select input_border mb-3"
                      name="howOften"
                      {...register('howOften', {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                    >
                      <option value="" disabled={true}>
                        How often?
                      </option>

                      {Array.isArray(howOftenList) && howOftenList.length > 0
                        ? howOftenList &&
                          howOftenList?.map((item) => {
                            return (
                              <option key={item.howOftenId} value={item.howOftenName}>
                                {item.howOftenName}
                              </option>
                            );
                          })
                        : 'new'}
                    </select>
                    <p className="error-message">{errors.howOften?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Last Payment Date :
                    </label>
                    <div class="input-group mb-3 date-picker-div">
                      <input
                        type="date"
                        id="date"
                        class="form-control input_border "
                        placeholder="Last payment date"
                        name="lastPaymentDate"
                        {...register('lastPaymentDate', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.lastPaymentDate?.message}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-xl-12 col-sm-12">
                    <div className="row mt-2 plan-wrp">
                      <div className="col-md-12 col-xl-12">
                        <label htmlFor="event-start" className="mb-1 labels-text">
                          Account associated :
                        </label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            name="accountAssociated"
                            class="form-control input_border"
                            placeholder="Account associated"
                            {...register('accountAssociated', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        <p className="error-message">{errors?.accountAssociated?.message}</p>
                      </div>
                    </div>
                  </div>
                  <div class="button modal-popup-btn-wrp">
                    <button type="submit" class="comn-btn common-btn-style">
                      Save expense
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReoccuringExpense;

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import weburls from '../../Weburls/weburls';
import '../AddIncome/addIncome.css';

const schema = yup.object().shape({
  expenseName: yup.string().required('*Please enter expense name'),
  transferAmount: yup.string().required('*Please enter transfer amount'),
  lastTransferDate: yup.string().required('*Please enter last transfer date'),
  howOften: yup.string().required('*Please enter transfer frequency'),
  transferFrom: yup.string().required('*Please enter transfer from'),
  transferTo: yup.string().required('*Please enter transfer to'),
});

const EditAccountTransfer = ({ modalOpen, fetchAccountTransfer, setModalOpen, transferUuid }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [accountData, setAccountData] = useState({
    userUuid: '',
    transferUuid: transferUuid,
    expenseType: '',
    transferAmount: '',
    lastTransferDate: '',
    howOften: '',
    transferFrom: '',
    transferTo: '',
  });

  let clientUuid = null;
  const [howOftenList, setOftenList] = useState([]);

  try {
    const clientUserUuidData = localStorage.getItem('client-userUuid');
    if (clientUserUuidData) {
      const parsedData = JSON.parse(clientUserUuidData);
      clientUuid = parsedData[0]?.userUuid;
    }
  } catch (error) {
    console.error('Error parsing client-userUuid data:', error);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const uuId = clientUuid;
    setAccountData((prev) => ({ ...prev, userUuid: uuId, [name]: value }));
  };

  const handleClose = () => {
    setModalOpen(!modalOpen);
  };

  // Get tarnsfer account by Id:-
  const fetchData = () => {
    axios
      .get(`${weburls.Get_Account_Transfer_By_Id}${transferUuid}`)
      .then((response) => {
        setAccountData(response?.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const howOften = async () => {
    try {
      const data = await axios.get(weburls.How_Often);
      setOftenList(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    howOften();
  }, []);
  const [formError, setformError] = useState('');

  const validateInput = (fieldName, value) => {
    if (!value) {
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`, // Generate a custom error message based on the field name
      }));
    } else {
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '',
      }));
    }
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    const updatedData = {
      ...accountData,
      expenseType: event.target.expenseType.value,
      transferAmount: event.target.transferAmount.value,
      lastTransferDate: event.target.lastTransferDate.value,
      howOften: event.target.howOften.value,
      transferFrom: event.target.transferFrom.value,
      transferTo: event.target.transferTo.value,
    };
    try {
      const response = await axios.put(weburls.Update_Account_Transfer, updatedData);
      if (response.status === 200) {
        toast.success(response.data.message || 'Data update successfully', {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          setModalOpen(false);
          fetchAccountTransfer();
        }, 3000);
      } else if (response.status === 500) {
        toast.error('Something went wrong please try again.');
      }
    } catch (error) {
      console.error('error', error);
      setModalOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
    reset();
  };
  useEffect(() => {
    fetchAccountTransfer()
},[])

  const handleDeleteTransfer = async () => {
    try {
      const response = await axios.delete(`${weburls.Delete_Account_Transfer}${clientUuid}/${transferUuid}`);
      if (response.status === 200) {
        toast.success(response?.data?.message || 'Data delete successfully', {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          setModalOpen(false);
          fetchAccountTransfer();
        }, 3000);
      } else if (response.status === 500) {
        toast.error('Something went wrong please try again.');
      } else {
        console.error('Delete request failed please try again', response);
      }
    } catch (error) {
      console.error('Error:', error);
      setModalOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };

  return (
    <div className={`modal-background ${modalOpen ? 'open-modal' : ''} `}>
      <div className="modal-content">
        <button onClick={handleClose} className="close-modal-button">
          <i class="fa fa-times"></i>
        </button>
        <div className="setting_background_color">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black big-top-heading">Edit transfer your account</span>
            </div>
          </div>
          <hr />
          <form onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xl-4 mb-3">
                <h5 className="bold black heading-new">Edit account details</h5>
                <div className="setting_text_color">This will be displayed on your profile.</div>
              </div>
              <div className="col-md-12 col-sm-12 col-xl-8">
                <div className="row">
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Expense Name :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="expenseType"
                        defaultValue={accountData?.expenseType}
                        class="form-control input_border"
                        placeholder="Expense name"
                        onChange={(e) => validateInput('expenseName', e.target.value)}
                      />
                    </div>
                    {formError.expenseName && <p className="error-message">{formError.expenseName}</p>}
                  </div>
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Transfer Amount :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="transferAmount"
                        defaultValue={accountData?.transferAmount}
                        class="form-control input_border"
                        placeholder="Transfer amount"
                        onChange={(e) => validateInput('transferAmount', e.target.value)}
                      />
                    </div>
                    {formError.transferAmount && <p className="error-message">{formError.transferAmount}</p>}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      How Often :
                    </label>
                    <select
                      class="form-select input_border mb-3"
                      name="howOften"
                      defaultValue={accountData?.howOften}
                      onChange={(e) => validateInput('howOften', e.target.value)}
                    >
                      <option value="" disabled={true}>
                        How often?
                      </option>

                      {Array.isArray(howOftenList) && howOftenList.length > 0
                        ? howOftenList &&
                          howOftenList?.map((item) => {
                            return (
                              <option key={item.howOftenId} value={item.howOftenName}>
                                {item.howOftenName}
                              </option>
                            );
                          })
                        : 'new'}
                    </select>{' '}
                    {formError.howOften && <p className="error-message">{formError.howOften}</p>}
                  </div>
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Last Transfer Date :
                    </label>
                    <div class="input-group mb-3 date-picker-div">
                      <input
                        type="date"
                        id="date"
                        class="form-control input_border "
                        defaultValue={accountData?.lastTransferDate?.substring(0, 10)}
                        placeholder="Last payment date"
                        name="lastTransferDate"
                        onChange={(e) => validateInput('lastTransferDate', e.target.value)}
                      />
                    </div>
                    {formError.lastTransferDate && <p className="error-message">{formError.lastTransferDate}</p>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-xl-12 col-sm-12">
                    <div className="row mt-2 plan-wrp">
                      <div className="col-md-12 col-xl-6">
                        <label htmlFor="event-start" className="mb-1 labels-text">
                          Transfer From :
                        </label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            name="transferFrom"
                            class="form-control input_border"
                            defaultValue={accountData?.transferFrom}
                            placeholder="Transfer from"
                            onChange={(e) => validateInput('transferFrom', e.target.value)}
                          />
                        </div>
                        {formError.transferFrom && <p className="error-message">{formError.transferFrom}</p>}
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <label htmlFor="event-start" className="mb-1 labels-text">
                          Transfer To :
                        </label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            name="transferTo"
                            class="form-control input_border"
                            defaultValue={accountData?.transferTo}
                            placeholder="Transfer to"
                            onChange={(e) => validateInput('transferTo', e.target.value)}
                          />
                        </div>
                        {formError.transferTo && <p className="error-message">{formError.transferTo}</p>}
                      </div>
                    </div>
                  </div>
                  <div class="button modal-popup-btn-wrp">
                    <button type="submit" class="comn-btn common-btn-style">
                      Save details
                    </button>
                    <button type="button" className="comn-btn delete-btn" onClick={handleDeleteTransfer}>
                      Delete income
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAccountTransfer;

import React from "react";
import { Bar } from "react-chartjs-2";

const Smallcart = () => {
  return (
    <>
      <Bar
        data={{
          // Name of the variables on x-axies for each bar
          labels: ["Pay", "Rental", "Invest", "Other", "Total"],
          datasets: [
            {
              // Label for bars
              label: "",
              // Data or value of your each variable
              data: [38, 75, 58, 65, 100],
              // Color of each bar
              backgroundColor: [
                "rgba(0, 170, 255, 0.16)",
                "rgba(254, 48, 48, 0.16)",
                "rgba(197, 55, 200, 0.16)",
                "rgba(250, 232, 72, 0.16)",
                "#40AF66",
              ],
              // Border color of each bar
              borderRadius: 13,
            },
          ],
        }}
        // Height of graph
        height={105}
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },

            y: {
              grid: {
                display: false,
                drawBorder: false, // <-- this removes y-axis line
                lineWidth: 0,
              },
              ticks: {
                display: false,
              },
              border: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
              },
            },
          },
        }}
      />
    </>
  );
};
export default Smallcart;

import React from 'react';
import Kiwisaver from '../../assests/images/icons/Kiwisaver.png';
import Mortage from '../../assests/images/icons/Mortgage.png';
import Sheris from '../../assests/images/icons/Sharesies.png';
import { LinecartNetworth } from './LinecartNetworth';

const Networthprogress = () => {
  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12 bar-cart col-xl-3 col-sm-12 col-lg-12 net-worth-progress-side">
          <div className="col-md-12 left">
            <h5>Net worth Progress</h5>
          </div>
          <div className="line-cart-net">
            <div className="bar-cart-heading-wrp col-lg-12 col-md-12">
              <div className="top-heading-canvas-price">
                <h4 className="bold black">Net Worth</h4>
                <h5>$13,780</h5>
              </div>
              <h6>May 2022</h6>
            </div>
            <div className="linecartbottom">
              <LinecartNetworth />
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-9 col-sm-12 bar-cart account-balances-side">
          <div className="recent-see-all">
            {' '}
            <div className="col-md-6 mb-4 left title-heading">
              <h5>Account Balances</h5>
            </div>
            <div className="col-md-6 right-section right see-all">See all</div>
          </div>

          <div className="col-md-12 col-lg-12">
            <div className="saving-acc-wrp row">
              <div className="col-md-4 col-lg-4 col-sm-12 col-xl-3 cmn-blck">
                <div className="blkc">
                  <div className="left-section">
                    <div className="saving-head">
                      <h4>Savings Account</h4>
                    </div>
                    <div className="saving-amt">
                      <h3 className="number-heading">$12000.45</h3>
                    </div>
                  </div>
                  <div className="right-section col-lg-4 col-xl-4">
                    <div class="img">
                      <img src={Mortage} alt="Mortage_img" />
                    </div>
                  </div>

                  <div className="saving-head">
                    <h4>Account number</h4>
                    <h4>1234 5678 1214 4758</h4>
                  </div>
                </div>
                <div className="bottom-wrp">
                  <div className="saving-head">
                    <h5>Name</h5>
                  </div>
                  <h4>Tim Peterson</h4>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 col-xl-3 cmn-blck">
                <div className="blkc">
                  <div className="left-section">
                    <div className="saving-head">
                      <h4>Spendings Account</h4>
                    </div>
                    <div className="saving-amt">
                      <h3 className="number-heading">$350.50</h3>
                    </div>
                  </div>
                  <div className="right-section col-lg-4 col-xl-4">
                    <div class="img">
                      <img src={Mortage} alt="Mortage_img" />
                    </div>
                  </div>

                  <div className="saving-head">
                    <h4>Account number</h4>
                    <h4>1234 5678 1214 4758</h4>
                  </div>
                </div>
                <div className="bottom-wrp">
                  <div className="saving-head">
                    <h5>Name</h5>
                  </div>
                  <h4>Tim Peterson</h4>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 col-xl-3 pink-block cmn-blck">
                <div className="blkc">
                  <div className="left-section">
                    <div className="saving-head">
                      <h4>Sharesies</h4>
                    </div>
                    <div className="saving-amt">
                      <h3 className="number-heading">$890.45</h3>
                    </div>
                  </div>
                  <div className="right-section col-lg-4 col-xl-4">
                    <div class="img">
                      <img src={Sheris} alt="Mortage_img" />
                    </div>
                  </div>

                  <div className="saving-head">
                    <h4>Account number</h4>
                    <h4>1234 5678 1214 4758</h4>
                  </div>
                </div>
                <div className="bottom-wrp">
                  <div className="saving-head">
                    <h5>Name</h5>
                  </div>
                  <h4>Tim Peterson</h4>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 col-xl-3 purple-block">
                <div className="blkc">
                  <div className="left-section">
                    <div className="saving-head">
                      <h4>Kiwisaver</h4>
                    </div>
                    <div className="saving-amt">
                      <h3 className="number-heading">$15,657.34</h3>
                    </div>
                  </div>
                  <div className="right-section col-lg-4 col-xl-4">
                    <div class="img">
                      <img src={Kiwisaver} alt="Mortage_img" />
                    </div>
                  </div>

                  <div className="saving-head">
                    <h4>Account number</h4>
                    <h4>1234 5678 1214 4758</h4>
                  </div>
                </div>
                <div className="bottom-wrp">
                  <div className="saving-head">
                    <h5>Name</h5>
                  </div>
                  <h4>Tim Peterson</h4>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 col-xl-3 mt-3">
                <div className="blkc">
                  <div className="left-section">
                    <div className="saving-head">
                      <h4>Savings Account</h4>
                    </div>
                    <div className="saving-amt">
                      <h3 className="number-heading">$12000.45</h3>
                    </div>
                  </div>
                  <div className="right-section col-lg-4 col-xl-4">
                    <div class="img">
                      <img src={Mortage} alt="Mortage_img" />
                    </div>
                  </div>

                  <div className="saving-head">
                    <h4>Account number</h4>
                    <h4>1234 5678 1214 4758</h4>
                  </div>
                </div>
                <div className="bottom-wrp">
                  <div className="saving-head">
                    <h5>Name</h5>
                  </div>
                  <h4>Tim Peterson</h4>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 col-xl-3 mt-3">
                <div className="blkc">
                  <div className="left-section">
                    <div className="saving-head">
                      <h4>Spendings Account</h4>
                    </div>
                    <div className="saving-amt">
                      <h3 className="number-heading">$350.50</h3>
                    </div>
                  </div>
                  <div className="right-section col-lg-4 col-xl-4">
                    <div class="img">
                      <img src={Mortage} alt="Mortage_img" />
                    </div>
                  </div>
                  <div className="saving-head">
                    <h4>Account number</h4>
                    <h4>1234 5678 1214 4758</h4>
                  </div>
                </div>
                <div className="bottom-wrp">
                  <div className="saving-head">
                    <h5>Name</h5>
                  </div>
                  <h4>Tim Peterson</h4>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 col-xl-3 pink-block mt-3">
                <div className="blkc">
                  <div className="left-section">
                    <div className="saving-head">
                      <h4>Sharesies</h4>
                    </div>
                    <div className="saving-amt">
                      <h3 className="number-heading">$890.45</h3>
                    </div>
                  </div>
                  <div className="right-section col-lg-4 col-xl-4">
                    <div class="img">
                      <img src={Sheris} alt="Mortage_img" />
                    </div>
                  </div>
                  <div className="saving-head">
                    <h4>Account number</h4>
                    <h4>1234 5678 1214 4758</h4>
                  </div>
                </div>
                <div className="bottom-wrp">
                  <div className="saving-head">
                    <h5>Name</h5>
                  </div>
                  <h4>Tim Peterson</h4>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 col-xl-3 purple-block mt-3">
                <div className="blkc">
                  <div className="left-section">
                    <div className="saving-head">
                      <h4>Kiwisaver</h4>
                    </div>
                    <div className="saving-amt">
                      <h3 className="number-heading">$15,657.34</h3>
                    </div>
                  </div>
                  <div className="right-section col-lg-4 col-xl-4">
                    <div class="img">
                      <img src={Kiwisaver} alt="Mortage_img" />
                    </div>
                  </div>
                  <div className="saving-head">
                    <h4>Account number</h4>
                    <h4>1234 5678 1214 4758</h4>
                  </div>
                </div>
                <div className="bottom-wrp">
                  <div className="saving-head">
                    <h5>Name</h5>
                  </div>
                  <h4>Tim Peterson</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Networthprogress;

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 10,
      right: 10,
      bottom: 10,
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'April 2022',
      align: 'start',
      color: 'black',
      font: {
        size: 20,
      },
    },

    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      stacked: true,
      border: {
        display: false,
      },
    },
    y: {
      max: 60,
      ticks: {
        display: false,
      },
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
      },
      border: {
        display: false,
      },
    },
  },
};

const labels = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Income',
      data: [12, 14, 25, 22, 15, 17, 21, 25, 18, 22, 35, 17, 22, 25, 22, 15],
      backgroundColor: '#469b88',
      minBarLength: 10,
    },
    {
      label: 'Expenses',
      data: [12, 10, 12, 12, 23, 17, 11, 15, 11, 14, 11, 12, 12, 15, 11, 12, 12, 11, 10],
      backgroundColor: '#ff8585',
    },
  ],
};

export function Cart() {
  return (
    <div className="car-wrp">
      <Bar options={options} data={data} className="barcart" />
    </div>
  );
}

import $ from 'jquery';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Backarrow } from '../../assests/images/icons/Arrow_rleft.svg';
import Camera from '../../assests/images/icons/camera.png';
import CountdownImg from '../../assests/images/icons/Countdown.png';
import Profile from '../../assests/images/icons/image.png';
import Countdown from '../../assests/images/sprites/Countdown.png';
import MainSidebar from '../MainSidebar';

const Merchantdetails = () => {
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };
  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            {/* <MainSidebar toggleSidebar={toggleSidebar} /> */}
            <div className="app-main__outer merchant-details-wrapp">
              <div className="row header-row">
                <div className="col-lg-4 col-md-12 top-heading-merchant">
                  <div className="fs-3 bold big-head-new">Merchant details</div>
                  <div className="fst-normal setting_text_color">
                    Group your spending categories to get an more confidence with your expenses
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 text-center back-to-merchants-wrp">
                  <div className="edit-btn-col">
                    <Link to="/Merchats">
                      <div className="edit-btn" href-="#">
                        <div class="left-arrow">
                          <Link to="/Merchats">
                            <Backarrow />
                          </Link>
                        </div>
                        <div className="right">
                          <h4 className="bold">Back to Merchants</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 right-section search-spacing back-portal-merchant-detail">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row merchats-detail-box">
                <div className="col-lg-12">
                  <div className="d-inline-block merchant-wrp ">
                    <img src={Countdown} alt="" className="count-down-img" />
                    <div className="small-icons-camera">
                      <img src={Camera} alt="camera-icon" />
                    </div>
                  </div>
                  <div className="bold heading mb-2">Countdown</div>
                  <div>
                    <span className="count-number">$150.65</span> | 1 Transaction
                  </div>
                  <div className="merchats-detail-select big-head-select">
                    <select class="form-select merchats-select">
                      <option selected>Groceries</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-3 col-md-12 mb-3">
                  <div className="smal-trans bold black">
                    <h5>Your spend history</h5>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 mt-3">
                      <div className="your-spend-box">
                        <div className="img-box">
                          <img src={CountdownImg} alt="" className="count-down-img" />
                        </div>
                        <div className="this-week">This week</div>
                        <div className="this-week-number">$350.78</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 mt-3">
                      <div className="your-spend-box">
                        <div className="img-box">
                          <img src={CountdownImg} alt="" className="count-down-img" />
                        </div>
                        <div className="this-week">This month</div>
                        <div className="this-week-number">$350.78</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 mt-3">
                      <div className="your-spend-box">
                        <div className="img-box">
                          <img src={CountdownImg} alt="" className="count-down-img" />
                        </div>
                        <div className="this-week">This quarter</div>
                        <div className="this-week-number">$350.78</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 mt-3">
                      <div className="your-spend-box">
                        <div className="img-box">
                          <img src={CountdownImg} alt="" className="count-down-img" />
                        </div>
                        <div className="this-week">This year</div>
                        <div className="this-week-number">$350.78</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="smal-trans bold black">
                    <h5>Recent Transactions</h5>
                  </div>
                  <div className="row table-horizontal">
                    <div className="col-lg-12 ">
                      <div className="merchant-box mt-3 transactions-table table-transaction merchant-detail-table">
                        <div className="d-flex">
                          <div className="img-box">
                            <img src={CountdownImg} alt="" />
                          </div>
                          <div className="ms-3">
                            <div className="text-merchant bold">Countdown New Lynn</div>
                            <div className="setting_text_color content">Groceries</div>
                          </div>
                        </div>
                        <div className="transactions-text bold">19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am</div>
                        <div className="transactions-text pending">Pending</div>
                        <div className="this-week-number light-red-trans">-$32.00</div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="merchant-box mt-3 transactions-table table-transaction merchant-detail-table">
                        <div className="d-flex">
                          <div className="img-box">
                            <img src={CountdownImg} alt="" />
                          </div>
                          <div className="ms-3">
                            <div className="text-merchant bold">Countdown Spotswood</div>
                            <div className="setting_text_color content">Groceries</div>
                          </div>
                        </div>
                        <div className="transactions-text bold">19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am</div>
                        <div className="transactions-text pending">Pending</div>
                        <div className="this-week-number light-red-trans">-$32.00</div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="merchant-box mt-3 transactions-table table-transaction merchant-detail-table">
                        <div className="d-flex">
                          <div className="img-box">
                            <img src={CountdownImg} alt="" />
                          </div>
                          <div className="ms-3">
                            <div className="text-merchant bold">Countdown Highbrook</div>
                            <div className="setting_text_color content">Groceries</div>
                          </div>
                        </div>
                        <div className="transactions-text bold">19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am</div>
                        <div className="transactions-text pending">Pending</div>
                        <div className="this-week-number light-red-trans">-$32.00</div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="merchant-box mt-3 transactions-table table-transaction merchant-detail-table">
                        <div className="d-flex">
                          <div className="img-box">
                            <img src={CountdownImg} alt="" />
                          </div>
                          <div className="ms-3">
                            <div className="text-merchant bold">Countdown Lower Hutt</div>
                            <div className="setting_text_color content">Groceries</div>
                          </div>
                        </div>
                        <div className="transactions-text bold">19/04/2022 &nbsp;&nbsp; | &nbsp;&nbsp;10:50am</div>
                        <div className="transactions-text pending">Pending</div>
                        <div className="this-week-number light-red-trans">-$32.00</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Merchantdetails;

import React from 'react';
import { Link } from 'react-router-dom';
import Ami from '../../../assests/images/icons/Ami insurance.png';
import Bleached from '../../../assests/images/icons/Bleached Cafe.png';
import Booking from '../../../assests/images/icons/bookin.png';
import ContactEnergy from '../../../assests/images/icons/contact-energy.png';
import Profile from '../../../assests/images/icons/image.png';
import { ReactComponent as People } from '../../../assests/images/icons/People.svg';
import Plus from '../../../assests/images/icons/plus.png';
import MainSidebar from '../../MainSidebar';
import Smallcart from '../Smallcart/Smallcart';
import './addwidgets.css';
import Bigpiechart from './Bigpichart';
import PieChartWidgetExpense from './PieChartWidgetExpense';
import Widgetpiechart from './Widgetpiechart';

const Addwidgets = () => {
  const ExpenseData = [
    { label: 'Food', value: 166.536, width: 40 },
    { label: 'Power/Wifi', value: 115.609, width: 40 },
    { label: 'Insurance', value: 154.168, width: 40 },
    { label: 'Mortgage', value: 115.609, width: 40 },
    { label: 'Car', value: 137.6, width: 40 },
    { label: 'Rates', value: 192, width: 40 },
    { label: 'Eating Out', value: 166.536, width: 40 },
    { label: 'Clothes', value: 100.562, width: 40 },
  ];
  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            <div className="app-main__outer client-dashboard-main-wrapper">
              <div className="row">
                <div className="col-md-6 top-head">
                  <div className="big-head-new">
                    <h3 className="bold big-head-new">Add Widgets</h3>
                    <p className="fst-normal setting_text_color">
                      Need more insights into your finances? Add more widgets here.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 right-section dashboard-inner-back-to-portal">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link className="comn-btn" to="/Clients/Activepage">
                        <i class="fa-solid fa-arrow-left"></i> Back to portal
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-start">
                <div className="col-xl-10 mt-5">
                  <div className="d-flex justify-content-between row">
                    <div className="col-lg-4 left-block-widgets">
                      <div className="head-left d-flex justify-content-between">
                        <h3 className="filter-trans mb-0">Goals</h3>
                        <div className="new-img">
                          <img src={Plus} alt="plus icons" />
                        </div>
                      </div>
                      <div className="widget-blck">
                        <div className="left-heading">
                          <h4 className="text-merchant inter">Savings Goal</h4>
                          <p className="fst-normal setting-text-color-widget">Expected to achieve on 15th June 2022</p>
                        </div>
                        <div className="right-pie-chart">
                          <Widgetpiechart />
                        </div>
                      </div>
                      <div className="widget-blck">
                        <div className="left-heading">
                          <h4 className="text-merchant inter">Holiday Goal</h4>
                          <p className="fst-normal setting-text-color-widget">
                            Expected to achieve on 21st August 2022
                          </p>
                        </div>
                        <div className="right-pie-chart">
                          <Widgetpiechart />
                        </div>
                      </div>
                      <div className="widget-blck">
                        <div className="left-heading">
                          <h4 className="text-merchant inter">Emergency Fund</h4>
                          <p className="fst-normal setting-text-color-widget">
                            Expected to achieve by 10th October 2022
                          </p>
                        </div>
                        <div className="right-pie-chart">
                          <Widgetpiechart />
                        </div>
                      </div>
                      <div className="widget-blck">
                        <div className="left-heading">
                          <h4 className="text-merchant inter">New Car</h4>
                          <p className="fst-normal setting-text-color-widget">
                            Expected to achieve by 15th September 2022
                          </p>
                        </div>
                        <div className="right-pie-chart">
                          <Widgetpiechart />
                        </div>
                      </div>

                      <div className="Un-categorised merchants mt-3">
                        <div className="head-left d-flex justify-content-between">
                          <h3 className="filter-trans mb-0">Un-categorised merchants</h3>
                          <div className="new-img">
                            <img src={Plus} alt="plus icons" />
                          </div>
                        </div>
                        <div className="merchant-box mt-3">
                          <Link to="/Merchatsdetails">
                            <div className="d-flex">
                              <div className="img-box">
                                <img src={Ami} alt="" />
                              </div>
                              <div className="ms-3">
                                <div className="text-merchant bold black">Ami Insurance</div>
                                <div className="small-text-merchant">
                                  <span className="number-merchant new-green">$560.80</span>
                                  <span className="black">|</span>
                                  <h4 className="small-text-merchant space black">5 Transactions</h4>
                                </div>
                              </div>
                            </div>
                          </Link>
                          <div className="select-category">
                            <select class="form-select">
                              <option selected></option>
                              <option></option>
                              <option></option>
                              <option></option>
                              <option></option>
                            </select>
                          </div>
                        </div>
                        <div className="merchant-box mt-3">
                          <Link to="/Merchatsdetails">
                            <div className="d-flex">
                              <div className="img-box">
                                <img src={Bleached} alt="" />
                              </div>
                              <div className="ms-3">
                                <div className="text-merchant bold black">Bleached Cafe</div>
                                <div className="small-text-merchant">
                                  <span className="number-merchant new-green">$15.80</span>
                                  <span className="black">|</span>
                                  <h4 className="small-text-merchant space black">2 Transactions</h4>
                                </div>
                              </div>
                            </div>
                          </Link>
                          <div className="select-category">
                            <select class="form-select">
                              <option selected></option>
                              <option></option>
                              <option></option>
                              <option></option>
                              <option></option>
                            </select>
                          </div>
                        </div>
                        <div className="merchant-box mt-3">
                          <Link to="/Merchatsdetails">
                            <div className="d-flex">
                              <div className="img-box">
                                <img src={Booking} alt="" />
                              </div>
                              <div className="ms-3">
                                <div className="text-merchant bold black">Booking.com</div>
                                <div className="small-text-merchant">
                                  <span className="number-merchant new-green">$150.65</span>
                                  <span className="black">|</span>
                                  <h4 className="small-text-merchant space black">1 Transaction</h4>
                                </div>
                              </div>
                            </div>
                          </Link>
                          <div className="select-category">
                            <select class="form-select">
                              <option selected></option>
                              <option></option>
                              <option></option>
                              <option></option>
                              <option></option>
                            </select>
                          </div>
                        </div>
                        <div className="merchant-box mt-3">
                          <Link to="/Merchatsdetails">
                            <div className="d-flex">
                              <div className="img-box">
                                <img src={ContactEnergy} alt="" />
                              </div>
                              <div className="ms-3">
                                <div className="text-merchant bold black">Contact Energy</div>
                                <div className="small-text-merchant">
                                  <span className="number-merchant new-green">$780.65</span>
                                  <span className="black">|</span>
                                  <h4 className="small-text-merchant space black">6 Transactions</h4>
                                </div>
                              </div>
                            </div>
                          </Link>
                          <div className="select-category">
                            <select class="form-select">
                              <option selected></option>
                              <option></option>
                              <option></option>
                              <option></option>
                              <option></option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 left-block-widgets">
                      <div className="head-left d-flex justify-content-between">
                        <h3 className="filter-trans mb-0">Expenses breakdown</h3>
                        <div className="new-img">
                          <img src={Plus} alt="plus icons" />
                        </div>
                      </div>
                      <div className="widget-blck">
                        {/* <div className="col-xl-4"> */}
                        <div className="right-top-blck new-top-blck">
                          <div className="new-blck">
                            <div className="content">
                              <h3 className="title bold widget-sub-title">Expense breakdown</h3>
                              <div class="right-section">
                                <select>
                                  <option>
                                    <a href="#">Month</a>
                                  </option>
                                  <option>Last 12 Months</option>
                                  <option>Last 12 Months</option>
                                  <option>Last 12 Months</option>
                                </select>
                              </div>
                            </div>
                            <div className="right">
                              <h3 className="per-head  dark-blue">$5600</h3>
                            </div>
                          </div>
                          <div className="chart-section-vertical" style={{ width: '100%' }}>
                            <ul className="bar-chart-budget">
                              {ExpenseData.map((item, index) => (
                                <li
                                  key={index}
                                  className={item.label === 'Power/Wifi' ? 'power-wifi-bar' : 'bar-budget'}
                                  style={{ height: `${item.value}px`, width: `${item.width}px` }}
                                >
                                  <span className="label-budget">{item.label}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="Un-categorised merchants mt-3">
                        <div className="head-left d-flex justify-content-between">
                          <h3 className="filter-trans mb-0">Income vs Budget</h3>
                          <div className="new-img">
                            <img src={Plus} alt="plus icons" />
                          </div>
                        </div>
                        <div className="widget-blck widget-income widget-sub-wrp">
                          <div className="new-blck">
                            <div className="content">
                              <h3 className="title bold  widget-sub-title">Total income vs budget</h3>
                              <div class="right-section">
                                <select>
                                  <option>
                                    <a href="#">This month</a>
                                  </option>
                                  <option>Last 12 Months</option>
                                  <option>Last 12 Months</option>
                                  <option>Last 12 Months</option>
                                </select>
                              </div>{' '}
                            </div>
                            <div className="right">
                              <h3 className="per-head bold">12.5%</h3>
                            </div>
                          </div>
                          <div className="pie-blck widget-pie-blck">
                            <Bigpiechart />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 left-block-widgets new-left-widgets">
                      <div className="head-left d-flex justify-content-between">
                        <h3 className="filter-trans mb-0">Income breakdown</h3>
                        <div className="new-img">
                          <img src={Plus} alt="plus icons" />
                        </div>
                      </div>
                      <div className="right-top-blck new-top-blck new-block">
                        <div className="new-blck">
                          <div className="content">
                            <h3 className="title">Income breakdown</h3>
                            <div class="right-section">
                              <select>
                                <option>
                                  <a href="#">This month</a>
                                </option>
                                <option>Last 12 Months</option>
                                <option>Last 12 Months</option>
                                <option>Last 12 Months</option>
                              </select>
                            </div>
                          </div>
                          <div className="right">
                            <h3 className="per-head bold">$5600</h3>
                          </div>
                        </div>
                        <div className="pie-blck">
                          <Smallcart />
                        </div>
                      </div>

                      <div className="head-left d-flex justify-content-between mt-4">
                        <h3 className="filter-trans mb-0">Expenses vs Budget</h3>
                        <div className="new-img">
                          <img src={Plus} alt="plus icons" />
                        </div>
                      </div>
                      <div className="widget-bottom-right-block mt-3 widget-sub-wrp">
                        <div className="new-blck">
                          <div className="content">
                            <h3 className="title">Total expenses vs budget</h3>
                            <div class="right-section">
                              <select>
                                <option>
                                  <a href="#">This month</a>
                                </option>
                                <option>Last 12 Months</option>
                                <option>Last 12 Months</option>
                                <option>Last 12 Months</option>
                              </select>
                            </div>{' '}
                          </div>
                          <div className="right">
                            <h3 className="per-head bold">12.5%</h3>
                          </div>
                        </div>
                        <div className="pie-blck widget-pie-blck">
                          <PieChartWidgetExpense />
                        </div>
                      </div>

                      <div className="head-left d-flex justify-content-between mt-3">
                        <h3 className="filter-trans mb-0">Merchants</h3>
                        <div className="new-img">
                          <img src={Plus} alt="plus icons" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12 col-lg-6 categories-block mt-4">
                          <div className="categorised">
                            <div className="categorised-icon">
                              <People />
                            </div>
                            <div className="text-light text-center category-text">Categorised</div>
                            <div className="fs-1 fw-bold text-light text-center">57</div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-6 categories-block mt-4">
                          <div className="uncategorised">
                            <div className="uncategorised-icon">
                              <People />
                            </div>
                            <div className="text-light text-center category-text">Uncategorised</div>
                            <div className="fs-1 fw-bold text-light text-center">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Addwidgets;

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { ReactComponent as Upload } from '../../assests/images/icons/upload.svg';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import weburls from '../Weburls/weburls';

const schema = yup.object().shape({
  firstName: yup.string().min(3, '*Required').required('*Firstname is Required'),
  lastName: yup.string().required('*Lastname is Required'),
  phoneNumber: yup.string().required('*Phone Number is Required'),
  email: yup.string().required('*Email is Required'),
  // grouplsit: yup.string().required('*Select group required')
  // groupName: yup.string().required('*Required'),
});

const Newclient = ({ show }) => {
  // const inputFile = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
     reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const advisorsDetail = JSON.parse(localStorage.getItem('userData'));
  const [planlist, setPlanList] = useState([]);
  const [grouplist, setGroupList] = useState([]);
  const companyid = JSON.parse(localStorage.getItem('userData'));
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    companyId: companyid.companyId,
    groupId: '',
    planName: '',
    planId: '',
    advisorId: companyid.advisorId,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSelectChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const [code, newCode ]= useState({})
const navigate = useNavigate()
  const addClient = async () => {
    try {
      const response = await axios.post(weburls.Add_clients, formData);
      setFormData(response);
      console.log(response, 'add group');
      // reset();
      if (response.data) {
        
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 2000,
        });
        // navigate('/Clients')
      } else if (response.data.errors && response.data.errors[0].code === 0) {
        toast.error(response.data.errors[0].detail,{
          position: 'top-right',
          autoClose: 2000,
        });
        console.log('arraa h andr...')

        console.log('error message');
      } else {
        toast.error('Something went wrong',{
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const getPlanlist = async () => {
    const response = await axios.get(weburls.Super_admin_plans_list);
    setPlanList(response.data);
  };
  const getGrouplist = async () => {
    try {
      const response = await axios.post(weburls.Group_list, {
        advisorId: advisorsDetail.advisorId,
      });
      if (response?.data?.length > 0) {
        setGroupList(response.data);
        // setLoading(false);
      }
      // setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPlanlist();
    getGrouplist();
  }, []);

  console.log('formdata', formData);

  return (
    <>
      {console.log(grouplist, 'grouplist')}
      <div className="container-fluid">
        <Header />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer">
            <div className="row">
              <div className="col-md-12 mb-3">
                <h3 className="small-heading">
                  <Link to="/Clients">
                    <i class="fa-solid fa-arrow-left"></i> Back to clients
                  </Link>
                </h3>
              </div>
            </div>
            <div className="setting_background_color">
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black big-top-heading">Add a new client</span>
                  <div className="setting_text_color">
                    Enter in your clients details, they'll get an email once you're finished.
                  </div>
                </div>
              </div>
              <hr />
                <ToastContainer
                  position="top-right"
                  autoClose={5000} // Auto close after 5 seconds
                  closeOnClick
                />
              <form onSubmit={handleSubmit(addClient)}>
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xl-5">
                    <h5 className="bold black heading-new">Personal details</h5>
                    <div className="setting_text_color">This will be displayed on their profile.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="First name"
                            {...register('firstName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="firstName"
                            value={formData.firstName}
                          />
                        </div>
                        <p className="error-message">{errors.firstName?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Last name"
                            {...register('lastName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="lastName"
                            value={formData.lastName}
                          />
                        </div>
                        <p className="error-message">{errors.lastName?.message}</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-7">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Email"
                            {...register('email', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="email"
                            value={formData.email}
                          />
                        </div>
                        <p className="error-message">{errors.email?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-5">
                        <div class="input-group mb-3">
                          <input
                            type="number"
                            class="form-control input_border "
                            placeholder="Phone number"
                            {...register('phoneNumber', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="phoneNumber"
                            value={formData.phoneNumber}
                          />
                        </div>
                        <p className="error-message">{errors.phoneNumber?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xl-5">
                    <h5 className="bold black heading-new">Group details</h5>
                    <div className="setting_text_color">This is if they’re part of a group package.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row mt-3">
                      <div className="col-md-12 col-xl-12 col-sm-12">
                        <div class="input-group mb-3">
                          <select
                            class="form-select input_border mb-4"
                            value={formData.selectedId}
                            name="grouplsit"
                            onChange={(e) => handleSelectChange(e, 'groupId')}
                          >
                            <option value=""> Select a group list</option>
                            {grouplist && grouplist.length > 0
                              ? grouplist.map((item) => (
                                  <option key={item.groupId} value={item.groupId}>
                                    {item.groupName}
                                  </option>
                                ))
                              : 'No data found'}
                          </select>{' '}
                        </div>
                          {/* {errors.grouplsit && (
    <p className="error-message">{errors.grouplsit.message}</p>
  )} */}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xl-5">
                    <h5 className="bold black heading-new">Plan details</h5>
                    <div className="setting_text_color">Enter in the type of plan the user has selected.</div>
                  </div>

                  <div className="col-md-12 col-xl-7 col-sm-12 mb-3">
                    <div className="row mt-3 plan-wrp">
                      <div className="col-xl-6 col-md-12">
                        <select
                          class="form-select input_border mb-4"
                          value={formData.selectedId}
                          name="planId"
                          onChange={(e) => handleSelectChange(e, 'planId')}
                        >
                          <option value=""> Select a plan</option>
                          {planlist && planlist?.length > 0
                            ? planlist.map((item) => (
                                <option key={item.planId} value={item.planId}>
                                  {item.planName}
                                </option>
                              ))
                            : 'No data found'}
                        </select>{' '}
                        {/* <p className="error-message">{errors.selectPlan?.message}</p> */}
                      </div>

                      <div className="col-xl-6 col-md-12">
                        <select class="form-select input_border mb-4">
                          <option selected> Billing Frequency</option>
                          <option> Billing Frequency 2</option>
                          <option> Billing Frequency 3</option>
                          <option> Billing Frequency</option>
                          <option> Billing Frequency</option>
                        </select>
                        {/* <p className="error-message">{errors.billingFrequency?.message}</p> */}
                      </div>

                      <div className="col-md-12 col-xl-6">
                        <select class="form-select input_border">
                          <option selected> Bill to</option>
                          <option> Bill to 2</option>
                          <option> Bill to 3</option>
                        </select>
                        {/* <p className="error-message">{errors.billTo?.message}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row mt-4">
                  <div className="col-md-12 col-sm-12 col-xl-6">
                    <h5 className="bold black heading-new">Assigned advisors</h5>
                    <div className="setting_text_color">Enter in the assigned advisor to this group.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-6">
                    <div className="row">
                      <div className="col-xl-6 col-md-12">
                        {/* <select class="form-select input_border mb-4" {...register('advisorName')}>
                          <option selected>Assigned advisor</option>
                          <option> Billing Frequency 2</option>
                        </select>
                        <p className="error-message">{errors.advisorName?.message}</p> */}
                      </div>

                      <div className="col-md-12 col-xl-6">
                        <select class="form-select input_border">
                          <option selected>Assigned advisor</option>
                          <option> Bill to 2</option>
                        </select>
                        {/* <p className="error-message">{errors.advisorName2?.message}</p> */}
                      </div>
                    </div>
                  </div>
                  <div class="button">
                    <button type="button" class="comn-btn button_white">
                      Cancel
                    </button>
                    <button type="submit" class="comn-btn common-btn-style">
                      Add client<i class="fa-solid fa-plus plus-icon-style"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="setting_background_color mt-5">
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black big-top-heading">Upload a list of clients</span>
                  <div className="setting_text_color">
                    If you have a bunch of clients to add, upload a csv file of all their details and we’ll handle it.
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xl-6">
                  <h5 className="bold black heading-new">Upload your file here</h5>
                  <div className="setting_text_color">Upload your csv file.</div>
                </div>
                <div className="col-md-12 col-sm-12 col-xl-6">
                  <div className="row mt-3">
                    <div className="col-md-12 col-xl-12">
                      <div className="col-md-12">
                        <div
                          class="input-group mb-3"
                          // onClick={handleCsvFile}
                        >
                          <input
                            type="text"
                            className="form-control input_border select-csv-file-net "
                            placeholder="Click to select your csv file"
                          />
                          <div className="upload">
                            <input type="file" id="file" style={{ display: 'none' }} />

                            <Upload className="upload-logo" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="button">
                      <button type="button" class="comn-btn button_white">
                        Cancel
                      </button>
                      <button type="button" class="comn-btn common-btn-style">
                        Add clients<i class="fa-solid fa-plus plus-icon-style"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Newclient;

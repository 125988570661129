import $ from 'jquery';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Arrowleft from '../../../assests/images/icons/arrow-left.png';
import Countdown from '../../../assests/images/icons/Countdown.png';
import { ReactComponent as Edit } from '../../../assests/images/icons/edit.svg';
import Profile from '../../../assests/images/icons/image.png';
import Mortgage from '../../../assests/images/icons/Mortgage.png';
import MainSidebar from '../../MainSidebar';
import MyCalendar from '../Calender/Calendersch';
// import Calendersec from '../Calender/Calendersch';
// import reducer from '../Newcalender/components/reducers/calendar-reducer';
// import registerServiceWorker from '../Newcalender/components/registerServiceWorker';
// import { createLogger } from 'redux-logger'

// const middleware = []
// if (process.env.NODE_ENV !== 'production') {
//   middleware.push(createLogger())
// }

// const store = createStore(
//   reducer,
//   applyMiddleware(...middleware)
// )

const Cashflowcart = () => {
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };

  const Data = [
    {
      id: 1,
      title: 'Everyday',
      account: '$350.50',
      src: Mortgage,
      accClass: 'account-number-dark-calendar bold ms-3',
    },
    {
      id: 2,
      title: 'Groceries',
      account: '$156.75',
      src: Mortgage,
      accClass: 'account-number-dark-calendar bold ms-3',
    },
    {
      id: 3,
      title: 'Power & Wifi',
      account: '$96.89',
      src: Mortgage,
      accClass: 'account-number-dark-calendar bold ms-3',
    },
    {
      id: 4,
      title: 'Mortgage',
      account: '$560.98',
      src: Mortgage,
      accClass: 'account-number-dark-calendar bold ms-3',
    },
    {
      id: 5,
      title: 'Savings',
      account: '$12,000.45',
      src: Mortgage,
      accClass: 'account-number-dark-calendar bold ms-3',
    },
  ];

  // registerServiceWorker()

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />

            <div className="app-main__outer">
              <div className="row top-nav">
                <div className="col-md-4">
                  <div className="bold big-head-new">Cashflow Calendar</div>
                  <div className="fst-normal setting_text_color">
                    See a forecast of where your moneys coming in and going out in your calendar.
                  </div>
                </div>
                <div className="col-md-4 cashflow-calendar-wrp">
                  <div className="edit-btn-col">
                    <div className="edit-btn" href-="#">
                      <div className="left">
                        <h4>View Cashflow Calendar</h4>
                        <p style={{ color: '#04baff' }}>
                          <Link to="/Clients/Cashflowcalender">Get the full overview.</Link>
                        </p>
                      </div>

                      <div className="right">
                        <a href="#">
                          <Edit />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 right-section">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <img src={Arrowleft} alt="srrowleft" /> Back to portal
                        </button>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row middle-seciton">
                <div className="col-xl-6 col-md-6 col-sm-12 calendar-sec-first-wrp">
                  <div className="row cashflow-wrp">
                    <div className="col-xl-6">
                      <div className="col-xl-12">
                        <h4 className="category-text bold">Income & Expenses</h4>
                      </div>
                      <div className="cal-container income-exp-first-container">
                        <div className="cal-inside">
                          <h5>2</h5>
                          <p>Pay & Rental Income</p>
                        </div>
                        <div className="cal-inside">
                          <h5 className="red-btn">3</h5>
                          <p>Pay & Rental Income</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 calender-right">
                      <div className="col-xl-12">
                        <h4 className="category-text bold">Transactions</h4>
                      </div>
                      <div className="row justify-content-between">
                        <div className="merchnat-img">
                          <img src={Countdown} alt="CountDown-img" />
                          <div className="name-merchant bar-chart">
                            <h5 className="bold">CountDown</h5>
                            <h6 className="bold">-$40.00</h6>
                          </div>
                        </div>
                        <div className="merchnat-img">
                          <img src={Countdown} alt="CountDown-img" />
                          <div className="name-merchant bar-chart">
                            <h5 className="bold">CountDown</h5>
                            <h6 className="bold">-$40.00</h6>
                          </div>
                        </div>
                        <div className="merchnat-img">
                          <img src={Countdown} alt="CountDown-img" />
                          <div className="name-merchant bar-chart">
                            <h5 className="bold">CountDown</h5>
                            <h6 className="bold">-$40.00</h6>
                          </div>
                        </div>
                        <div className="merchnat-img">
                          <img src={Countdown} alt="CountDown-img" />
                          <div className="name-merchant bar-chart">
                            <h5 className="bold">CountDown</h5>
                            <h6 className="bold">-$40.00</h6>
                          </div>
                        </div>
                        <div className="merchnat-img">
                          <img src={Countdown} alt="CountDown-img" />
                          <div className="name-merchant bar-chart">
                            <h5 className="bold">CountDown</h5>
                            <h6 className="bold">-$40.00</h6>
                          </div>
                        </div>
                        <div className="merchnat-img">
                          <img src={Countdown} alt="CountDown-img" />
                          <div className="name-merchant bar-chart">
                            <h5 className="bold">CountDown</h5>
                            <h6 className="bold">-$40.00</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 row justify-content-between calendar-sec-first-wrp">
                  <div className="col-xl-6">
                    <div className="col-xl-12">
                      <h4 className="category-text bold">Add Income</h4>
                    </div>
                    <div className="cal-container">
                      <div className="cal-inside justify-content-between">
                        <h4 className="text-merchant">Name</h4>
                        <div className="planner"></div>
                      </div>
                      <div className="cal-inside justify-content-between">
                        <h4 className="text-merchant">Amount</h4>
                        <div className="planner"></div>
                      </div>
                      <div className="cal-inside justify-content-between select-wrp">
                        <h4 className="text-merchant">Repeat?</h4>
                        <select className="planner">
                          <option></option>
                        </select>
                      </div>
                      <div className="cal-inside justify-content-between big-select-wrp account-lst-wrp-div">
                        <h4 className="text-merchant">Account</h4>
                        <select className="planner">
                          <option></option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="col-xl-12">
                      <h4 className="category-text bold">Add expense</h4>
                    </div>
                    <div className="cal-container">
                      <div className="cal-inside justify-content-between">
                        <h4 className="text-merchant">Name</h4>
                        <div className="planner"></div>
                      </div>
                      <div className="cal-inside justify-content-between">
                        <h4 className="text-merchant">Amount</h4>
                        <div className="planner"></div>
                      </div>
                      <div className="cal-inside justify-content-between">
                        <h4 className="text-merchant">Repeat?</h4>
                        <select className="planner">
                          <option></option>
                        </select>
                      </div>
                      <div className="cal-inside justify-content-between">
                        <h4 className="text-merchant">Account</h4>
                        <select className="planner">
                          <option></option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12">
                  <div className="col-xl-12">
                    <h4 className="category-text bold">Projected account balances</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="projected-acct-balances">
                    <div className="col-xl-12 col-lg-10 col-md-6">
                      <div className="d-flex justify-content-between">
                        {Data &&
                          Data?.map((item, index) => {
                            return (
                              <div className="merchant-box mt-3">
                                <div className="d-flex align-items-center">
                                  <div className="your-account-balance-wrp-logo-img">
                                    <img src={item.src} alt="" />
                                  </div>
                                  <div className="ms-2">
                                    <div className="text-merchant fw-bold-600">{item.title}</div>
                                  </div>
                                </div>
                                <div className={item.accClass}>{item.account}</div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                <MyCalendar />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Cashflowcart;

import React from 'react';
import { Link } from 'react-router-dom';
import '../../../assests/css/style.css';
import Arrowright from '../../../assests/images/Expand_right.png';
import Mortage from '../../../assests/images/icons/Mortgage.png';
import ArrowWhite from '../../../assests/images/icons/white-right.svg';
import Piechart2 from '../Donutpiechart/Piechart2';
import './spendbudget.css';
const Spendbudget = () => {
  const Data = [
    {
      id: 1,
      name: 'Groceries',
      emoji: '🛒',
      amount: '$699',
      spentamt: 'Overspent by $120',
      progress: 'progress dark-red-color',
      activeprogress: 'progress-bar w-100 dark-red-color-progress',
      progressvalue: '100',
    },
    {
      id: 2,
      name: 'Car',
      emoji: '🚗',
      amount: '$200',
      spentamt: '$235 remaining',
      progress: 'progress light-yellow',
      activeprogress: 'progress-bar w-50 light-yellow-progress',
      progressvalue: '50',
    },
  ];

  const budgetExpenseData = [
    { label: 'Food', value: 166.536, width: 40 },
    { label: 'Power/Wifi', value: 115.609, width: 40 },
    { label: 'Insurance', value: 154.168, width: 40 },
    { label: 'Mortgage', value: 115.609, width: 40 },
    { label: 'Car', value: 137.6, width: 40 },
    { label: 'Rates', value: 192, width: 40 },
    { label: 'Eating Out', value: 166.536, width: 40 },
    { label: 'Clothes', value: 100.562, width: 40 },
  ];

  return (
    <>
      <div className="row second-section">
        <div className="col-md-8 col-xl-8 col-sm-12 top-section mb-4 spend-vs-budget-top-side">
          <div className="col-md-6">
            <h5 className="title main-title">Spend vs Budget</h5>
          </div>
          <div class="col-md-6 right-section">
            <select className="bold big-select">
              <option>
                <a href="#">This week</a>
              </option>
              <option>Last 12 Months</option>
              <option>Last 12 Months</option>
              <option>Last 12 Months</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 spend-vs-budget-wrapper">
          <div className="wrp">
            {Data &&
              Data.map((item) => {
                return (
                  //  <div>
                  <Link className="groceries-main" to="/Groceriesedit">
                    <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                      <div className="inner-blck">
                        <div className="merchantSummary">
                          <div className="icon-img red-img">{item.emoji}</div>
                          <div className="content">
                            <h6 className="small-content">{item.name}</h6>
                            <h5 className="bold black">{item.amount}</h5>
                          </div>
                        </div>
                        <div className="arrow">
                          <div className="icon-eclamation">
                            <i class="fa-solid fa-exclamation"></i>
                          </div>
                          <img src={Arrowright} />
                        </div>
                      </div>
                      <div className="left-block name-merchant mb-2">
                        <h6>{item.spentamt}</h6>
                      </div>
                      <div class={item.progress} style={{ height: '12px' }}>
                        <div
                          class={item.activeprogress}
                          role="progressbar"
                          aria-valuenow={item.progressvalue}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            {/* <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="icon-img yellow-img">🚗</div>
                  <div className="content">
                    <h6 className="small-content">Car</h6>
                    <h5 className="bold">$200</h5>
                  </div>
                </div>
                <div className="arrow">
                  <img src={Arrowright} />
                </div>
              </div>
              <div className="left-block name-merchant mb-2">
                <h6>$235 remaining</h6>
              </div>
              <div class="progress light-yellow" style={{ height: '12px' }}>
                <div
                  class="progress-bar w-50 light-yellow-progress"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div> */}
            <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="icon-img">🏡</div>
                  <div className="content">
                    <h6 className="small-content">House</h6>
                    <h5 className="bold">$800</h5>
                  </div>
                </div>
                <div className="arrow">
                  <img src={Arrowright} />
                </div>
              </div>
              <div className="left-block name-merchant mb-2">
                <h6>$453 remaining</h6>
              </div>
              <div class="progress lightest-blue" style={{ height: '12px' }}>
                <div
                  class="progress-bar w-50 lightest-blue-progress"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="icon-img red-img">👔</div>
                  <div className="content">
                    <h6 className="small-content">Clothes</h6>
                    <h5 className="bold">$199</h5>
                  </div>
                </div>
                <div className="arrow">
                  <img src={Arrowright} />
                </div>
              </div>
              <div className="left-block name-merchant mb-2">
                <h6>$263 remaining</h6>
              </div>
              <div class="progress lightest-pink" style={{ height: '12px' }}>
                <div
                  class="progress-bar w-25 lightest-pink-progress"
                  role="progressbar"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="icon-img lightest-green">🌮</div>
                  <div className="content">
                    <h6 className="small-content">Eating out</h6>
                    <h5 className="bold">$120</h5>
                  </div>
                </div>
                <div className="arrow">
                  <img src={Arrowright} />
                </div>
              </div>
              <div className="left-block name-merchant mb-2">
                <h6>$15 remaining</h6>
              </div>
              <div class="progress lightest-green" style={{ height: '12px' }}>
                <div
                  class="progress-bar w-25 lightest-green-progress"
                  role="progressbar"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="icon-img voilet-light">🔌</div>
                  <div className="content">
                    <h6 className="small-content">Power & Wifi</h6>
                    <h5 className="bold">$120</h5>
                  </div>
                </div>
                <div className="arrow">
                  <img src={Arrowright} />
                </div>
              </div>
              <div className="left-block name-merchant mb-2">
                <h6>$15 remaining</h6>
              </div>
              <div class="progress voilet-light" style={{ height: '12px' }}>
                <div
                  class="progress-bar w-25 voilet-light-progress"
                  role="progressbar"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="icon-img light-brown-color">🏋️‍♀️</div>
                  <div className="content">
                    <h6 className="small-content">Gym</h6>
                    <h5 className="bold">$120</h5>
                  </div>
                </div>
                <div className="arrow">
                  <img src={Arrowright} />
                </div>
              </div>
              <div className="left-block name-merchant mb-2">
                <h6>$15 remaining</h6>
              </div>
              <div class="progress light-brown-color" style={{ height: '12px' }}>
                <div
                  class="progress-bar w-75 light-brown-color-progress"
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="icon-img phone-plan-green">📱</div>
                  <div className="content">
                    <h6 className="small-content">Phone Plan</h6>
                    <h5 className="bold">$60</h5>
                  </div>
                </div>
                <div className="arrow">
                  <img src={Arrowright} />
                </div>
              </div>
              <div className="left-block name-merchant mb-2">
                <h6>$15 remaining</h6>
              </div>
              <div class="progress phone-plan-green" style={{ height: '12px' }}>
                <div
                  class="progress-bar w-75 phone-plan-green-progress"
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="icon-img red-img">
                    {' '}
                    <img src={Mortage} alt="Mortage_img" />
                  </div>
                  <div className="content">
                    <h6 className="small-content">Transfer to savings</h6>
                    <h5 className="bold">$120</h5>
                  </div>
                </div>
                <div className="arrow">
                  <img src={Arrowright} />
                </div>
              </div>
              <div className="left-block name-merchant mb-2">
                <h6>$0 left to transfer</h6>
              </div>
              <div class="progress navy-blue-color" style={{ height: '12px' }}>
                <div
                  class="progress-bar w-100 navy-blue-color-progress"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="icon-img red-img">
                    {' '}
                    <img src={Mortage} alt="Mortage_img" />
                  </div>
                  <div className="content">
                    <h6 className="small-content">Transfer to car account</h6>
                    <h5 className="bold">$60</h5>
                  </div>
                </div>
                <div className="arrow">
                  <img src={Arrowright} />
                </div>
              </div>
              <div className="left-block name-merchant mb-2">
                <h6>$15 left to transfer</h6>
              </div>
              <div class="progress navy-blue-color" style={{ height: '12px' }}>
                <div
                  class="progress-bar w-75 navy-blue-color-progress"
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-12 col-sm-12 mt-6 spend-budget-wrp">
          <div className="right-top-blck bottom-right-block">
            <div className="new-blck">
              <div className="content">
                <h3 className="title">Total expenses vs budget</h3>
                <p className="common-content">See how you’re tracking</p>
              </div>
              <div className="right">
                <h3 className="per-head bold">12.5%</h3>
              </div>
            </div>
            <div className="pie-blck first-circular-block">
              <Piechart2 />
            </div>
          </div>
          <div className="right-top-blck mt-3 new-bottom-blck">
            <div className="new-blck">
              <div className="content">
                <h3 className="title">Budgeted expenses</h3>
              </div>
              <div className="right">
                <h3 className="per-head bold dark-blue">$5600</h3>
              </div>
            </div>
            <div className="chart-section-vertical">
              {/* <ul className="bar-line">
                <li className="food-bar-line">Food</li>
                <li className="food-bar-line">Mortgage</li>
                <li className="food-bar-line">Rates</li>
                <li className="food-bar-line">Eating Out</li>
                <li className="food-bar-line">Clothes</li>
                <li className="food-bar-line">Food</li>

                <li className="food-bar-line">Food</li>
              </ul>
              <ul className="bar-line power-wifi">
                <li className="food-bar-line power-wifi-line">Power/Wifi</li>
              </ul>
              <ul className="bar-line">
                <li className="food-bar-line">Insurance</li>
              </ul>
              <ul className="bar-line">
</ul> */}

              <ul className="bar-chart-budget">
                {budgetExpenseData.map((item, index) => (
                  <li
                    key={index}
                    className={item.label === 'Power/Wifi' ? 'power-wifi-bar' : 'bar-budget'}
                    style={{ height: `${item.value}px`, width: `${item.width}px` }}
                  >
                    <span className="label-budget">{item.label}</span>
                  </li>
                ))}
              </ul>

              {/* <div className="bar food-bar">
                <h3>Food</h3>
              </div> */}
              {/* <div className="bar power-wifi">
                <h3>Power/Wifi</h3>
              </div>
              <div className="bar insurance">
                <h3>Insurance</h3>
              </div>
              <div className="bar mortgage">
                <h3>mortgage</h3>
              </div>
              <div className="bar car">
                <h3>Car</h3>
              </div>
              <div className="bar rates">
                <h3>Rates</h3>
              </div>
              <div className="bar eating-out">
                <h3>Eating Out</h3>
              </div>
              <div className="bar clothes">
                <h3>Clothes</h3>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          <div className="wrp">
            <div className="col-lg-12 col-xl-12 col-sm-12 merchant-blck">
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="content">
                    <h6 className="small-content little-big-font">Total expenses vs budget</h6>
                    <h5 className="bold">$1520</h5>
                  </div>
                </div>
                <div className="arrow">
                  <img src={Arrowright} />
                </div>
              </div>
              <div className="left-block name-merchant">
                <h6 className="mb-2">$300 remaining</h6>
              </div>
              <div class="progress total-exp-budget-color" style={{ height: '16px' }}>
                <div
                  class="progress-bar w-75 total-exp-budget-color-progress"
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <div className="col-lg-12 col-xl-12 col-sm-12 goals-wrp content">
              <h5 className="title main-title">Achieved Goals Budget</h5>
              <p className="new-small-content">
                When you achieve a savings goal, it’s added to your budget section for when you spend that money on what
                you’ve saved for.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-8 mt-4">
          <Link to="/EditGoal">
            <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 back-red white">
              <div className="inner-blck">
                <div className="merchantSummary">
                  <div className="icon-img red-img goal-1-progress-color">🏝</div>
                  <div className="content">
                    <h6 className="small-content white">Goal 1 - Holiday</h6>
                    <h5 className="bold">$1200</h5>
                  </div>
                </div>
                <div className="arrow">
                  <img src={ArrowWhite} />
                </div>
              </div>
              <div className="left-block name-merchant">
                <h6 className="white mb-2">$1200 remaining</h6>
              </div>
              <div class="progress goal-1-progress-color" style={{ height: '12px' }}>
                <div
                  class="progress-bar w-100 goal-1-progress-color"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};
export default Spendbudget;

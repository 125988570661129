import $ from 'jquery';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Ami from '../../assests/images/icons/Ami insurance.png';
import Bleached from '../../assests/images/icons/Bleached Cafe.png';
import Bookin from '../../assests/images/icons/bookin.png';
import Chemist from '../../assests/images/icons/chemist-warehouse.png';
import ContactEnrgy from '../../assests/images/icons/contact-energy.png';
import Countdown from '../../assests/images/icons/Countdown.png';
import { ReactComponent as Filter } from '../../assests/images/icons/Filter.svg';
import Profile from '../../assests/images/icons/image.png';
import Mcdonals from '../../assests/images/icons/Mcdonald2-.png';
import Paksave from '../../assests/images/icons/Paksave.png';
import { ReactComponent as People } from '../../assests/images/icons/People.svg';
import Rebel from '../../assests/images/icons/rebel-sport.png';
import { ReactComponent as Search } from '../../assests/images/icons/Search.svg';
import Spark from '../../assests/images/icons/spark.png';
import Starbucks from '../../assests/images/icons/starbucks.png';
import Trademe from '../../assests/images/icons/Trademe.png';
import Uber from '../../assests/images/icons/uber.png';
import MainSidebar from '../MainSidebar';

// import MerchatsDetail from "../../Components/Merchats/MerchatsDetail";

const Merchats = () => {
  const [active, setActive] = useState(false);

  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };

  // const handleChangeDetail = () => {
  //   setActive(true);
  // };
  return (
    <>
      {/* {active && <MerchatsDetail />} */}
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar />
            {/* <MainSidebar toggleSidebar={toggleSidebar} /> */}
            <div className="app-main__outer your-merchants-main-wrapper">
              <div className="row">
                <div className="col-lg-6">
                  <div className="bold big-head-new">Your Merchants</div>
                  <div className="fst-normal setting_text_color">
                    Group your spending categories to get an more confidence with your expenses.
                  </div>
                </div>
                <div className="col-lg-6 right-section search-spacing your-merchants-back-to-portal">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to="/Clients/Activepage">
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-7 col-lg-7">
                  <div className="bold heading-new">Search</div>
                  <div>
                    <div className="search-merchant your-merchant-search">
                      <form>
                        <input type="text" className="search search-color-white" />
                        <Search className="search-merchant" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-9 merchants-inner-wrp">
                  <div className="bold heading-new">Merchants</div>
                  <div className="merchant-box mt-3">
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Ami} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Ami Insurance</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$560.80</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">5 Transactions</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="select-category">
                      <select class="form-select">
                        <option selected>Select category</option>
                        <option>Groceries</option>
                        <option>Insurance</option>
                        <option>Clother</option>
                        <option>Mortgage</option>
                      </select>
                    </div>
                  </div>
                  <div className="merchant-box mt-3">
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Bleached} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Bleached Cafe</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$15.80</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black"> 2 Transactions </h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="select-category">
                      <select class="form-select">
                        <option selected></option>
                      </select>
                    </div>
                  </div>
                  <div className="merchant-box mt-3">
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Bookin} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Booking.com</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$150.65</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">1 Transactions</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="select-category">
                      <select class="form-select">
                        <option selected></option>
                      </select>
                    </div>
                  </div>
                  <div
                    className="merchant-box mt-3"
                    // onClick={handleChangeDetail}
                  >
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Chemist} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Chemist Warehouse</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$120.65</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">2 Transactions</h4>
                          </div>
                        </div>
                      </div>
                      <div className="select-category">
                        <select class="form-select">
                          <option selected></option>
                        </select>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="merchant-box mt-3"
                    // onClick={handleChangeDetail}
                  >
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={ContactEnrgy} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Contact Energy</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$780.65</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">6 Transactions</h4>
                          </div>
                        </div>
                      </div>
                      <div className="select-category">
                        <select class="form-select">
                          <option selected></option>
                        </select>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="merchant-box mt-3"
                    // onClick={handleChangeDetail}
                  >
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Countdown} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Countdown</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$150.65</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">1 Transactions</h4>
                          </div>
                        </div>
                      </div>
                      <div className="select-category">
                        <select class="form-select">
                          <option selected></option>
                        </select>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="merchant-box mt-3"
                    // onClick={handleChangeDetail}
                  >
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Mcdonals} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Mcdonalds</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$80.40</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">3 Transactions</h4>
                          </div>
                        </div>
                      </div>
                      <div className="select-category">
                        <select class="form-select">
                          <option selected></option>
                        </select>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="merchant-box mt-3"
                    // onClick={handleChangeDetail}
                  >
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Uber} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Uber</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$54.65</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">1 Transactions</h4>
                          </div>
                        </div>
                      </div>
                      <div className="select-category">
                        <select class="form-select">
                          <option selected></option>
                        </select>
                      </div>
                    </Link>
                  </div>
                  <div className="merchant-box mt-3">
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Paksave} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Paknsave</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$485.60</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">1 Transactions</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="select-category">
                      <select class="form-select">
                        <option selected></option>
                      </select>
                    </div>
                  </div>
                  <div className="merchant-box mt-3">
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Rebel} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Rebel Sport</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$150.65</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">1 Transactions</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="select-category">
                      <select class="form-select">
                        <option selected></option>
                      </select>
                    </div>
                  </div>
                  <div className="merchant-box mt-3">
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Spark} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Spark</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$204.65</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">2 Transactions</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="select-category">
                      <select class="form-select">
                        <option selected></option>
                      </select>
                    </div>
                  </div>
                  <div className="merchant-box mt-3">
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Trademe} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Trademe</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$150.65</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">1 Transactions</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="select-category">
                      <select class="form-select">
                        <option selected></option>
                      </select>
                    </div>
                  </div>
                  <div className="merchant-box mt-3">
                    <Link to="/Merchatsdetails">
                      <div className="d-flex">
                        <div className="img-box">
                          <img src={Starbucks} alt="" />
                        </div>
                        <div className="ms-3">
                          <div className="text-merchant bold black">Starbucks</div>
                          <div className="small-text-merchant">
                            <span className="number-merchant">$150.65</span>
                            <span className="black">|</span>
                            <h4 className="small-text-merchant space black">10 Transactions</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="select-category">
                      <select class="form-select">
                        <option selected></option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 search-spacing filter-merchants-wrp-side">
                  <div className="d-flex justify-content-between">
                    <div className="bold heading-new">Filter</div>
                    <div>
                      <Filter />
                    </div>
                  </div>

                  <div className="merchants-background-color  mt-3">
                    <div className="bold live-connection mb-3">Merchant type</div>
                    <div className="group-btn green-btn">
                      <div className="button-blck">
                        <a href="#" className="group-button green">
                          All
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Groceries
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Car
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Insurance
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Clothes
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Travel
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          House
                        </a>
                      </div>
                    </div>
                    <div className="bold live-connection mb-3">Categories</div>
                    <div className="group-btn green-btn">
                      <div className="button-blck">
                        <a href="#" className="group-button green">
                          All
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Categorised
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Un-categorised
                        </a>
                      </div>
                    </div>
                    <div className="bold live-connection mb-3">Filter by spend</div>
                    <div className="group-btn green-btn">
                      <div className="button-blck">
                        <a href="#" className="group-button green">
                          All
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Top 10 merchants
                        </a>
                      </div>
                      <div className="button-blck">
                        <a href="#" className="group-button">
                          Top 5 merchants
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12 col-lg-6 categories-block mt-4">
                      <div className="categorised">
                        <div className="categorised-icon">
                          <People />
                        </div>
                        <div className="text-light text-center category-text">Categorised</div>
                        <div className="fs-1 fw-bold text-light text-center">57</div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-lg-6 categories-block mt-4">
                      <div className="uncategorised">
                        <div className="uncategorised-icon">
                          <People />
                        </div>
                        <div className="text-light text-center category-text">Uncategorised</div>
                        <div className="fs-1 fw-bold text-light text-center">5</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Merchats;

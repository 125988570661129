import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import weburls from '../../Weburls/weburls';
import '../AddIncome/addIncome.css';

const schema = yup.object().shape({
  expenseType: yup.string().required('*Please enter expense name'),
  transferAmount: yup
    .string()
    .required('*Please enter transfer amount')
    .matches(/^[0-9]+$/, '*Please enter number only'),
  lastTransferDate: yup.string().required('*Please enter last transfer date'),
  howOften: yup.string().required('*Please enter transfer frequency'),
  transferFrom: yup.string().required('*Please enter transfer from'),
  transferTo: yup.string().required('*Please enter transfer to'),
});

const AccountTransferModal = ({ modalOpen, fetchAccountTransfer, setModalOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [accountData, setAccountData] = useState({
    userUuid: '',
    expenseType: '',
    transferAmount: '',
    lastTransferDate: '',
    howOften: '',
    transferFrom: '',
    transferTo: '',
  });
  let clientUuid = null;
  const [howOftenList, setOftenList] = useState([]);

  try {
    const clientUserUuidData = localStorage.getItem('client-userUuid');
    if (clientUserUuidData) {
      const parsedData = JSON.parse(clientUserUuidData);
      clientUuid = parsedData[0]?.userUuid;
    }
  } catch (error) {
    console.error('Error parsing client-userUuid data:', error);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const uuId = clientUuid;
    setAccountData({ ...accountData, userUuid: uuId, [name]: value });
  };

  const handleClose = () => {
    setModalOpen(!modalOpen);
  };

  const onSubmitHandler = async () => {
    try {
      const response = await axios.post(weburls.Account_Transfer_Add, accountData);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          setModalOpen(false);
          fetchAccountTransfer();
        }, 3000);
      } else if (response.status === 404) {
        toast.error('Something went wrong please try again.', {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error('error', error);
      setModalOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
    reset();
  };

  const howOften = async () => {
    try {
      const data = await axios.get(weburls.How_Often);
      setOftenList(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    howOften();
  }, []);

  return (
    <div className={`modal-background ${modalOpen ? 'open-modal' : ''} `}>
      <div className="modal-content">
        <button onClick={handleClose} className="close-modal-button">
          <i class="fa fa-times"></i>
        </button>
        <div className="setting_background_color">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black big-top-heading">Transfer your account</span>
            </div>
          </div>
          <hr />
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row add-expense-wrp">
              <div className="col-md-12 col-sm-12 col-xl-4 mb-3">
                <h5 className="bold black heading-new">Account details</h5>
                <div className="setting_text_color">This will be displayed on your profile.</div>
              </div>
              <div className="col-md-12 col-sm-12 col-xl-8">
                <div className="row">
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Expense Name :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="expenseType"
                        class="form-control input_border"
                        placeholder="Expense name"
                        {...register('expenseType', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors?.expenseType?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Transfer Amount :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="transferAmount"
                        class="form-control input_border"
                        placeholder="Transfer amount"
                        {...register('transferAmount', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.transferAmount?.message}</p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-xl-6 col-md-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      How Often :
                    </label>
                    <select
                      class="form-select input_border mb-3"
                      name="howOften"
                      {...register('howOften', {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                    >
                      <option value="" disabled={true}>
                        How often?
                      </option>

                      {Array.isArray(howOftenList) && howOftenList.length > 0
                        ? howOftenList &&
                          howOftenList?.map((item) => {
                            return (
                              <option key={item.howOftenId} value={item.howOftenName}>
                                {item.howOftenName}
                              </option>
                            );
                          })
                        : 'new'}
                    </select>{' '}
                    <p className="error-message">{errors.howOften?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Last Transfer Date :
                    </label>
                    <div class="input-group mb-3 date-picker-div">
                      <input
                        type="date"
                        id="date"
                        class="form-control input_border "
                        placeholder="Last payment date"
                        name="lastTransferDate"
                        {...register('lastTransferDate', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.lastTransferDate?.message}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-xl-12 col-sm-12">
                    <div className="row mt-2 plan-wrp">
                      <div className="col-md-12 col-xl-6">
                        <label htmlFor="event-start" className="mb-1 labels-text">
                          Transfer From :
                        </label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            name="transferFrom"
                            class="form-control input_border"
                            placeholder="Transfer from"
                            {...register('transferFrom', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        <p className="error-message">{errors.transferFrom?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <label htmlFor="event-start" className="mb-1 labels-text">
                          Transfer To :
                        </label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            name="transferTo"
                            class="form-control input_border"
                            placeholder="Transfer to"
                            {...register('transferTo', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        <p className="error-message">{errors.transferTo?.message}</p>
                      </div>
                    </div>
                  </div>
                  <div class="button modal-popup-btn-wrp">
                    <button type="submit" class="comn-btn common-btn-style">
                      Save details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountTransferModal;

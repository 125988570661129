import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const Piechart2 = () => {
  const percentage = 12.5;

  return (
    <>
      <div className="pie-blck-circular second-pie-blck">
        <CircularProgressbarWithChildren
          value={percentage}
          text={`${percentage}%`}
          s
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0.1,

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',

            // Text size
            textSize: '20px',

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.3,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: `rgba(223, 92, 92, 1), ${percentage / 100})`,
            textColor: 'rgba(38, 50, 56, 1)',
            trailColor: 'rgba(223, 92, 92, 0.4)',
            backgroundColor: '',
          })}
        >
          <div style={{ fontSize: 11, marginTop: 60, fontWeight: 'bold' }}>
            <center>OF BUDGET SPENT</center>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </>
  );
};
export default Piechart2;
